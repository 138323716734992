const dateFormat = function (dateToFormat, format) {
	const date = new Date(dateToFormat);
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const year = String(date.getFullYear()).padStart(4, '0');
	const fullDayOfWeek = date.toLocaleString('default', { weekday: 'long' });
	const shortDayOfWeek = date.toLocaleString('default', { weekday: 'short' });
	const shortMonthName = date.toLocaleString('default', { month: 'short' });
	let formattedDate = `${month}/${day}/${year}`;

	switch (format) {
		case 'MM/DD/YYYY':
			formattedDate = `${month}/${day}/${year}`;
			break;
		case 'dddd MM/DD/YYYY ':
			formattedDate = `${fullDayOfWeek} ${month}/${day}/${year}`;
			break;
		case 'YYYY-MM-DD':
			formattedDate = `${year}-${month}-${day}`;
			break;
		case 'MMM Do, YYYY':
			formattedDate = `${shortMonthName} ${date.getDate()}${getSuffixForNumber(day)}, ${year}`;
			break;
		case 'dddd':
			formattedDate = `${fullDayOfWeek}`;
			break;
		case 'ddd MMM DD, YYYY':
			formattedDate = `${shortDayOfWeek} ${shortMonthName} ${day} ${year}`;
			break;
		default:
			formattedDate = `${month}/${day}/${year}`;
	}
	return formattedDate;
};
const getSuffixForNumber = (number) => {
	if (number <= 0) return '';
	  return ['th', 'st', 'nd', 'rd'][(number > 3 && number < 21) || number % 10 > 3 ? 0 : number % 10];
	// return number > 0 ? ['th', 'st', 'nd', 'rd'][(number > 3 && number < 21) || number % 10 > 3 ? 0 : number % 10] : '';
};

export { dateFormat };
