<template>
	<v-container class="pa-0">
		<v-row class="ma-0">
			<v-col
				cols="12"
				class="pa-0"
			>
				<div class="property-details">
					<p class="text-center property-details__address ma-0 mt-6 mb-13">
						{{ selectedPropertyDetails.addressLineOne }}
						{{ selectedPropertyDetails.city }}, {{ selectedPropertyDetails.state }}
					</p>
					<div class="property-details__img-wrapper">
						<!-- <PropertyImage :address="completeAddress" /> -->
					</div>
				</div>
			</v-col>
			<v-col cols="12">
				<v-card class="mx-6 property-details__card">
					<v-card-actions class="pa-0 py-6 d-block">
						<div class="d-flex justify-space-between align-end property-details__item mb-6">
							<span class="field">Mowing Rate:</span
							><span data-testid="mowing-rate"
								>${{
									selectedPropertyDetails.mowingRate
										? selectedPropertyDetails.mowingRate.toFixed(2)
										: selectedPropertyDetails.mowingRate
								}}</span
							>
						</div>
						<div class="d-flex justify-space-between align-end property-details__item mb-6">
							<span class="field">Mowing Frequency:</span
							><span data-testid="mowing-freq"
								>Every {{ selectedPropertyDetails.mowingFrequency }}
								{{ selectedPropertyDetails.mowingFrequency > 1 ? 'days' : 'day' }}</span
							>
						</div>
						<div class="d-flex justify-space-between align-end property-details__item mb-6">
							<span class="field">Status:</span
							><span data-testid="status">{{
								selectedPropertyDetails.status ? selectedPropertyDetails.status : 'Not Set'
							}}</span>
						</div>
						<div class="d-flex justify-space-between align-end property-details__item mb-9">
							<span class="field">Last Mowed:</span><span data-testid="last-mow">{{ lastMowed }} </span>
						</div>
						<div class="d-flex justify-space-between align-end property-details__item mb-9">
							<span class="field">Next Mow:</span
							><span data-testid="next-mow">{{ dateFormat(nextMow, 'MMM Do, YYYY') }}</span>
						</div>
						<div class="d-flex justify-space-between align-end property-details__item mb-9">
							<span class="field">Animals:</span><span data-testid="animals">{{ selectedPropertyDetails.animals }} </span>
						</div>

						<div class="d-flex justify-space-between align-end property-details__item mb-9">
							<span class="field">Gated:</span
							><span data-testid="gated">{{ selectedPropertyDetails.gated ? 'Yes' : 'No' }} </span>
						</div>
						<div class="property-details__item mb-9">
							<span class="field">Notes:</span
							><span
								data-testid="notes"
								class="mt-2 d-block"
								>{{ selectedPropertyDetails.notes }}
							</span>
						</div>
						<div class="property-details__buttons-wrapper d-flex justify-space-between align-center py-0">
							<v-btn
								class="rounded-xl px-5 owner-details-btn"
								variant="flat"
								height="50"
								@click="() => $router.push(`/clients/${selectedPropertyDetails.propertyOwnerDetails.id}`)"
							>
								<span>Owner details</span>
							</v-btn>

							<span
								class="property-details__edit"
								@click="showEditPropertiesDialog = true"
							>
								<i class="material-icons">build</i>
							</span>
						</div>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
	<EditPropertyDetailsDialog />
</template>

<script setup>
import { useScheduleStore } from '@/stores/schedule';
import EditPropertyDetailsDialog from '@/components/EditPropertyDetailsDialog';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { dateFormat } from '@/utils/dateFormatter';
// Import PropertyImage from '@/components/PropertyImage.vue';

const scheduleStore = useScheduleStore();
// Let showEditDialog = ref(false);
const { selectedPropertyDetails, showEditPropertiesDialog } = storeToRefs(scheduleStore);
const lastMowed = ref(new Date());
const nextMow = ref(new Date());

// Const completeAddress = computed(() => {
//   return `${selectedPropertyDetails.value.addressLineOne}, ${selectedPropertyDetails.value.addressLineTwo},
//   ${selectedPropertyDetails.value.city}, ${selectedPropertyDetails.value.state}`;
// });

onMounted(async () => {
	await scheduleStore.fetchPropertyDetails();
	lastMowed.value =
		selectedPropertyDetails.value.mows.length > 0
			? dateFormat(
					new Date(Math.max(...selectedPropertyDetails.value.mows.map((element) => new Date(element.dateMowed)))),
					'MMM Do, YYYY',
				)
			: 'First Mow';

	nextMow.value =
		selectedPropertyDetails.value.nextMows.length > 0
			? new Date(Math.max(...selectedPropertyDetails.value.nextMows.map((element) => new Date(element.nextMowDate))))
			: new Date(
					new Date(selectedPropertyDetails.value.startServiceDate).setDate(
						new Date(selectedPropertyDetails.value.startServiceDate).getDate() +
							selectedPropertyDetails.value.mowingFrequency,
					),
				);
});
</script>

<style lang="scss" scoped>
@import 'styles';
@import '../../styles.scss';
.property-details {
	&__img-wrapper {
		margin-top: 30px;

		img {
			width: 100%;
			height: auto;
		}
	}

	&__address {
		margin-top: 23px;
		margin-bottom: 50px;
		font-size: 20px;
		line-height: 24px;
		font-family: 'Raleway', sans-serif;
		font-weight: 800;
		letter-spacing: 0;
		color: $SP400;
	}

	&__card {
		box-shadow: none;
	}

	&__item {
		span {
			color: $SP400;
			letter-spacing: 0;
			font-family: 'Raleway', sans-serif;
		}
		.field {
			font-size: 16px;
			font-weight: bold;
		}
	}

	&__edit {
		display: block;
		width: 50px;
		height: 50px;
		border-radius: 25px;
		background-color: $SP500;
		background-image: $wrench;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 40px;
		cursor: pointer;
	}

	&__buttons-wrapper {
		button {
			span {
				font-size: 16px;
				line-height: 19px;
				text-transform: capitalize;
				font-weight: bold;
				color: $SP1600;
			}
		}
		.owner-details-btn {
			background-color: $SP200;
		}
	}
}
.property-details__edit {
	position: relative;
	display: inline-block;
}

.material-icons {
	/* Styles for the icon */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
}
</style>
