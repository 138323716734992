<template>
	<v-container class="container">
		<div class="logo-container">
			<a
				href="https://schedupay.com"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img
					src="schedulepay.png"
					alt=""
					data-testid="schedupay-image"
				/>
			</a>

			<h2>Schedu<span>Pay</span></h2>
			<h3>Pro</h3>
		</div>
		<p class="text">
			This is a<span> mobile only </span> application. <br />Please use your mobile device to access ScheduPay Pro.
		</p>
	</v-container>
</template>
<style lang="scss" scoped>
@import '../../styles.scss';
.container {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-family: 'Raleway', sans-serif;
	.logo-container {
		color: $SP1500;
		h2 {
			font-size: 40px;
			font-weight: 500;
			& span {
				color: $SP400;
			}
		}
		h3 {
			color: $SP1500;
			font-size: 32px;
			font-style: italic;
		}
	}
	.text {
		font-size: 22px;
		font-style: italic;
		font-weight: 400;
		color: $SP1500;
		span {
			font-weight: 800;
			color: $SP1900;
		}
	}
}
</style>
