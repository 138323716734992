<template>
	<v-row justify="center">
		<v-dialog
			v-model="twoFASent"
			activator="parent"
			fullscreen
			:scrim="false"
			transition="dialog-bottom-transition"
		>
			<v-card class="card">
				<i
					class="back-arrow material-icons"
					@click="twoFASent = false"
					>arrow_back_ios</i
				>
				<div class="main__image__heading">
					<h2>Two Factor Authentication</h2>
					<img
						class="image2FA"
						src="f2a.jpg"
						alt=""
					/>
				</div>
				<v-card-text class="card__text">
					We sent a secret code to your email. Please enter it in the field below.
				</v-card-text>
				<v-form>
					<v-row justify="center">
						<v-col
							lg="6"
							xl="4"
						>
							<v-text-field
								v-model="code"
								class="code__input rounded-pill"
								clearable
								variant="plain"
								data-testid="code-input"
							/>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col
							lg="6"
							xl="4"
						>
							<v-btn
								class="resend__button"
								variant="flat"
								size="large"
								@click="openVerificationDialog"
							>
								Resend Code
								<EmailSentDialogBox />
							</v-btn>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col
							lg="6"
							xl="4"
						>
							<v-btn
								rounded="xl"
								size="large"
								block
								variant="tonal"
								class="card__btn"
								data-testid="submit-button"
								@click="verify2FACode(code)"
							>
								Submit
							</v-btn>
						</v-col>
					</v-row>
				</v-form>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script setup>
import { useAuthStore } from '../stores/auth';
import { storeToRefs } from 'pinia';
import EmailSentDialogBox from './EmailSentDialog.vue';
import { ref } from 'vue';

const authStore = useAuthStore();
const { twoFASent, emailDialogDisplayed } = storeToRefs(authStore);
const { verify2FACode } = authStore;
const code = ref('');
const openVerificationDialog = () => {
	authStore.$patch({
		emailDialogDisplayed: !emailDialogDisplayed,
	});
};
</script>

<style lang="scss">
@import '../../styles.scss';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap');

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
	transition: transform 0.2s ease-in-out;
}

.image2FA {
	width: 17rem;
}

.main__image__heading {
	margin-top: 20px !important;
	text-align: center;
	font-size: 1.5rem;
	letter-spacing: 0px;
	font-family: 'Raleway', sans-serif;
	line-height: 1.2;
	color: $SP400;
}
.card {
	padding: 35px 30px !important;
}

.card__text {
	font-family: 'Raleway', sans-serif;
	font-weight: 400;
	font-size: 1.1rem !important;
	line-height: 2 !important;
}

.code__input {
	height: 4rem;
	padding: 0rem 2rem;
	background-color: $SP600;
}

.code__input input {
	font-size: 1.2rem;
}

.code__input .v-icon {
	color: $SP1500 !important;
	font-size: 2rem !important;
}

.resend__button {
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
	text-decoration: underline;
	color: $SP200 !important;
}
.card__btn {
	width: 100%;
	height: 60px !important;
	background: $SP500 0% 0% no-repeat padding-box !important;
	font: normal normal bold 33px/39px Raleway;
	color: $SP1600 !important;
	font-size: 25px !important;
}
</style>
