<template>
	<v-container class="pauseMowing text-center">
		<!-- step 1 -->
		<v-card
			v-if="currentStep === 1"
			class="pauseMowing__step pauseMowing__step--one elevation-0"
		>
			<v-title class="card-title mar-b-30 mx-auto pauseMowing__title headline">
				Pause your season by taping the orange pause button below
			</v-title>
			<v-btn
				class="pauseMowing__button rounded-pill"
				width="200px"
				height="200px"
				@click="handleStep(2)"
			></v-btn>
			<h2 class="pauseMowing__subtitle">1 of 3 steps to pause</h2>
			<h3 class="pauseMowing__warning">Warning</h3>
			<p class="pauseMowing__description">when you pause the season the follow actions will happen</p>
			<p class="pauseMowing__description">
				1. All of your users will be emailed stating that you have stopped mowing for the season
			</p>
			<div class="divider"></div>
			<p class="pauseMowing__description">2. All of your schedules will be removed and unscheduled</p>
		</v-card>

		<!-- step 2 -->
		<v-card
			v-if="currentStep === 2"
			class="pauseMowing__step pauseMowing__step--two pa-5 rounded-xl"
		>
			<v-title class="card-title mar-b-30 w-75 mx-auto pauseMowing__title d-block headline">
				Pause mowing <span class="d-block">Step 2 of 3</span>
			</v-title>
			<h2 class="pauseMowing__subtitle">What month do you estimate to start Mowing again?</h2>
			<p class="pauseMowing__select-month">Select month</p>
			<v-select
				v-model="selectedMonth"
				:items="months"
				:rules="[(v) => !!v || 'Item is required']"
				required
				hide-details
				class="select"
			></v-select>
			<v-btn
				class="button rounded-xl d-block mx-auto mt-7 mb-10 py-3"
				height="auto"
				@click="handleStep(3)"
				>Next <span class="arrow"></span
			></v-btn>
		</v-card>

		<!-- step 3 -->
		<v-card
			v-if="currentStep === 3"
			class="pauseMowing__step pauseMowing__step--three px-5 pt-5 pb-16 rounded-xl"
		>
			<v-title class="card-title mar-b-30 w-75 mx-auto pauseMowing__title d-block headline">
				Pause mowing <span class="d-block">Step 3 of 3</span>
			</v-title>
			<h2 class="pauseMowing__subtitle mb-12">
				Tap the numbers in their sequential order to confirm that you want to pause your mowing season until 03/24
			</h2>
			<v-btn
				class="button button--1 rounded-pill mr-5"
				width="65px"
				height="65px"
				>1</v-btn
			>
			<v-btn
				class="button button--2 rounded-pill mr-5"
				width="65px"
				height="65px"
				>2</v-btn
			>
			<v-btn
				class="button button--3 rounded-pill mr-5"
				width="65px"
				height="65px"
				>3</v-btn
			>
			<v-btn
				class="button button--4 rounded-pill"
				width="65px"
				height="65px"
				>4</v-btn
			>
		</v-card>
	</v-container>
</template>
<script setup>
import { ref } from 'vue';

const currentStep = ref(1);
const months = ref([
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]);
const selectedMonth = ref(null);

const handleStep = (step) => (currentStep.value = step);
</script>

<style lang="scss">
@import 'styles.scss';

.pauseMowing {
	&__title {
		font-size: 18px;
		max-width: 270px;
	}

	&__subtitle {
		color: $SP1000;
		margin-top: 15px;
	}

	&__warning {
		color: $SP500;
		font-size: 35px;
		text-transform: uppercase;
		font-weight: 500;
		margin: 15px 0;
	}

	&__button {
		background-image: $pause;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 70px;
		background-color: $SP500;
	}

	&__description {
		text-align: left;
		text-transform: uppercase;
		color: $SP1300;

		&:first-of-type {
			text-align: center;
			margin-bottom: 10px;
		}
	}

	.divider {
		width: 100%;
		height: 1px;
		border-top: 1px dashed $SP1200;
		margin: 15px 0;
	}

	&__step {
		&--one {
		}

		&--two {
			.headline {
				font-size: 28px;
			}

			.pauseMowing__subtitle {
				color: $SP400;
				font-weight: 400;
				margin-bottom: 20px;
			}

			.pauseMowing__select-month {
				color: $SP400;
				font-weight: 400;
				font-size: 22px;
				margin-bottom: 10px;
			}

			.select {
				.v-field__outline {
					display: none;
				}
				.v-field {
					border-radius: 100px !important;
				}
			}

			.button {
				text-transform: capitalize;
				font-size: 22px;
				background-color: $SP500;
				color: $SP1600;

				span.arrow {
					padding: 0 15px;
					display: block;
					width: 30px;
					height: 30px;
					transform: rotate(-90deg);
					background: $angles-down-white no-repeat center/22px;
				}
			}
		}

		&--three {
			.headline {
				font-size: 28px;
			}
			.headline {
				font-size: 28px;
			}
			.pauseMowing__subtitle {
				color: $SP400;
				font-weight: 400;
				margin-bottom: 30px;
			}
			.button {
				font-size: 30px;
				color: $SP1600;

				&--1 {
					background-color: $SP500;
				}
				&--2 {
					background-color: $SP400;
				}
				&--3 {
					background-color: $SP400;
				}
				&--4 {
					background-color: $SP500;
				}
			}
		}
	}
}
</style>
