export const parseAddressComponents = (addressComponents) => {
	const parsedAddress = {};

	addressComponents.forEach((component) => {
		if (component.types.includes('street_number')) {
			parsedAddress.streetNumber = component.long_name;
		} else if (component.types.includes('route')) {
			parsedAddress.streetName = component.long_name;
		} else if (component.types.includes('locality')) {
			parsedAddress.city = component.long_name;
		} else if (component.types.includes('administrative_area_level_2')) {
			parsedAddress.county = component.long_name;
		} else if (component.types.includes('administrative_area_level_1')) {
			parsedAddress.state = component.short_name;
		} else if (component.types.includes('country')) {
			parsedAddress.country = component.long_name;
		} else if (component.types.includes('postal_code')) {
			parsedAddress.zipCode = component.long_name;
		}
	});
	parsedAddress.addressLineOne = `${parsedAddress.streetNumber} ${parsedAddress.streetName}`;
	return parsedAddress;
};

export const updateAddressComponentByType = (place, type, newValues) => {
	// Find the address component by type
	const component = place.address_components.find((comp) => comp.types.includes(type));

	// If found, update the values
	if (component) {
		Object.assign(component, newValues);
	}
};

export const combineAddressComponents = (addressComponents) => {
	let streetNumber;
	let streetName;
	let city;
	let state;
	let country;
	let zipCode;

	addressComponents.forEach((component) => {
		if (component.types.includes('street_number')) {
			streetNumber = component.long_name;
		} else if (component.types.includes('route')) {
			streetName = component.long_name;
		} else if (component.types.includes('locality')) {
			city = component.long_name;
		} else if (component.types.includes('administrative_area_level_1')) {
			state = component.short_name;
		} else if (component.types.includes('country')) {
			country = component.long_name;
		} else if (component.types.includes('postal_code')) {
			zipCode = component.long_name;
		}
	});

	return `${streetNumber} ${streetName}, ${city}, ${state} ${zipCode}, ${country}`;
};

export const parseWebsite = (website) => {
	const url = new URL(website);
	const domainParts = url.hostname.split('.');
	const domain = domainParts.slice(-2).join('.');
	return `${url.protocol}//${domain}`;
};

export const parseDateString = (dateString) => {
	if (!dateString) return '';

	const [datePart, timePart] = dateString.split(' ');
	const [month, day, year] = datePart.split('/');
	const [time, period] = timePart.split(/(\d+:\d+:\d+)(\w+)/).filter(Boolean);
	const [hour, minute, second] = time.split(':');

	const hourIn24Format = period.toUpperCase() === 'PM' ? parseInt(hour) + 12 : hour;

	const date = new Date(year, month - 1, day, hourIn24Format, minute, second);

	return date.toLocaleDateString('en-US', {
		weekday: 'short',
		month: 'short',
		day: '2-digit',
		year: 'numeric',
	});
};
