<template>
	<div
		class="nav-wrapper elevation-15"
		:class="{ active: showBar }"
	>
		<v-row class="justify-center">
			<div class="chevron-button">
				<v-btn
					class="justify-center nav-wrapper__toggle"
					:class="{ active: showBar }"
					icon
					data-testid="bottom-nav-toggle-button"
					@click="showBar = !showBar"
				>
					<Icon
						class="toggleUpDown"
						:class="{ rotate: showBar }"
						width="30"
						icon="mdi:chevron-up"
					/>
				</v-btn>
			</div>
		</v-row>
		<v-fade-transition>
			<v-bottom-navigation
				v-if="showBar"
				transition="fade-transition"
				class="bottom-nav"
				elevation="20"
			>
				<router-link
					class="nav-route"
					to="/account"
					data-testid="account-nav-link"
				>
					<v-btn>
						<Icon
							icon="ic:baseline-account-circle"
							width="40"
						/>
						<p>Profile</p>
					</v-btn>
				</router-link>
				<router-link
					class="nav-route"
					to="/clients"
					data-testid="clients-nav-link"
				>
					<v-btn>
						<Icon
							icon="material-symbols:supervisor-account-rounded"
							width="40"
						/>
						<p>Clients</p>
					</v-btn>
				</router-link>
				<router-link
					class="nav-route"
					to="/schedule"
					data-testid="schedule-nav-link"
				>
					<v-btn>
						<Icon
							icon="material-symbols:calendar-month"
							width="40"
						/>
						<p>Schedule</p>
					</v-btn>
				</router-link>
				<router-link
					class="nav-route"
					to="/addClient"
					data-testid="add-client-nav-link"
				>
					<v-btn>
						<Icon
							icon="ic:round-person-add"
							width="40"
						/>
						<p>Add</p>
					</v-btn>
				</router-link>
			</v-bottom-navigation>
		</v-fade-transition>
	</div>
</template>

<script setup>
import { ref } from 'vue';
import { Icon } from '@iconify/vue';

/** Refs */
const showBar = ref(true);
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
.nav-route {
	text-decoration: none;
	color: inherit;
}

.toggleUpDown {
	transition: transform 0.3s ease-in-out !important;
}

.toggleUpDown {
	&.rotate {
		transform: rotate(180deg);
	}
}

.bottom-nav {
	height: 80px !important;
	padding-top: 10px;
	z-index: 1 !important;
	border-radius: 50px 50px 0 0;

	button {
		color: $SP1500;
		p {
			text-transform: capitalize;
		}
	}
}

.chevron-button {
	position: fixed;
	z-index: 2;
	bottom: 60px;
}

.nav-wrapper {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: $SP1600;

	&:not(&.active) {
		width: 100%;
		height: 40px;
		border-top-left-radius: 50px;
		border-top-right-radius: 50px;
	}

	&__toggle {
		position: relative;
		top: 40px;
		transition: 0.3s;

		&.active {
			top: 0;
		}
	}
}
</style>
