<template>
	<v-dialog
		v-model="createAccountIsDisplayed"
		fullscreen
	>
		<v-card class="form">
			<i
				class="back-arrow material-icons"
				@click="createAccountIsDisplayed = false"
				>arrow_back_ios</i
			>
			<div class="logo">
				<img
					src="schedulepay.png"
					alt=""
				/>
				<h2>Schedule<span>pay</span></h2>
				<h3>Pro</h3>
			</div>
			<v-card-title class="form__title">Create a <span>Pro</span> Account</v-card-title>
			<v-form @submit.prevent>
				<v-row class="form__fields">
					<v-col
						cols="12"
						md="6"
					>
						<h3 class="form__label">Email</h3>
						<v-text-field
							v-model="email"
							class="custom-input rounded-pill"
							clearable
							clear-icon="close"
							variant="plain"
							:error-messages="errors.email"
							type="email"
							data-test-id="email-field"
							@blur="validateInput('email')"
							@input="validateInput('email')"
						/>
					</v-col>
					<v-col cols="12">
						<h3 class="form__label">Password</h3>
						<div class="password-input">
							<v-text-field
								v-model="password"
								:error-messages="errors.password"
								class="custom-input rounded-pill"
								clearable
								clear-icon="close"
								variant="plain"
								:type="passwordFieldType"
								data-test-id="password-field"
								@blur="validateInput('password')"
								@input="validateInput('password')"
							/>
							<v-icon
								class="password-toggle-icon"
								:class="{ 'password-visible': showPassword }"
								data-testid="show-password-icon"
								@click="togglePasswordVisibility"
							>
								{{ showPassword ? 'visibility' : 'visibility_off' }}
							</v-icon>
						</div>
					</v-col>
					<v-col
						cols="12"
						md="6"
					>
						<h3 class="form__label">First Name</h3>
						<v-text-field
							v-model="firstName"
							class="custom-input rounded-pill"
							clearable
							clear-icon="close"
							variant="plain"
							min="3"
							:error-messages="errors.firstName"
							data-test-id="firstName-field"
							@blur="validateInput('firstName')"
							@input="validateInput('firstName')"
						/>
					</v-col>
					<v-col
						cols="12"
						md="6"
					>
						<h3 class="form__label">Last Name</h3>
						<v-text-field
							v-model="lastName"
							:error-messages="errors.lastName"
							class="custom-input rounded-pill"
							clearable
							clear-icon="close"
							variant="plain"
							data-test-id="lastName-field"
							@blur="validateInput('lastName')"
							@input="validateInput('lastName')"
						/>
					</v-col>
					<v-col
						cols="12"
						md="6"
					>
						<h3 class="form__label">Business Name</h3>
						<v-text-field
							v-model="businessName"
							:error-messages="errors.businessName"
							class="custom-input rounded-pill"
							clearable
							clear-icon="close"
							variant="plain"
							data-test-id="businessName-field"
							@blur="validateInput('businessName')"
							@input="validateInput('businessName')"
						/>
					</v-col>
					<v-col cols="12">
						<h3 class="form__label">Business Address Line One</h3>
						<v-text-field
							v-model="businessAddressLineOne"
							:error-messages="errors.businessAddressLineOne"
							class="custom-input rounded-pill"
							clearable
							clear-icon="close"
							variant="plain"
							data-test-id="businessAddressLineOne-field"
							@blur="validateInput('businessAddressLineOne')"
							@input="validateInput('businessAddressLineOne')"
						/>
					</v-col>
					<v-col
						cols="12"
						md="6"
					>
						<h3 class="form__label">Business Address City</h3>
						<v-text-field
							v-model="businessAddressLineCity"
							:error-messages="errors.businessAddressLineCity"
							class="custom-input rounded-pill"
							clearable
							clear-icon="close"
							variant="plain"
							data-test-id="businessAddressLineCity-field"
							@blur="validateInput('businessAddressLineCity')"
							@input="validateInput('businessAddressLineCity')"
						/>
					</v-col>
					<v-col
						cols="12"
						md="6"
					>
						<h3 class="form__label">Business Address State</h3>
						<v-autocomplete
							v-model="businessAddressLineState"
							:items="stateOptions"
							:error-messages="errors.businessAddressLineState"
							class="custom-input rounded-pill"
							clearable
							clear-icon="close"
							variant="plain"
							data-test-id="businessAddressLineState-field"
							@blur="validateInput('businessAddressLineState')"
							@input="validateInput('businessAddressLineState')"
						></v-autocomplete>
					</v-col>
					<v-col
						cols="12"
						md="6"
					>
						<h3 class="form__label">Business Address Zip</h3>
						<v-text-field
							v-model="businessAddressLineZip"
							:error-messages="errors.businessAddressLineZip"
							class="custom-input rounded-pill"
							clearable
							clear-icon="close"
							variant="plain"
							data-test-id="businessAddressLineZip-field"
							@blur="validateInput('businessAddressLineZip')"
							@input="validateInput('businessAddressLineZip')"
						/>
					</v-col>
				</v-row>
				<OnboardingSystems v-if="squareQuickBooksOnboarding" />
				<v-row>
					<v-col
						cols="12"
						md="4"
					>
						<v-btn
							type="submit"
							block
							class="form__btn mt-6"
							data-testid="submit-button"
							@click="submitForm"
							:loading="isSubmitting"
						>
							Next
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import OnboardingSystems from '@/components/OnboardingSystems.vue';
import { useApplicationStore } from '@/stores/application';

const authStore = useAuthStore();
const { createAccountIsDisplayed, emailDialogDisplayed } = storeToRefs(authStore);

const API_URL = process.env.VUE_APP_API_URL;

const squareQuickBooksOnboarding = ref(false);

const email = ref('');
const firstName = ref('');
const lastName = ref('');
const businessName = ref('');
const businessAddressLineOne = ref('');
const businessAddressLineCity = ref('');
const businessAddressLineState = ref('');
const businessAddressLineZip = ref('');
const password = ref('');
const showPassword = ref(false);
const isSubmitting = ref(false);

const errors = ref({
	email: '',
	firstName: '',
	lastName: '',
	businessName: '',
	businessAddressLineOne: '',
	businessAddressLineCity: '',
	businessAddressLineState: '',
	businessAddressLineZip: '',
	password: '',
});
const stateOptions = ref([]);

onMounted(async () => {
	await useApplicationStore().fetchStates();
	useApplicationStore().states.forEach((state) => {
		stateOptions.value.push(state.name);
	});
});

const validateInput = (key) => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	switch (key) {
		case 'email':
			if (email.value === null) {
				email.value = '';
			}

			errors.value.email =
				email.value.trim() === '' ? 'This field is required' : emailRegex.test(email.value) ? '' : 'Invalid email address';
			break;
		case 'firstName':
			if (firstName.value === null) {
				firstName.value = '';
			}

			errors.value.firstName =
				firstName.value.trim() === ''
					? 'This field is required'
					: firstName.value.length >= 3
						? ''
						: 'First name must be at least 3 characters';
			break;
		case 'lastName':
			if (lastName.value === null) {
				lastName.value = '';
			}

			errors.value.lastName = lastName.value.trim() === '' ? 'This field is required' : '';
			break;
		case 'businessName':
			if (businessName.value === null) {
				businessName.value = '';
			}

			errors.value.businessName = businessName.value.trim() === '' ? 'This field is required' : '';
			break;
		case 'businessAddressLineOne':
			if (businessAddressLineOne.value === null) {
				businessAddressLineOne.value = '';
			}

			errors.value.businessAddressLineOne = businessAddressLineOne.value.trim() === '' ? 'This field is required' : '';
			break;
		case 'businessAddressLineCity':
			if (businessAddressLineCity.value === null) {
				businessAddressLineCity.value = '';
			}

			errors.value.businessAddressLineCity = businessAddressLineCity.value.trim() === '' ? 'This field is required' : '';
			break;
		case 'businessAddressLineState':
			if (businessAddressLineState.value === null) {
				businessAddressLineState.value = '';
			}

			errors.value.businessAddressLineState = businessAddressLineState.value.trim() === '' ? 'This field is required' : '';
			break;
		case 'businessAddressLineZip':
			if (businessAddressLineZip.value === null) {
				businessAddressLineZip.value = '';
			}

			errors.value.businessAddressLineZip =
				businessAddressLineZip.value.trim() === ''
					? 'This field is required'
					: /^\d{5}$/.test(businessAddressLineZip.value)
						? ''
						: 'ZIP code must be a 5-digit number';
			break;
		case 'password':
			if (password.value === null) {
				password.value = '';
			}

			errors.value.password =
				password.value.trim() === ''
					? 'This field is required'
					: /^(?=.*[A-Z])(?=.*[a-zA-Z0-9])(?=.*[^a-zA-Z0-9])/.test(password.value)
						? ''
						: 'Password requires at least 1 Digit, Uppercase and Special character.';
			break;
		default:
			break;
	}
};

const submitForm = async () => {
	if (
		email.value &&
		firstName.value &&
		lastName.value &&
		businessName.value &&
		businessAddressLineOne.value &&
		businessAddressLineCity.value &&
		businessAddressLineState.value &&
		businessAddressLineZip.value
	) {
		const formData = {
			email: email.value,
			firstName: firstName.value,
			lastName: lastName.value,
			businessName: businessName.value,
			businessAddressLineOne: businessAddressLineOne.value,
			businessAddressLineCity: businessAddressLineCity.value,
			businessAddressLineState: businessAddressLineState.value,
			businessAddressLineZip: businessAddressLineZip.value,
			password: password.value,
		};
		try {
			isSubmitting.value = true;
			const response = await axios.post(
				`${API_URL}/Onboarding/RegisterLcp`,
				{ ...formData },
				{ headers: { 'Content-Type': 'application/json; charset=utf-8' } },
			);

			if (response.status === 200) {
				window.open(response.data.url);
				// Handle successful response
				createAccountIsDisplayed.value = false;
				emailDialogDisplayed.value = true;
				email.value = '';
				firstName.value = '';
				lastName.value = '';
				businessName.value = '';
				businessAddressLineOne.value = '';
				businessAddressLineCity.value = '';
				businessAddressLineState.value = '';
				businessAddressLineZip.value = '';
				password.value = '';
			} else {
				console.warn('Unexpected response status:', response.status);
			}
		} catch (error) {
			console.error(error);
			alert('Account sign up failed. Please contact support.');
		} finally {
			isSubmitting.value = false;
		}
	} else {
		validateInput('email');
		validateInput('firstName');
		validateInput('lastName');
		validateInput('businessName');
		validateInput('businessAddressLineOne');
		validateInput('businessAddressLineCity');
		validateInput('businessAddressLineState');
		validateInput('businessAddressLineZip');
		validateInput('password');
	}
};

const passwordFieldType = computed(() => (showPassword.value ? 'text' : 'password'));
function togglePasswordVisibility() {
	showPassword.value = !showPassword.value;
}
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');

.form {
	padding: 3rem 1.5rem 0rem 1.5rem;
	gap: 1.5rem;
}

.back-arrow {
	font-size: 2.5rem !important;
	color: $SP400;
}

.logo {
	text-align: center;
	font-size: 1.5rem;
	color: $SP1500;

	& > h2 {
		font-weight: 400;

		& span {
			color: $SP400;
		}
	}

	& > h3 {
		font-style: italic;
	}
}

.form {
	&__fields {
		gap: 1.25rem;
	}

	&__title {
		text-align: center;
		font-size: 1.5rem;
		color: $SP400;
		font-family: 'Inter', sans-serif;
		letter-spacing: 0;
		white-space: unset;

		& > span {
			font-style: italic;
		}
	}

	&__label {
		color: $SP400;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		letter-spacing: 0px;
		line-height: 22px;
		margin-bottom: 5px;
	}

	&__btn {
		background: $SP500;
		color: $SP1100;
		border-radius: 22px;
		width: 360px !important;
		height: 67px !important;
		opacity: 1;
		font-size: 2rem;
		text-transform: unset;
	}

	.custom-input {
		background: #dce3e3;
		border-radius: 33px;
		height: 57px;
	}
}

/* Internal Clases to match the design */
.v-field__append-inner {
	padding: 0;
}

:deep(.v-field__input) {
	font-size: 1.15rem !important;
	color: $SP400;
	padding: 0 !important;
	padding-left: 20px !important;
}

:deep(.v-field__field) {
	align-items: center !important;
	justify-content: center !important;
}

:deep(.v-input__control) {
	height: 57px;
}

:deep(.v-field__clearable) {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	padding: 0px 15px 0px 0px !important;
	color: $SP1500;
	cursor: pointer;

	.v-icon--clickable {
		font-size: 2.5rem;
	}
}
.password-input {
	position: relative;
	display: flex;
	align-items: center;
}
.password-toggle-icon {
	position: absolute;
	top: 16px;
	right: 55px;
	cursor: pointer;
}
</style>
