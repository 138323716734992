<template>
	<v-dialog
		v-model="doneMowingSingleYardDialog"
		class="confirmation-modal text-center"
		fullscreen
	>
		<v-card class="rounded-xl">
			<v-card-title class="card-title mt-5"> {{ resolvingJob ? 'Resolve Job' : 'Done Mowing' }} </v-card-title>

			<v-card-text class="text-center">
				<h3>{{ getProperty.firstName }} {{ getProperty.lastName }}</h3>
				<h3>{{ getProperty.addressLineOne }}</h3>
				<h3>{{ getProperty.city }}, {{ getProperty.state }}</h3>
				<div class="mowing-details">
					<div class="mowing-data">
						<label class="label">Mowing Rate</label>
						<div class="data">
							<div class="amount">${{ getProperty.mowingRate }}</div>
							<div class="edit-icon"></div>
						</div>
					</div>
					<div class="mowing-data">
						<label class="label">Mowing Frequency</label>
						<div class="data">
							<div class="amount">{{ getProperty.mowingFrequency }} Days</div>
							<div class="edit-icon"></div>
						</div>
					</div>
					<div class="mowing-data align-center">
						<label class="label">Next Mow if Done</label>
						<div class="data mowing-date">
							<div class="amount">{{ dateFormat(nextMowDate, "dddd MM/DD/YYYY") }}</div>
						</div>
					</div>
					<div
						v-if="resolvingJob"
						class="date-field"
					>
						<v-row justify="space-around">
							<v-date-picker
								v-model="mowedDate"
								title="Date Mowed"
								show-adjacent-months
							></v-date-picker>
						</v-row>
					</div>
					<div class="fees">
						<div class="fees__header">
							<h2 class="fees__main-heading">Additional Fees &amp; Services</h2>
						</div>
						<v-btn
							size="small"
							class="pl-1 mb-2 mt-3 rounded-pill add-fee-button"
							@click="
								showAddFeeDialog = true;
								editingFee = {};
							"
							><v-icon
								class="mr-1"
								size="small"
								icon="add_circle"
							/>
							<span>Add Fees</span>
						</v-btn>

						<div class="fees__details">
							<div
								v-if="editCard"
								class="new-fee"
							>
								<DropdownEditFeeCard @close-card="closeEditCard" />
							</div>
							<div
								v-else
								class="fees__info d-flex flex-column gap"
							>
								<div
									v-for="fees in getPropertyFees"
									:key="fees.title"
									class="fees__data d-flex align-center justify-space-between"
								>
									<div class="data flex-grow-1 text-left text-h6 font-weight-regular">
										<p
											@click="
												editCard = true;
												editingFee = fees;
											"
										>
											{{ fees.title }}
										</p>
									</div>
									<div
										class="font-weight-bold"
										@click="
											editCard = true;
											editingFee = fees;
										"
									>
										${{ fees.amount }}
									</div>
									<v-icon
										class="ml-2"
										size="x-small"
										color="warning"
										icon="do_not_disturb_on"
										@click="(e) => removeFeeListing(e, fees)"
									/>
								</div>
							</div>
							<div class="total">
								<div class="text">Total Extra Fees</div>
								<div class="total__amount">${{ totalAdditionalFees }}</div>
							</div>
						</div>
					</div>
					<div class="bills">
						<h3 class="bills__heading">Today's Total Bill</h3>
						<div class="bills__content">
							<div class="bills__info">
								<div class="data">
									<p class="text">Mowing Rate</p>
									<div class="bill__amount">${{ getProperty.mowingRate }}</div>
								</div>

								<div class="data">
									<p class="text">Total Extra Fees</p>
									<div class="bill__amount">${{ totalAdditionalFees }}</div>
								</div>
							</div>

							<div class="total">
								<div class="text">Total Extra Fees</div>
								<div class="total__amount">${{ getProperty.mowingRate + totalAdditionalFees }}</div>
							</div>
						</div>
					</div>
				</div>
			</v-card-text>

			<v-card-actions class="flex-column justify-center">
				<v-btn
					class="mb-7 rounded-pill confirm-btn text text-capitalize"
					variant="flat"
					width="80%"
					height="48"
					@click="doneMowing(getProperty.propertyId)"
          :loading="isLoading"
				>
					<span>Confirm</span>
				</v-btn>

				<v-btn
					class="mb-7 rounded-pill close-btn text text-capitalize"
					variant="flat"
					width="80%"
					height="48"
					@click="doneMowingSingleYardDialog = false"
				>
					<span>Close Dialog</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script setup>
import { useScheduleStore } from '@/stores/schedule';
import { storeToRefs } from 'pinia';
import { computed, toRaw, ref, watch, defineProps } from 'vue';
import DropdownEditFeeCard from './DropdownEditFeeCard.vue';
import { dateFormat } from '@/utils/dateFormatter.js';
const menu = ref(false);
const date = ref(new Date().toISOString().substr(0, 10)); // Default to today
const props = defineProps({
	resolvingJob: {
		type: Boolean,
		required: false,
		default: false,
	},
});
const mowedDate = ref(new Date());
const editCard = ref(false);
const isLoading=ref(false);
const closeEditCard = () => {
	editCard.value = false;
};

const scheduleStore = useScheduleStore();
const emit = defineEmits(['setSevenDayArray']);
const { doneMowingSingleYardDialog, getProperty, getPropertyFees, showAddFeeDialog, editingFee } =
	storeToRefs(scheduleStore);
const nextMowDate = computed(() => {
	const newDate = new Date();
	newDate.setDate(newDate.getDate() + toRaw(getProperty.value).mowingFrequency);
	return newDate;
});

const totalAdditionalFees = computed(() => {
	let feeTotal = 0;
	getPropertyFees.value.forEach((fee) => {
		feeTotal += fee.amount;
	});
	return feeTotal;
});
watch(doneMowingSingleYardDialog, () => {
	if (doneMowingSingleYardDialog.value) {
		mowedDate.value = new Date(toRaw(getProperty.value).nextMow);
	}
});

const doneMowing = async (propertyId) => {
  isLoading.value=true;
  try{
	const dateFinishedMowing = props.resolvingJob ? dateFormat(mowedDate.value, "MM/DD/YYYY") : dateFormat(new Date(), "MM/DD/YYYY");
	const filteredAdditionalList = getPropertyFees.value.map((fees) => {
		if (fees.propertyId === propertyId) {
			return { ServiceTitle: fees.title, ServiceDetails: fees.description, ServiceFee: fees.amount };
		}
	});
	const mowingDetails = {
		PropertyId: propertyId,
		DateTimeMowed: dateFinishedMowing,
		Services: filteredAdditionalList || [],
	};
	await scheduleStore.setDoneMowing(mowingDetails);
	emit('setSevenDayArray');
	doneMowingSingleYardDialog.value = false;
    isLoading.value=false;
  }catch (e){
    isLoading.value=false;
    console.error(e);
  }
};

const removeFeeListing = (e, toBeRemovedFee) => {
	scheduleStore.removeFee(toBeRemovedFee);
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';

.confirmation-modal {
	font-family: 'Raleway', sans-serif;
	.v-card {
		border-radius: 20px;
	}
	h3 {
		font-weight: 400;
		font-size: 20px;
		margin-bottom: 0;
	}
	.mowing-details {
		margin: 3rem 0.5rem 1rem 0.5rem;
		color: $SP400;

		.mowing-data {
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;
			.label {
				font-size: 18px;
				font-weight: bold;
				text-align: left;
			}

			.data {
				display: flex;
				text-align: right;
			}
			.mowing-date {
				margin-right: 30px;
			}
		}
		.date-field {
			margin-top: 2.5rem;
			font-size: 18px;
			font-weight: bold;
		}

		.edit-icon {
			display: block;
			width: 20px;
			height: 20px;
			margin-left: 10px;
			background-image: $edit;
			background-repeat: no-repeat;
			background-position: center;
			cursor: pointer;
			filter: invert(34%) sepia(48%) saturate(2526%) hue-rotate(6deg) brightness(96%) contrast(91%);
		}

		.fees {
			&__header {
				display: flex;
				text-align: left;
				justify-content: space-between;
			}

			.add-fees-btn {
				display: flex;
				flex-direction: column;
			}

			.add-fee-button {
				color: $SP1100;
				display: flex;
				background-color: $SP800;
			}

			&__details {
				margin: 1rem 0;

				.total {
					display: flex;
					justify-content: space-between;
					font-size: 20px;
					font-weight: bold;
					color: $SP800;
					margin: 5px 0;
				}
			}

			&__info {
				border-bottom: 2px solid;
				gap: 10px;
			}

			&__data {
				.data {
					max-width: 70%;
				}

				.fees__amount {
					font-weight: bold;
				}
			}

			&__data:last-child {
				margin-bottom: 1rem;
			}
		}

		.bills {
			margin-top: 2rem;

			&__heading {
				font-size: 28px;
				margin-bottom: 10px;
			}

			&__content {
				.total {
					display: flex;
					justify-content: space-between;
					font-size: 20px;
					font-weight: bold;
					color: $SP800;
					margin: 5px 0;
				}
			}

			&__info {
				border-bottom: 2px solid;

				.data {
					display: flex;
					justify-content: space-between;
					font-size: 20px;
				}

				.data:last-child {
					margin-bottom: 1rem;
				}
			}
		}
	}
	.confirm-btn,
	.close-btn {
		color: $SP1600;
		font-weight: bold;
		font-size: 16px;
		text-transform: capitalize;
	}
	.confirm-btn {
		background-color: $SP500;
	}
	.close-btn {
		background-color: $SP400;
	}
}
</style>
