<template>
	<v-container v-if="selectedJobDetails">
		<v-row>
			<v-col cols="12">
				<div
					class="text-center service__title"
					@click="$router.push(`/clients/${selectedJobDetails.propertyOwnerId}`)"
				>
					{{ selectedJobDetails.propertyOwnerName }}
				</div>
				<div
					class="text-center mt-5 service__address"
					@click="$router.push(`/propertyDetails/${selectedJobDetails.propertyId}`)"
				>
					{{ selectedJobDetails.addressLineOne ? selectedJobDetails.addressLineOne + ', ' : '' }}
					{{ selectedJobDetails.city ? selectedJobDetails.city + ', ' : '' }}
					{{ selectedJobDetails.zip }}
				</div>
				<div
					class="text-center mt-5 service__date"
					@click="navigateToDateDetails"
				>
					Date Serviced: {{ propertyInfo.date ? new Date(propertyInfo.date).toLocaleDateString() : '12/12/2023' }}
				</div>
				<div class="text-center mt-7 up-invoice">Unpaid Job</div>
			</v-col>
		</v-row>
		<div class="mt-10 pa-5">
			<v-row class="mt-6">
				<v-col class="table-val text-left">Details</v-col>
				<v-col class="table-val text-right">Amount</v-col>
			</v-row>
			<hr class="mt-2 mb-8 blue-border" />
			<v-row class="mt-2">
				<v-col class="table-lab text-left">Mow</v-col>
				<v-col class="table-lab text-right">${{ selectedJobDetails.mowingRate?.toFixed(2) }}</v-col>
			</v-row>
			<v-row
				v-for="service in selectedJobDetails.services"
				:key="service.propertyServicesId"
				class="mt-2"
			>
				<v-col class="table-lab text-left">{{ service.serviceDetails }}</v-col>
				<v-col class="table-val text-right">${{ service.amount?.toFixed(2) }}</v-col>
			</v-row>
			<hr class="mt-13" />
			<v-row class="mt-3">
				<v-col class="table-lab text-left">Total</v-col>
				<v-col class="table-total text-right"
					>{{ selectedJobDetails.jobStatus }} ${{ selectedJobDetails.jobTotal?.toFixed(2) }}</v-col
				>
			</v-row>
			<v-row class="mt-13 mb-8 justify-center"
				><v-btn
					class="button success py-2 text-capitalize"
					size="large"
					@click="showEditJobDialog = true"
					>Edit Job</v-btn
				></v-row
			>

			<EditJobDetailsDialogVue />
			<AddChargesDialog />
			<!-- <EditJobDetailsDialogVue :show-dialog="showEditJobDialog" :client-info = "clientInfo" :property-info="propertyInfo" :property-services = "propertyServices" :mowing-rate="mowingRate"/> -->
		</div>
	</v-container>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';
import EditJobDetailsDialogVue from '../components/EditJobDetailsDialog.vue';
import AddChargesDialog from '../components/AddChargesDialog.vue';

import { useScheduleStore } from '@/stores/schedule';
import { storeToRefs } from 'pinia';

const scheduleStore = useScheduleStore();
const { showEditJobDialog, selectedJobDetails, fetchJobDetail } = storeToRefs(scheduleStore);

const route = useRoute();
const clientInfo = ref({});
const propertyInfo = ref({});
const propertyServices = ref([]);
const jobStatus = ref('');
const mowingRate = ref(0);
const jobDate = ref('');
const totalPropertyServicesAmount = ref(0);
// const showEditJobDialog = ref(false);

const navigateToDateDetails = computed(() => route.push(`/propertyDetails/${propertyInfo.id}`));

onMounted(async () => {
	await scheduleStore.fetchJobDetail();
	const token = localStorage.getItem('token');

	const API_URL = process.env.VUE_APP_API_URL;
	const { data } = await axios.get(`${API_URL}/Property/Services?jobId=${route.params.id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	totalPropertyServicesAmount.value = data.jobsInfo.jobTotal;
	mowingRate.value = data.jobsInfo.mowingRate;
	clientInfo.value = {
		name: data.jobsInfo.propertyOwnerName,
		id: data.jobsInfo.propertyOwnerId,
		date: data.jobsInfo.jobdate,
	};
	propertyInfo.value = {
		id: data.jobsInfo.propertyId,
		address: `${data.jobsInfo.addressLineOne ? data.jobsInfo.addressLineOne + ', ' : ''} ${
			data.jobsInfo.city ? data.jobsInfo.city + ', ' : ''
		} ${data.jobsInfo.state} ${data.jobsInfo.zip}`,
	};
	propertyServices.value = data.jobsInfo.services;
	jobStatus.value = data.jobsInfo.jobStatus;
});
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
.service__title {
	font: normal normal 800 27px/31px Raleway;
	color: $SP900;
	cursor: pointer;
}

.service__address {
	font: normal normal 600 18px/21px Raleway;
	color: $SP1300;
	cursor: pointer;
}
.service__date {
	font: normal normal 600 18px/21px Raleway;
	color: $SP1300;
	cursor: pointer;
}
.blue-border {
	border: 2px solid $SP2000;
}
.up-invoice {
	font: normal normal 600 27px/31px Raleway;
	color: $SP500;
	opacity: 1;
}

.card-title {
	font: normal normal 600 27px/31px Raleway;
	color: $SP1500;
}

.table-val {
	font: normal normal medium 17px/20px Raleway;
	color: $SP1300;
	font-size: 18px;
}
.table-lab {
	font: normal normal bold 17px/20px Raleway;
	color: $SP1300;
	font-size: 18px;
}

.table-total {
	font: normal normal bold 17px/20px Raleway;
	color: $SP500;
}

.card {
	background: $SP1100 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px $SP1700;
	border-radius: 50px;
}

hr {
	border: 2px solid $SP900;
}

.button {
	width: 320px;
	height: 55px;
	border-radius: 28px;
	text-align: center;
	font: normal normal bold 17px/20px Raleway;
	color: $SP1100;
	box-shadow: 0px 3px 6px $SP1700;
}
.success {
	background: $SP800 0% 0% no-repeat padding-box;
}

.write-off {
	background: $SP500 0% 0% no-repeat padding-box;
}

.resend {
	background: $SP700 0% 0% no-repeat padding-box;
}
</style>
