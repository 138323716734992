import { defineStore } from 'pinia';
import { computed, ref, toRaw } from 'vue';

export const useInviteClientStore = defineStore('clientStore', () => {
	const selectedClients = ref({});
	const firstName = ref('');
	const lastName = ref('');
	const email = ref('');
	const phone = ref('');
	const addressLineOne = ref('');
	const city = ref('');
	const state = ref('');
	const zip = ref('');
	const selectedOption = ref('email');
	const showManualForm = ref(false);
	const setState = (contactsObject) => {
		selectedClients.value = contactsObject;
		firstName.value = getFirstName.value;
		lastName.value = getLastName.value;
		phone.value = getPhone.value;
		addressLineOne.value = getStreetAddress.value;
		city.value = getCity.value;
		state.value = getState.value;
		zip.value = getZip.value;
		email.value = getEmail.value;
	};

	const getFirstName = computed(() => {
		if (isEmpty(toRaw(selectedClients).value)) {
			return '';
		}

		const fullName = selectedClients.value.name[0];

		const splitName = fullName.split(' ');

		return splitName[0];
	});

	const getLastName = computed(() => {
		if (isEmpty(toRaw(selectedClients).value)) {
			return '';
		}

		const fullName = selectedClients.value.name[0];

		const splitName = fullName.split(' ');

		return splitName[splitName.length - 1];
	});

	const getPhone = computed(() => (isEmpty(toRaw(selectedClients).value) ? '' : selectedClients.value?.tel[0]));

	const getStreetAddress = computed(() => {
		if (isEmpty(toRaw(selectedClients).value) || !selectedClients.value.address[0]?.addressLine) {
			return '';
		}

		const addressLine = selectedClients.value.address[0]?.addressLine;
		const streetAddress = addressLine[0].split('\n');
		return streetAddress[0];
	});

	const getCity = computed(() =>
		isEmpty(toRaw(selectedClients).value) ? '' : selectedClients.value.address[0]?.city || '',
	);
	const getState = computed(() =>
		isEmpty(toRaw(selectedClients).value) ? '' : selectedClients.value.address[0]?.region || '',
	);
	const getZip = computed(() =>
		isEmpty(toRaw(selectedClients).value) ? '' : selectedClients.value.address[0]?.postalCode || '',
	);
	const getEmail = computed(() => (isEmpty(toRaw(selectedClients).value) ? '' : selectedClients.value.email[0]));

	function isEmpty(obj) {
		return Object.keys(obj).length === 0;
	}

	return {
		setState,
		selectedClients,
		getEmail,
		getZip,
		getState,
		getCity,
		getStreetAddress,
		getPhone,
		getLastName,
		getFirstName,
		firstName,
		lastName,
		email,
		phone,
		addressLineOne,
		state,
		city,
		zip,
		selectedOption,
		showManualForm,
	};
});
