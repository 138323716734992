<template>
	<v-container>
		<AddnewClientForm
			:reset-form="resetForm"
			@input-values-updated="handleInputValues"
		/>
		<div
			v-if="showManualForm"
			cols="12"
			md="4"
		>
			<v-title class="card-title my-5">Payment Type</v-title>
			<v-select
				v-model="customerPaymentType"
				class="card__input rounded-pill"
				variant="plain"
				clearable
				required
				label="Select"
				:items="paymentTypes"
			/>
		</div>
		<div v-if="showManualForm && customerPaymentType === 'card'">
			<v-title class="card-title my-5">Card Info</v-title>
			<div id="card-element">
				<!-- Stripe card element will be mounted here -->
			</div>
		</div>

		<v-row class="justify-center submit-wrapper">
			<v-btn
				:loading="loading"
				class="submit-btn rounded-pill"
				data-testid="add-client-btn"
				@click="showManualForm ? submitDisplay() : sendInvite()"
			>
				Submit
			</v-btn>

			<v-dialog
				v-model="submitSuccess"
				activator="child"
				transition="dialog-bottom-transition"
			>
				<v-card
					v-if="success"
					class="success-Card py-8 px-4"
				>
					<h2 class="success-Card-Title">
						{{ inviteSuccess ? `Invite Sent Successfully` : `Client successfully saved` }}
					</h2>
					<span class="success-Card-subTitle">{{ successMessage }}</span>
					<v-btn
						class="success-Card-Close"
						href="#contact-section"
						@click="submitSuccess = false"
					>
						<span class="btn-text">OK</span>
					</v-btn>
				</v-card>
				<v-card
					v-else
					class="success-Card py-8 px-4"
				>
					<h2 class="success-Card-Title">
						{{ errorMessage }}
					</h2>
					<span class="success-Card-subTitle">{{
						errorMessageDetail
							? errorMessageDetail
							: `The new client wasn't
            saved to the database, Please try again`
					}}</span>
					<v-btn
						class="success-Card-Close"
						href="#contact-section"
						@click="submitSuccess = false"
					>
						<span class="btn-text">OK</span>
					</v-btn>
				</v-card>
			</v-dialog>

			<v-dialog
				v-model="showEmailConfirmationDialog"
				activator="child"
				transition="dialog-bottom-transition"
			>
				<v-card class="success-Card py-8 px-4">
					<h2 class="success-Card-Title">Proceed Without Email?</h2>
					<span class="success-Card-subTitle">Are you sure you want to continue without Email?</span>
					<v-card-actions class="flex justify-center">
						<v-btn
							class="success-Card-No"
							href="#contact-section"
							size="large"
							@click="showEmailConfirmationDialog = false"
						>
							<span class="btn-text">No</span>
						</v-btn>
						<v-btn
							class="success-Card-Close"
							href="#contact-section"
							size="large"
							@click="submitDisplay()"
						>
							<span class="btn-text">Yes</span>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
	</v-container>
</template>

<script setup>
import { ref, computed, nextTick, onMounted, watch } from 'vue';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import useStripe from '@/services/stripeService';
import AddnewClientForm from '@/components/AddnewClientForm.vue';
import { useInviteClientStore } from '@/stores/invite-client-store';
const clientInviteStore = useInviteClientStore();
const { email, phone, showManualForm, selectedOption } = storeToRefs(clientInviteStore);

const resetForm = ref(false);
const API_URL = process.env.VUE_APP_API_URL;
const submitSuccess = ref(false);
const inviteSuccess = ref(false);
let success = false;
const errorMessage = ref('Error:');
const loading = ref(false);
const formInputs = ref({});
const errors = ref({});
const errorMessageDetail = ref('');
const paymentTypes = ref(['cash', 'check', 'card']);
const customerPaymentType = ref(null);
const showEmailConfirmationDialog = ref(false);

const { stripe, elements } = useStripe();
const cardElement = ref(null);
const successMessage = computed(() =>
	inviteSuccess.value ? 'Please check your client list to monitor the invite status.' : '',
);

const cardStyle = ref({
	style: {
		base: {
			iconColor: 'var(--icon-color, #303c3d)',
			color: 'var(--icon-color, #303c3d)',
			fontWeight: '500',
			fontFamily: 'Raleway, sans-serif',
			fontSize: '22px',
			backgroundColor: 'var(--background-color, #f8fafc)',
			lineHeight: 3,
			':-webkit-autofill': {
				color: 'var(--icon-color, #303c3d)',
			},
			'::placeholder': {
				color: 'var(--placeholder-color, #848686)',
			},
		},
		invalid: {
			iconColor: 'var(--invalid-icon-color, red)',
			color: 'var(--invalid-icon-color, red)',
		},
	},
});

onMounted(async () => {
	nextTick(() => {
		if (elements.value && typeof elements.value.create === 'function') {
			try {
				cardElement.value = elements.value.create('card', cardStyle.value);
			} catch (error) {
				console.error('Error creating card element:', error);
			}
		}
	});
});

const watchPaymentFunction = () => {
	if (showManualForm.value && customerPaymentType.value === 'card') {
		nextTick(() => {
			if (elements.value) {
				cardElement.value.mount('#card-element');
			}
		});
	}
};

watch(showManualForm, () => {
	watchPaymentFunction();
});

watch(customerPaymentType, () => {
	watchPaymentFunction();
});

async function fetchPaymentToken() {
	try {
		if (!stripe.value || !cardElement.value) {
			console.error("Stripe hasn't been initialized properly.");
			return;
		}
		var { token, error } = await stripe?.value?.createToken(cardElement?.value);
		if (error) {
			loading.value = false;
			errorMessageDetail.value = error.message;
			errorMessage.value = 'Invalid Card Details';
			console.error(error.message);
			return { error };
		}
	} catch (e) {
		loading.value = false;
		errorMessage.value = 'Invalid Card Details';
		errorMessageDetail.value = e.message;
	}
	return { token };
	// Send the token to your server to process the payment
}

const handleInputValues = (payload, otherProps) => {
	formInputs.value = payload;
	errors.value = otherProps.errors;
};

const checkValidationErrors = (obj) => {
	if (!Object.keys(obj).length) {
		return true;
	}

	for (const key in obj) {
		// eslint-disable-next-line no-prototype-builtins
		if (obj.hasOwnProperty(key) && obj[key] === '') {
			if (key === 'Email') {
				if (showEmailConfirmationDialog.value) {
					showEmailConfirmationDialog.value = false;
					continue;
				} else {
					showEmailConfirmationDialog.value = true;
					return true;
				}
			} else {
				showValidationError();
				return true;
			}
		}
	}

	for (const key in errors.value) {
		if (key != 'email' && (errors.value[key] || errors.value[key].length)) {
			showValidationError();
			return true;
		}
	}

	return false;
};

const showValidationError = () => {
	errorMessage.value = 'Validation Errors';
	success = false;
	submitSuccess.value = true;
};

const sendInvite = async () => {
	try {
		let payload = {
			IsSMS: selectedOption.value === 'phone',
			IsEmail: selectedOption.value === 'email',
			Phone: phone.value.length ? phone.value : '',
			Email: email.value,
			MowingRate: formInputs.value.MowingRate,
			StartServiceDate: formInputs.value.StartServiceDate,
		};
		const token = localStorage.getItem('token');
		axios.defaults.headers.common = { Authorization: `bearer ${token}` };
		loading.value = true;
		await axios.post(`${API_URL}/Client/SendInvite`, payload, {
			headers: {
				'content-type': 'application/json; charset=utf-8',
				'cache-control': 'private',
			},
		});
		success = true;
		loading.value = false;
		resetForm.value = true;
		inviteSuccess.value = true;

		return (submitSuccess.value = true);
	} catch (err) {
		if (err?.response?.status === 400) {
			errorMessage.value = 'Invite Already Sent';
			errorMessageDetail.value = 'This user is already in our system and cannot be invited.';
		} else if (err?.response?.status === 500) {
			errorMessage.value = ' Ops! Something went wrong';
			errorMessageDetail.value = 'Please contact us for additional help';
		} else {
			errorMessage.value = 'Something went wrong';
			errorMessageDetail.value = 'Please contact us for additional help';
		}

		submitSuccess.value = true;
		success = false;
		loading.value = false;
	}
};

const submitDisplay = async () => {
	try {
		loading.value = true;
		resetForm.value = false;
		if (checkValidationErrors(formInputs.value)) {
			errorMessage.value = 'did you filled all?';
			errorMessageDetail.value = 'Please fill all fields in form';
			loading.value = false;
			return;
		}
		formInputs.value.PaymentType = paymentTypes.value.indexOf(customerPaymentType.value);
		if (customerPaymentType.value === 'card') {
			const paymentToken = await fetchPaymentToken();
			if (paymentToken.token === undefined || paymentToken === null) {
				// Return early if paymentToken is undefined or null
				return (submitSuccess.value = true);
			} else if (paymentToken.error) {
				errorMessage.value = 'Payment Details Error';
				success = false;
				loading.value = false;
				return (submitSuccess.value = true);
			}
			formInputs.value.CardToken = paymentToken?.token?.id;
		}
		const token = localStorage.getItem('token');
		axios.defaults.headers.common = { Authorization: `bearer ${token}` };
		let payload = formInputs.value;
		await axios.post(`${API_URL}/Client/AddClient`, payload, {
			headers: {
				'content-type': 'application/json; charset=utf-8',
				'cache-control': 'private',
			},
		});
		success = true;
		loading.value = false;
		resetForm.value = true;
		formInputs.value = {};

		return (submitSuccess.value = true);
	} catch (err) {
		loading.value = false;
		errorMessageDetail.value = '';
		if (err?.response?.status === 409) {
			errorMessage.value = err.response.data.email == '' ? 'Username Already Exist' : 'Email Already Exist';
		} else if (err?.response?.status === 400) {
			errorMessage.value = 'Invalid Data';
		} else {
			errorMessage.value = 'Something went wrong';
			errorMessageDetail.value = err.message;
		}
		submitSuccess.value = true;
		success = false;
		loading.value = false;
	}
};
</script>

<style lang="scss">
@import '../../styles.scss';

.main-card {
	padding: 54px 12px 30px;
	box-shadow: none !important;
}

.card-title {
	display: flex;
	justify-content: center;
	font: normal normal 600 27px/31px Raleway;
	letter-spacing: 0px;
	color: $SP400;
	opacity: 1;
}

.mar-b-30 {
	margin-bottom: 30px;
}

.mar-t-30 {
	margin-top: 30px;
}

.mar-t-6 {
	margin-top: 6px;
}

.mar-b-11 {
	margin-bottom: 11px;
}

.mar-b-5 {
	margin-bottom: 5px;
}

.address-sub-title {
	display: flex;
	font: normal normal medium 20px/24px Raleway;
	letter-spacing: 0px;
	color: $SP400;
	opacity: 1;
	margin-left: 15px;
}

.service-sub-title {
	display: flex;
	text-align: left;
	font: normal normal 300 17px/20px Raleway;
	letter-spacing: 0px;
	color: $SP400;
	opacity: 1;
	padding: 10px;
}

h3 {
	text-align: center;
	font: normal normal medium 19px/22px Raleway;
	letter-spacing: 0px;
	color: $SP400;
	opacity: 1;
	margin-bottom: 7px;
}

.card__input {
	background: $SP600 0% 0% no-repeat padding-box;
	border-radius: 33px;
	opacity: 1;
	padding: 0rem 1rem;
	margin-bottom: 1px;
}

.next-button {
	background: $SP500 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px $SP1700;
	text-align: left;
	font: normal normal bold 22px/26px Raleway;
	letter-spacing: 0px;
	color: $SP1100;
	opacity: 1;
	margin-top: 18px;
	padding: 11px 25px;
	width: 134px;
	height: 48px;
}

.submit-btn {
	width: 100%;
	height: 62px !important;
	background: $SP500 0% 0% no-repeat padding-box !important;
	text-align: left;
	font: normal normal bold 33px/39px Raleway;
	letter-spacing: 0px;
	color: $SP1600 !important;
	opacity: 1;
	font-size: 25px !important;
}

.submit-wrapper {
	width: 100%;
	margin: 30px 0 115px !important;
}

.success-Card {
	border-radius: 50px !important;
	padding: 70px 42px 55px 45px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.success-Card-Title {
		margin-bottom: 12px;
		text-align: center;
		font: normal normal 600 27px/31px Raleway;
		letter-spacing: 0px;
		color: $SP400;
		opacity: 1;
	}

	.success-Card-Image {
		margin-bottom: 28px;
		width: 255px;
		height: 255px;
	}

	.success-Card-subTitle {
		margin-bottom: 58px;
		text-align: center;
		font: normal normal normal 21px/38px Raleway;
		letter-spacing: 0px;
		color: $SP400;
		opacity: 1;
	}

	.success-Card-Close,
	.success-Card-No {
		width: 229px;
		height: 55px;
		background: $SP800 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px $SP1700;
		border-radius: 28px;
		opacity: 1;

		span {
			text-align: center;
			font: normal normal bold 23px/27px Raleway;
			letter-spacing: 0px;
			color: $SP800;
			opacity: 1;
		}

		.btn-text {
			color: $SP1100;
			z-index: 10;
		}
	}
	.success-Card-No {
		background: $SP500 0% 0% no-repeat padding-box;
		span {
			color: $SP500;
		}
	}
}
</style>
