import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';

const API_URL = process.env.VUE_APP_API_URL;

export const useScheduleStore = defineStore('schedule', () => {
	const sevenDayArray = ref([]);
	const clickedDay = ref('');
	const fees = ref([]);
	const allJobsDoneDialog = ref(false);
	const allJobsDoneArray = ref([]);
	const rescheduleServiceModalVisible = ref(false);
	const rescheduleWeekDayModalVisible = ref(false);
	const rescheduleJobModalVisible = ref(false);
	const rescheduleTodaysJobData = ref([]);
	const reschedulePropertySelected = ref({});
	const showAddFeeDialog = ref(false);
	const showEditPropertiesDialog = ref(false);
	const doneMowingSingleYardDialog = ref(false);
	const selectedPropertyId = ref('');
	const editingFee = ref({});
	const dashboardAnalytics = ref({});
	const UnpaidInvoices = ref([]);
	const unInvoicedClients = ref([]);
	const selectedPropertyDetails = ref({});
	const selectedJobDetails = ref({});
	const showEditJobDialog = ref(false);
	const showAddChargesDialog = ref(false);
	const editingCharges = ref({});
	const route = useRoute();
	// Define store computed properties
	const getDashboardAnalytics = computed(() => dashboardAnalytics.value);

	const getDaysJobs = computed(() => {
		if (clickedDay.value !== '') {
			const filteredArray = sevenDayArray.value.filter((day) => day.Day === clickedDay.value);
			if (filteredArray.length > 0) {
				return filteredArray[0].Jobs;
			}
		}

		return undefined;
	});

	const getSevenDayArray = computed(() => sevenDayArray.value);

	const getAdditionalFees = computed(() => fees.value);

	const getProperty = computed(() =>
		sevenDayArray.value.find((property) => property.propertyId === selectedPropertyId.value),
	);

	const getPropertyFees = computed(() => {
		const feesFound = fees.value.filter((fee) => {
			if (fee.propertyId === selectedPropertyId.value) {
				return fee;
			}
		});
		return feesFound;
	});

	// ... (remaining code)

	// Define store actions
	const fetchPropertyDetails = async () => {
		try {
			const { id } = route.params;
			const token = localStorage.getItem('token');
			const response = await axios.get(`${API_URL}/Property/Read?propertyId=${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			selectedPropertyDetails.value = response.data;
		} catch (error) {
			console.error(error);
			// Handle the error, e.g., show an error message to the user
		}
	};

	const updateProperty = async (payload) => {
		try {
			const token = localStorage.getItem('token');
			const response = await axios.put(`${API_URL}/Property/Update`, payload, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.status === 200) {
				selectedPropertyDetails.value = response.data;
				return response;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const addProperty = async (payload) => {
		try {
			const token = localStorage.getItem('token');
			const response = await axios.post(`${API_URL}/Property/Create`, payload, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.status === 200) {
				selectedPropertyDetails.value = response.data;
				return response;
			} else {
				throw new Error(`Failed to add property: ${response.statusText}`);
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const fetchPropertyFees = (propertyId) => {
		const feesFound = fees.value.filter((fee) => {
			if (fee.propertyId === propertyId) {
				return fee;
			}
		});
		return feesFound;
	};

	const addService = (service, propertyId) => {
		const jobs = getDaysJobs.value;
		const job = jobs.find((job) => job.PropertyId === propertyId);
		job.Services.push(service);
	};

	const addFees = (payload, editState = false, feeIndex) => {
		if (!editState) {
			fees.value.push(payload);
		} else {
			fees.value[feeIndex].id = payload.id;
			fees.value[feeIndex].amount = payload.amount;
			fees.value[feeIndex].title = payload.title;
			fees.value[feeIndex].description = payload.description;
		}

		return fees.value;
	};

	const removeFee = (toBeRemovedFee) => {
		const updatedFee = fees.value.filter((fee) => fee.id !== toBeRemovedFee.id);
		fees.value = [...updatedFee];
		return fees.value;
	};

	const fetchJobDetail = async () => {
		try {
			const { id } = route.params;
			const token = localStorage.getItem('token');
			const response = await axios.get(`${API_URL}/Property/Services?jobId=${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			selectedJobDetails.value = response.data.jobsInfo;
		} catch (error) {
			console.error(error);
		}
	};

	const removeServiceFromSelectedJob = (servicetoBeRemoved) => {
		const updatedServices = selectedJobDetails.value.services.filter((service) => {
			return servicetoBeRemoved.propertyServicesId != service.propertyServicesId;
		});
		selectedJobDetails.value.services = [...updatedServices];
		return selectedJobDetails.value.services;
	};

	const updateJob = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await axios.put(`${API_URL}/Job/Update`, selectedJobDetails.value, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			selectedJobDetails.value = response.data.jobsInfo;
			return true;
		} catch (error) {
			fetchJobDetail();
			console.error(error);
			return false;
		}
	};
	const updateMowRateOfSelectedJob = (mowRate) => {
		selectedJobDetails.value.mowingRate = mowRate;
	};

	const fetchSevenDaysSchedule = async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await axios.get(`${API_URL}/Schedule/NextSevenDays`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			sevenDayArray.value = response.data;
		} catch (error) {
			console.error(error);
			// Handle the error, e.g., show an error message to the user
		}
	};

	const rescheduleWholeDay = async (payload) => {
		try {
			const token = localStorage.getItem('token');
			const response = await axios.post(`${API_URL}/Schedule/RescheduleWholeDay`, payload, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			sevenDayArray.value = response.data;
		} catch (error) {
			console.error(error);
			// Handle the error, e.g., show an error message to the user
		}
	};

	const setDoneMowing = async (mowingDetails) => {
		try {
			const token = localStorage.getItem('token');
			const response = await axios.post(`${API_URL}/Schedule/DoneMowing`, mowingDetails, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.status === 200 && response.data.length) {
				sevenDayArray.value = response.data;
			}
		} catch (error) {
			console.error(error);
			if (error.response && (error.response.status === 400 || error.response.status === 500)) {
				// Handle specific error cases, e.g., alert the user
				alert('Contact us for help.');
			}
		}
	};

	const setMarkAllAsDone = async (mowingDetails) => {
		try {
			const token = localStorage.getItem('token');
			const response = await axios.post(`${API_URL}/Schedule/AllDone`, mowingDetails, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.status === 200 && response.data.length) {
				sevenDayArray.value = response.data;
			}
		} catch (error) {
			console.error(error);
			if (error.response && (error.response.status === 400 || error.code === 'ERR_NETWORK')) {
				// Handle specific error cases, e.g., alert the user
				alert('Contact us for help.');
			}
		}
	};

	const dashboardData = async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await axios.get(`${API_URL}/Dashboard/GetDashboardData`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.status === 200 && response.data.isChargesEnabled === false) {
				const verificationUrl = response.data.verificationResponse.url;
				window.location.href = verificationUrl;
				return;
			}
			if (response.status === 200) {
				dashboardAnalytics.value.monthUnPaidInvoices = {
					amount: response.data.totalDollarAmountOfUnPaidJobs,
					openInvoices: response.data.listOfClientsWithBalance.reduce((acc, clients) => {
						acc = clients.amountOfOpenInvoices + acc;
						return acc;
					}, 0),
					clients: response.data.listOfClientsWithBalance.length,
				};

				dashboardAnalytics.value.monthPaidInvoices = {
					amount: response.data.totalDollarAmountOfPaidInvoices,
					openInvoices: response.data.amountOfPaidInvoices,
					clients: response.data.amountOfJobsCompleted,
				};

				dashboardAnalytics.value.monthUnInvoicedJobs = {
					amount: response.data.totalDollarAmountOfUnInvoicedJobs,
					openInvoices: response.data.listOfUnInvoicedJobs.length,
					clients: response.data.listOfClientsWithBalance.length,
				};

				dashboardAnalytics.value.monthRevenue = {
					amount: response.data.totalRevenueAmount,
					yards: response.data.amountOfJobsCompleted,
					extraJobs: response.data.amountOfExtraServicesAdded,
				};
				UnpaidInvoices.value = response.data.listOfClientsWithBalance;
				unInvoicedClients.value = response.data.listOfClientsWithUnInvoicedJobs;
			}
		} catch (error) {
			console.error(error);
			if (error.response && (error.response.status === 400 || error.code === 'ERR_NETWORK')) {
				// Handle specific error cases, e.g., alert the user
				alert('Contact us for help.');
			}
		}
	};

	// ... (previous code)

	// Export store properties, getters, and actions
	return {
		// State properties
		sevenDayArray,
		clickedDay,
		fees,
		allJobsDoneDialog,
		allJobsDoneArray,
		rescheduleServiceModalVisible,
		rescheduleWeekDayModalVisible,
		rescheduleJobModalVisible,
		rescheduleTodaysJobData,
		reschedulePropertySelected,
		showAddFeeDialog,
		doneMowingSingleYardDialog,
		selectedPropertyId,
		editingFee,
		dashboardAnalytics,
		UnpaidInvoices,
		selectedPropertyDetails,
		showEditPropertiesDialog,
		unInvoicedClients,
		selectedJobDetails,
		showEditJobDialog,
		editingCharges,

		// Computed properties
		getDashboardAnalytics,
		getDaysJobs,
		getSevenDayArray,
		getAdditionalFees,
		getProperty,
		getPropertyFees,

		// Actions
		fetchPropertyDetails,
		fetchPropertyFees,
		addService,
		addFees,
		removeFee,
		fetchSevenDaysSchedule,
		rescheduleWholeDay,
		setDoneMowing,
		setMarkAllAsDone,
		dashboardData,
		addProperty,
		updateProperty,
		fetchJobDetail,
		removeServiceFromSelectedJob,
		showAddChargesDialog,
		updateJob,
		updateMowRateOfSelectedJob,
	};
});
