<template>
	<v-dialog
		v-model="showEditJobDialog"
		class="job-dialog text-center"
		fullscreen
	>
		<v-card>
			<v-card-title
				class="card-title mt-5 mb-3 font-weight-bold"
				data-testid="card-title"
			>
				Edit Job Details
			</v-card-title>
			<v-card-text class="text-center pt-0">
				<h3>{{ selectedJobDetails.propertyOwnerName }}</h3>
				<h3>{{ selectedJobDetails.addressLineOne }}</h3>
				<h3>{{ selectedJobDetails.city }}</h3>
				<div class="mowing-details">
					<div class="mowing-data">
						<label class="label">Mowing Rate</label>
						<div
							v-if="!isEditingMowingRate"
							class="data"
							data-testid="mowing-rate-text"
						>
							<div class="amount">${{ selectedJobDetails.mowingRate.toFixed(2) }}</div>
							<div
								class="edit-icon"
								data-testid="edit-mow-btn"
								@click="editMowingRate"
							></div>
						</div>
						<div
							v-else
							class="d-flex flex-column data"
							data-testid="mowing-rate-edit-box"
						>
							<div class="mow-edit-input-box d-flex justify-center">
								<div class="d-flex justify-center">
									<p class="d-flex align-center h-100">$</p>
									<v-text-field
										v-model="updatedMowingRate"
										variant="plain"
										class="mowingrate-input"
										placeholder=""
										density="compact"
										:error-messages="mowingRateErr"
										@blur="validateMowingRate()"
										@input="validateMowingRate()"
									></v-text-field>
								</div>
							</div>

							<v-btn
								size="medium"
								class="px-5 py-2 mb-2 mt-3 rounded-pill update-btn"
								@click="updateMowingRate()"
							>
								<span>Update</span>
							</v-btn>
						</div>
					</div>
					<div class="charges">
						<div class="charges__header">
							<h2 class="charges__main-heading text-left">Additional Fees &amp; Services</h2>
						</div>
						<v-btn
							v-if="selectedJobDetails.services.length == 0"
							size="small"
							class="pl-1 mb-2 mt-3 rounded-pill d-flex flex-column add-service-button"
							data-testid="add-fees-btn"
							@click="showAddChargesDialog = true"
							><v-icon
								class="mr-1"
								size="small"
								icon="add_circle"
							/>
							<span>Add Fees</span>
						</v-btn>
						<div class="charges__details">
							<div class="charges__info d-flex flex-column gap">
								<div
									v-for="service in selectedJobDetails.services"
									:key="service.propertyServicesId"
									class="charges__data d-flex align-center justify-space-between"
									@click="
										editingCharges = service;
										showAddChargesDialog = true;
									"
								>
									<div class="d-flex align-center data flex-grow-1 text-left text-h6 font-weight-regular">
										<div
											v-if="
												service.propertyServicesId ==
												selectedJobDetails.services[selectedJobDetails.services.length - 1].propertyServicesId
											"
											class="icon plus-icon"
											@click="showAddChargesDialog = true"
										></div>
										<p>
											{{ service.serviceDetails }}
										</p>
										<div
											class="mx-2 icon remove-icon"
											@click.stop="() => scheduleStore.removeServiceFromSelectedJob(service)"
										></div>
									</div>
									<div class="font-weight-bold">${{ service.amount?.toFixed(2) }}</div>
								</div>
							</div>
							<div class="total">
								<div class="text">Total Extra Fees</div>
								<div class="total__amount">${{ totalAdditionalServiceCharges.toFixed(2) }}</div>
							</div>
						</div>

						<div class="jobtotal">
							<h3 class="jobtotal__heading">Job Totals</h3>
							<div class="jobtotal__content">
								<div class="jobtotal__info">
									<div class="data">
										<p class="text">Mowing Rate</p>
										<div class="bill__amount">${{ selectedJobDetails.mowingRate.toFixed(2) }}</div>
									</div>

									<div class="data">
										<p class="text">Total Extra Fees</p>
										<div class="bill__amount">${{ totalAdditionalServiceCharges.toFixed(2) }}</div>
									</div>
								</div>

								<div class="total">
									<div class="text">Total Extra Fees</div>
									<div class="total__amount">
										${{ (selectedJobDetails.mowingRate + totalAdditionalServiceCharges).toFixed(2) }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</v-card-text>
			<v-card-actions class="flex-column justify-center">
				<v-btn
					class="mb-7 rounded-pill confirm-btn text text-capitalize"
					variant="flat"
					width="80%"
					height="48"
					@click="updateJob()"
				>
					<span>Update</span>
				</v-btn>

				<v-btn
					class="mb-7 rounded-pill close-btn text text-capitalize"
					variant="flat"
					width="80%"
					height="48"
					@click="showEditJobDialog = false"
				>
					<span>Close Dialog</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
	<v-dialog
		v-model="showMessage"
		activator="child"
		transition="dialog-bottom-transition"
	>
		<v-card class="message-Card py-8 px-4">
			<h2 class="message-Card-Title mb-2">{{ errorMessage === '' ? 'Success' : 'Error' }}</h2>
			<p class="message-Card-text mb-4">
				{{ errorMessage === '' ? `Job Details Updated Successfully` : errorMessage }}
			</p>
			<v-btn
				class="message-Card-Close"
				@click="showMessage = false"
			>
				<span class="btn-text px-4 py-12">OK</span>
			</v-btn>
		</v-card>
	</v-dialog>
</template>
<script setup>
import { useScheduleStore } from '@/stores/schedule';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
const scheduleStore = useScheduleStore();
const { showEditJobDialog, selectedJobDetails, fetchJobDetail, showAddChargesDialog, editingCharges } =
	storeToRefs(scheduleStore);
const showMessage = ref(false);
const errorMessage = ref('');
const isEditingMowingRate = ref(false);
const updatedMowingRate = ref();
const mowingRateErr = ref('');

const totalAdditionalServiceCharges = computed(() => {
	let charges = 0;
	selectedJobDetails.value.services.forEach((service) => {
		charges += service.amount;
	});
	return charges;
});

const updateJob = () => {
	errorMessage.value = '';
	const updated = scheduleStore.updateJob();
	if (!updated) {
		errorMessage.value = 'Unable to update job details. Please try again later.';
	}
	showMessage.value = true;
	showEditJobDialog.value = false;
};
const validateMowingRate = () => {
	if (isNaN(updatedMowingRate.value) || updatedMowingRate.value <= 0 || updatedMowingRate.value > 10000) {
		mowingRateErr.value = 'Invalid Input';
		return;
	} else {
		mowingRateErr.value = '';
	}
};
const updateMowingRate = () => {
	if (mowingRateErr.value === '') {
		isEditingMowingRate.value = false;
		scheduleStore.updateMowRateOfSelectedJob(Number(updatedMowingRate.value));
	}
};
const editMowingRate = () => {
	isEditingMowingRate.value = true;
	updatedMowingRate.value = selectedJobDetails.value.mowingRate.toFixed(2).toString();
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
.job-dialog {
	font-family: 'Raleway', sans-serif;
	.v-card {
		border-radius: 20px;
	}
	h3 {
		font-weight: 400;
		font-size: 18px;
		margin-bottom: 0;
		line-height: 1.2;
	}
	.mowing-details {
		margin: 2rem 0 1rem;
		color: $SP400;
		padding: 0 24px;

		.mowing-data {
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;
			.label {
				font-size: 18px;
				font-weight: bold;
				text-align: left;
			}

			.data {
				display: flex;
				text-align: right;
				.mow-edit-input-box {
					background-color: $SP600;
					border-bottom: 2px solid $SP400;
				}
				.mow-edit-input-box > div {
					min-width: 60%;
					max-width: 80%;
				}
			}
			p {
				color: $SP400;
			}
			.mowingrate-input {
				height: 2.4rem;
			}

			.update-btn {
				color: $SP1100;
				display: flex;
				background-color: $SP800;
			}
		}

		.edit-icon {
			display: block;
			width: 20px;
			height: 20px;
			margin-left: 10px;
			background-image: $edit;
			background-repeat: no-repeat;
			background-position: center;
			cursor: pointer;
			filter: invert(34%) sepia(48%) saturate(2526%) hue-rotate(6deg) brightness(96%) contrast(91%);
		}

		.charges {
			&__header {
				display: flex;
				text-align: left;
				justify-content: space-between;
			}

			.add-service-button {
				color: $SP1100;
				background-color: $SP800;
			}

			&__details {
				margin: 1rem 0;

				.total {
					display: flex;
					justify-content: space-between;
					font-size: 20px;
					font-weight: bold;
					color: $SP800;
					margin: 5px 0;
				}
			}

			&__info {
				border-bottom: 2px solid;
				gap: 10px;
			}

			&__data {
				.data {
					max-width: 70%;
				}

				.bill__amount {
					font-weight: bold;
				}
				.icon {
					display: block;
					width: 20px;
					height: 20px;
					margin-right: 10px;
					background-repeat: no-repeat;
					background-position: center;
					cursor: pointer;
				}
				.plus-icon {
					margin: 0 5px 0 -24px;
					background-image: $circle-plus;
					filter: brightness(0) saturate(100%) invert(42%) sepia(6%) saturate(929%) hue-rotate(134deg) brightness(98%)
						contrast(90%);
				}
				.remove-icon {
					background-image: $circle-minus;
					filter: invert(34%) sepia(48%) saturate(2526%) hue-rotate(6deg) brightness(96%) contrast(91%);
				}
			}

			&__data:last-child {
				margin-bottom: 1rem;
			}
		}

		.jobtotal {
			margin-top: 2rem;

			&__heading {
				font-size: 28px;
				margin-bottom: 10px;
			}

			&__content {
				.total {
					display: flex;
					justify-content: space-between;
					font-size: 20px;
					font-weight: bold;
					color: $SP800;
					margin: 5px 0;
				}
			}

			&__info {
				border-bottom: 2px solid;

				.data {
					display: flex;
					justify-content: space-between;
					font-size: 20px;
				}

				.data:last-child {
					margin-bottom: 1rem;
				}
			}
		}
	}
	.confirm-btn,
	.close-btn {
		color: $SP1600;
		font-weight: bold;
		font-size: 16px;
		text-transform: capitalize;
	}
	.confirm-btn {
		background-color: $SP500;
	}
	.close-btn {
		background-color: $SP400;
	}
}
</style>
