<template>
	<div class="contact-picker d-flex flex-column justify-center align-center">
		<div class="select-client-options d-flex justify-center align-center">
			<span :class="{ 'color-orange': !toggleSwitch }">Send</span>
			<span class="toggle-button mx-3">
				<v-switch
					v-model="manualSelection"
					inset
					@change="selectOption"
				></v-switch>
			</span>
			<span :class="{ 'color-orange': toggleSwitch }">Manual</span>
		</div>
		<v-btn
			v-if="hideContactPicker"
			class="rounded-pill select-client-btn mb-9 mt-1 py-2 text-capitalize"
			size="large"
			@click="selectContact"
			>Select Contacts</v-btn
		>
	</div>
</template>
<script setup>
import { ref, computed } from 'vue';
import { useInviteClientStore } from '@/stores/invite-client-store';
const inviteClientStore = useInviteClientStore();
const emit = defineEmits(['change-selection']);
const manualSelection = ref(false);
const selectContact = async () => {
	const props = ['name', 'email', 'tel', 'address', 'icon'];
	const opts = { multiple: false };
	try {
		const contacts = await navigator.contacts.select(props, opts);
		inviteClientStore.setState(contacts[0]);
	} catch (ex) {
		console.error('catch', ex);
	}
};

const hideContactPicker = computed(() => 'contacts' in navigator && 'ContactsManager' in window);
const toggleSwitch = computed(() => manualSelection.value);
const selectOption = () => {
	emit('change-selection', manualSelection.value);
};
</script>

<style lang="scss">
@import 'styles';

.contact-picker {
	.select-client-options {
		font-family: 'Raleway', sans-serif;
		font-weight: bold;

		.toggle-button {
			height: 55px;
		}

		.v-selection-control {
			.v-switch__track {
				border: 2px solid $SP400;
				background-color: $SP600;
			}

			.v-switch__thumb {
				border: 2px solid $SP400;
			}
		}
	}

	.select-client-btn {
		background-color: $SP1200 !important;
		color: $SP1600 !important;
	}
}

.color-orange {
	color: $SP500;
}
</style>
