<template>
	<v-dialog
		v-model="showAddChargesDialog"
		class="add-charges-modal text-center"
	>
		<v-card>
			<v-card-title
				class="card-title mt-5 mb-3"
				data-testid="card-title"
			>
				Add Service
			</v-card-title>
			<v-card-text>
				<h3>Service Name</h3>
				<v-text-field
					v-model="serviceDetails"
					variant="plain"
					class="rounded-xl code__input input"
					clearable
					data-testid="additionalFeeDescription"
					placeholder="Tree Trimming"
					:error-messages="error.title"
					@blur="validateAmount('title')"
					@input="validateAmount('title')"
				></v-text-field>
				<h3 class="mt-5">Amount</h3>
				<v-text-field
					v-model="serviceAmount"
					variant="plain"
					class="code__input rounded-xl input"
					type="number"
					clearable
					placeholder="50.00"
					data-testid="serviceAmount"
					:error-messages="error.amount"
					@blur="validateAmount('amount')"
					@input="validateAmount('amount')"
				></v-text-field>

				<h3 class="mt-5">Description</h3>
				<v-textarea
					v-model="serviceNotes"
					variant="plain"
					class="rounded-xl input notes"
					clearable
					placeholder="The tree was really big."
				></v-textarea>
			</v-card-text>

			<v-card-actions
				class="d-flex flex-column justify-center"
				data-testid="v-card-actions"
			>
				<v-btn
					class="mb-7 rounded-pill submit_button submit-button text"
					variant="flat"
					width="80%"
					height="48"
					data-testid="submit-button"
					@click="addService"
				>
					<span>Submit</span>
				</v-btn>
				<v-btn
					class="mb-7 rounded-pill schedule_button close-button text"
					variant="flat"
					width="80%"
					height="48"
					data-testid="close-dialog-button"
					@click="showAddChargesDialog = false"
				>
					<span>Close Dialog</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script setup>
import { useScheduleStore } from '@/stores/schedule';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
const scheduleStore = useScheduleStore();
const { showAddChargesDialog, selectedJobDetails, editingCharges } = storeToRefs(scheduleStore);
import { useRoute } from 'vue-router';
import { v4 as uuidv4 } from 'uuid';
const route = useRoute();

const serviceAmount = ref(null);
const serviceDetails = ref('');
const serviceNotes = ref('');
const error = ref({});
const selectedServiceId = ref(null);

const addService = () => {
	if (serviceDetails.value === '' || isNaN(serviceAmount.value) || serviceAmount.value <= 0) {
		return;
	}

	const payload = {
		propertyServicesId: selectedServiceId.value ? selectedServiceId.value : uuidv4(),
		serviceDetails: serviceDetails.value,
		serviceNotes: serviceNotes.value,
		amount: parseInt(serviceAmount.value),
		propertyId: selectedJobDetails.value.propertyId,
		jobId: route.params.id,
	};
	if (selectedServiceId.value) {
		selectedJobDetails.value.services = selectedJobDetails.value.services.map((service) => {
			if (service.propertyServicesId === selectedServiceId.value) {
				return {
					...service,
					amount: Number(serviceAmount.value),
					serviceDetails: serviceDetails.value,
					serviceNotes: serviceNotes.value,
				};
			}
			return service;
		});
	} else {
		selectedJobDetails.value.services.push(payload);
	}
	resetForm();
	showAddChargesDialog.value = false;
};

const resetForm = () => {
	selectedServiceId.value = null;
	serviceAmount.value = null;
	serviceDetails.value = '';
	serviceNotes.value = '';
	error.value = {};
	editingCharges.value = {};
};
const validateAmount = (toBeValidated) => {
	if (toBeValidated === 'amount') {
		error.value.amount = isNaN(serviceAmount.value) || serviceAmount.value <= 0 ? 'Please enter a valid amount' : '';
	}
	if (toBeValidated === 'title') {
		error.value.title = serviceDetails.value === '' ? 'Service title cannot be empty' : '';
	}
};
watch(showAddChargesDialog, () => {
	if (!showAddChargesDialog.value) {
		resetForm();
	} else {
		selectedServiceId.value = editingCharges.value.propertyServicesId;
		serviceAmount.value = editingCharges.value.amount;
		serviceDetails.value = editingCharges.value.serviceDetails;
		serviceNotes.value = editingCharges.value.serviceNotes;
	}
});
</script>
<style lang="scss">
@import '../../styles.scss';

.add-charges-modal {
	.v-card .v-card-title {
		font-size: 28px;
		font-family: 'Raleway', sans-serif;
	}
	.v-card,
	.v-card {
		border-radius: 20px;
	}
	.v-messages__message {
		text-align: left;
		margin-top: 2px;
	}
	h3 {
		text-align: left;
		font-weight: 450;
		font-size: 1.3rem;
		margin-bottom: 0;
		margin-left: 6px;
	}
	.code__input {
		border: 2px solid $SP600;
		background-color: transparent;
		padding: 0 20px;
	}
	input,
	textarea {
		font-size: 1.5rem;
		padding-top: 0.8rem;
	}
	.notes {
		border: 2px solid $SP600;
		//background-color: $SP600;
		padding: 0 20px;
	}
	.submit_button,
	.close-button {
		color: $SP1600;
		font-weight: bold;
		font-size: 16px;
		text-transform: capitalize;
	}
	.submit_button {
		background-color: $SP500;
	}
	.close-button {
		background-color: $SP400;
	}
}
</style>
