<!-- eslint-disable max-len -->
<template lang="">
	<div>
		<svg
			:width="width"
			:height="height"
			:viewBox="viewBox"
		>
			<path
				:fill="fill"
				d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"
			/>
		</svg>
	</div>
</template>

<script>
export default {
	name: 'VerticalDotsIcon',
	props: {
		width: {
			type: String,
			default: '24',
		},
		height: {
			type: String,
			default: '24',
		},
		fill: {
			type: String,
			default: 'currentColor',
		},
		viewBox: {
			type: String,
			default: '0 0 24 24',
		},
	},
};
</script>
