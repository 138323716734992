<template>
	<v-container>
		<div class="main">
			<v-row>
				<v-col col="12">
					<div class="re-start-sec">
						<div>
							<p class="re-start-header">Re-Start your screen by tapping the green play button below</p>
						</div>
						<div class="re-start-img-cont">
							<v-img
								src="../assets/Images/4028535.png"
								alt=""
								height="230"
								class="re-start-img"
								@click="mowingTime()"
							/>
						</div>
						<div class="steps-con">
							<h3>1 of 3 steps to re-start.</h3>
						</div>
					</div>

					<div class="warning-sec">
						<h1 class="warning-header">WARNING</h1>
						<p>WHEN YOU RE-START THE SEASON THE FOLLOW ACTIONS WILL HAPPEN</p>
						<div class="steps">
							<ol>
								<li>ALL OF YOUR USERS WILL BE EMAILED STATING THAT YOU HAVE START MOWING.</li>
								<li>ALL OF YOUR SCHEDULES WILL BE SET AND READY FOR YOU TO SERVICE AND CHARGE YOUR CUSTOMERS</li>
							</ol>
						</div>
					</div>
				</v-col>
			</v-row>
		</div>
		<v-row class="justify-center">
			<v-dialog
				v-model="submitSuccess"
				activator="child"
				transition="dialog-bottom-transition"
			>
				<v-row class="justify-center">
					<v-dialog
						v-model="submitSuccess"
						activator="child"
						transition="dialog-bottom-transition"
						@close="submitSuccess2 = true"
					>
						<v-card class="success-Card">
							<h2 class="success-Card-Title">
								Re-start Mowing <br />
								Step 2 of 3
							</h2>
							<span class="success-Card-subTitle">what week will you start mowing this season?</span>

							<v-btn
								class="success-Card-Close-BTN"
								@click="submitSuccess = false"
							>
								<span
									>Next
									<!-- <i class="fa-solid fa-chevrons-right"></i> -->
								</span>
							</v-btn>
						</v-card>
					</v-dialog>
				</v-row>
			</v-dialog>
		</v-row>
		<v-row class="justify-center">
			<v-dialog
				v-model="submitSuccess"
				activator="child"
				transition="dialog-bottom-transition"
				@close="submitSuccess2 = true"
			>
				<v-card class="success-Card">
					<h2 class="success-Card-Title">
						Re-start Mowing <br />
						Step 2 of 3
					</h2>
					<span class="success-Card-subTitle">what week will you start mowing this season?</span>
					<span class="success-Card-subTitle">Select Week</span>

					<input
						id="birthday"
						type="date"
						name="birthday"
						class="dateInput"
					/>

					<v-btn
						class="mb-7 rounded-pill schedule__button next-btn"
						variant="flat"
						width="232"
						height="48"
						@click="
							submitSuccess = false;
							submitSuccess2 = true;
						"
					>
						Next
					</v-btn>
				</v-card>
			</v-dialog>
		</v-row>
		<v-row class="justify-center">
			<v-dialog
				v-model="submitSuccess2"
				activator="child"
				transition="dialog-bottom-transition"
			>
				<v-card class="success-Card">
					<h2 class="success-Card-Title">
						Re-start Mowing <br />
						Step 3 of 3
					</h2>
					<span class="success-Card-subTitle"
						>Tap the numbers in their sequential order to confirm that you want to Re-start your mowing season the week
						3/24/24.</span
					>
					<div class="buttons">
						<div
							class="circle"
							:class="{ active: circle1 }"
							@click="toggleCircle(1)"
						>
							1
						</div>
						<div
							class="circle"
							:class="{ active: circle2 }"
							@click="toggleCircle(2)"
						>
							2
						</div>
						<div
							class="circle"
							:class="{ active: circle3 }"
							@click="toggleCircle(3)"
						>
							3
						</div>
						<div
							class="circle"
							:class="{ active: circle4 }"
							@click="toggleCircle(4)"
						>
							4
						</div>
					</div>
				</v-card>
			</v-dialog>
		</v-row>
	</v-container>
</template>

<script>
// Import { VMenu, VTextField, VDatePicker } from 'vuetify/lib';
export default {
	name: 'AddNewClient',
	data() {
		return {
			submitSuccess2: false,
			submitSuccess: false,
			circle1: false,
			circle2: false,
			circle3: false,
			circle4: false,
		};
	},
	methods: {
		mowingTime() {
			return (this.submitSuccess = true);
		},
		mowingTime2() {
			this.submitSuccess2 = true;
			this.submitSuccess = false;
		},
		toggleCircle(circleNum) {
			if (circleNum === 1) {
				this.circle1 = !this.circle1;
			} else if (circleNum === 2) {
				this.circle2 = !this.circle2;
			} else if (circleNum === 3) {
				this.circle3 = !this.circle3;
			} else if (circleNum === 4) {
				this.circle4 = !this.circle4;
			}
		},
	},
};
</script>

<style lang="scss">
@import '../../styles.scss';

.main {
	font-family: 'Raleway', sans-serif;
	display: flex;
	justify-content: center;
	align-items: center;

	.re-start-sec {
		margin: 40px;

		.re-start-header {
			margin: 10px 20px;
			text-align: center;
		}

		.re-start-img-cont {
			display: flex;

			.re-start-img {
				:hover {
					cursor: pointer !important;
				}
			}
		}
	}

	.warning-sec {
		margin: 0 5px;

		h1 {
			color: $SP800;
		}

		h1,
		p {
			text-align: center;
			margin: 30px 0;
		}

		li {
			margin: 30px 20px;
		}

		.steps {
			ol {
				margin-bottom: 5rem;
			}
		}
	}
}

.buttons {
	display: flex;
	margin-bottom: 30px;
	justify-content: space-around;
	align-items: center;
}

input[type='date'] {
	background-color: $SP600;
	border-radius: 50px;
	padding: 15px;
	width: 300px;
	margin-bottom: 20px;
}

.buttons .circle {
	min-width: 37px;
	display: flex;
	border: 1px solid black;
	border-radius: 50%;
	background: $SP400;
	justify-content: center;
	align-items: center;
	font-size: 25px;
	font-weight: 500;
	color: $SP1100;
	margin-right: 19px;
}

.buttons .active {
	background-color: $SP800 !important;
}

.dropdown {
	margin-bottom: 30px;
	width: 400px;
	border-radius: 30px;
	overflow: hidden;
	border: 0;
}

.success-Card {
	border-radius: 50px !important;
	padding: 70px 42px 55px 45px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.success-Card-Title {
		margin-bottom: 12px;
		text-align: center;
		font: normal normal 600 27px/31px Raleway;
		letter-spacing: 0px;
		color: $SP400;
		opacity: 1;
	}

	.success-Card-subTitle {
		margin-bottom: 10px !important;
		text-align: center;
		font: normal normal normal 21px/38px Raleway;
		letter-spacing: 0px;
		color: $SP400;
		opacity: 1;
	}

	.success-Card-Close-BTN {
		width: 229px;
		height: 55px;
		background: $SP800 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px $SP1700;
		border-radius: 28px;

		opacity:unset span {
			text-align: center;
			font: normal normal bold 23px/27px Raleway;
			letter-spacing: 0px;
			color: $SP1100;
			opacity: 1;
		}
	}
}

.dateInput {
	width: 100% !important;
}
.next-btn {
	background-color: $SP800;
}
</style>
