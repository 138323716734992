<template>
	<v-dialog
		v-model="showAddFeeDialog"
		class="fee-modal text-center"
	>
		<v-card>
			<v-card-title
				class="card-title mt-5 mb-3"
				data-testid="card-title"
			>
				Add Service Fee
			</v-card-title>
			<v-card-text>
				<h3>Service Description</h3>
				<v-text-field
					v-model="additionalFeeTitle"
					variant="plain"
					class="code__input rounded-pill input"
					clearable
					data-testid="additionalFeeTitle"
				></v-text-field>
				<h3>Amount</h3>
				<v-text-field
					v-model="additionalFeeAmount"
					variant="plain"
					class="code__input rounded-pill input"
					type="number"
					clearable
					data-testid="additionalFeeAmount"
				></v-text-field>

				<h3>Service Notes</h3>
				<v-textarea
					v-model="additionalFeeDescription"
					variant="plain"
					class="rounded-xl input notes"
					clearable
					data-testid="additionalFeeDescription"
				></v-textarea>
			</v-card-text>

			<v-card-actions
				class="d-flex flex-column justify-center"
				data-testid="v-card-actions"
			>
				<v-btn
					class="mb-7 rounded-pill submit_button submit-button text"
					variant="flat"
					width="80%"
					height="48"
					data-testid="submit-button"
					@click="submitFee"
				>
					<span>Submit</span>
				</v-btn>
				<v-btn
					class="mb-7 rounded-pill schedule_button close-button text"
					variant="flat"
					width="80%"
					height="48"
					data-testid="close-dialog-button"
					@click="showAddFeeDialog = false"
				>
					<span>Close Dialog</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script setup>
import { useScheduleStore } from '@/stores/schedule';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { v4 as uuidv4 } from 'uuid';
const scheduleStore = useScheduleStore();
const { showAddFeeDialog, selectedPropertyId, fees } = storeToRefs(scheduleStore);

const props = defineProps({
	feeTitle: {
		type: String,
		default: '',
	},
	feeAmount: {
		type: Number,
		default: null,
	},
	feeDescription: {
		type: String,
		default: '',
	},
});
const additionalFeeTitle = ref(props.feeTitle);
const additionalFeeAmount = ref(props.feeAmount);
const additionalFeeDescription = ref(props.feeDescription);

const submitFee = () => {
	if (additionalFeeTitle.value === '' || additionalFeeAmount.value === '') {
		return;
	}

	const payload = {
		id: uuidv4(),
		propertyId: selectedPropertyId.value,
		title: additionalFeeTitle.value,
		amount: Number(additionalFeeAmount.value),
		description: additionalFeeDescription.value,
	};
	fees.value.push(payload);
	resetForm();
	showAddFeeDialog.value = false;
};

const resetForm = () => {
	additionalFeeAmount.value = '';
	additionalFeeTitle.value = '';
	additionalFeeDescription.value = '';
};

watch(showAddFeeDialog, () => {
	if (!showAddFeeDialog.value) {
		resetForm();
	}
});
</script>
<style lang="scss" scoped>
@import '../../styles.scss';

.fee-modal {
	.v-card,
	.v-card {
		border-radius: 20px;
	}
	h3 {
		text-align: center;
		font-weight: 400;
		font-size: 1.3rem;
		margin-bottom: 0.5rem;
	}
	.notes {
		background-color: $SP600;
		padding: 1rem 20px;
	}
	.submit_button,
	.close-button {
		color: $SP1600;
		font-weight: bold;
		font-size: 16px;
		text-transform: capitalize;
	}
	.submit_button {
		background-color: $SP500;
	}
	.close-button {
		background-color: $SP400;
	}
}
</style>
