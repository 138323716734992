<template>
	<div id="slider">
		<div class="container">
			<div
				ref="outer"
				class="outer"
				:class="{ unlocked: unlocked }"
			>
				<div
					ref="lock"
					class="lock"
					@mousedown="dragStart"
					@touchstart="dragStart"
				></div>
				<div class="text">Swipe to rechedule all jobs</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		resetSlider: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['swiped'],
	data() {
		return {
			unlocked: false,
			lockRect: null,
			dragProps: null,
		};
	},
	computed: {
		outerRect() {
			return this.$refs.outer.getBoundingClientRect();
		},
		isTouch() {
			return 'ontouchstart' in document.documentElement;
		},
	},
	watch: {
		resetSlider() {
			if (!this.resetSlider) {
				this.$refs.lock.style.display = 'block';
				document.removeEventListener('mousemove', this.dragLock, false);
				document.removeEventListener('touchmove', this.dragLock, false);
				this.unlocked = false;
			}
		},
	},
	methods: {
		dragStart(e) {
			if (this.unlocked) {
				return;
			}

			this.lockRect = this.$refs.lock.getBoundingClientRect();
			const x = this.getX(e);
			this.dragProps = {
				start: this.lockRect.left - this.outerRect.left,
				mouseStart: x,
				newX: 0,
			};
			this.$refs.lock.classList.add('dragging');
			document.addEventListener('mousemove', this.dragLock, false);
			document.addEventListener('touchmove', this.dragLock, false);
			document.addEventListener('mouseup', this.dragStop);
			document.addEventListener('touchend', this.dragStop);
		},
		dragStop(reset) {
			this.$refs.lock.classList.remove('dragging');
			if (reset !== false) {
				this.$refs.lock.style.left = '0px';
			}

			document.removeEventListener('mousemove', this.dragLock, false);
			document.removeEventListener('touchmove', this.dragLock, false);
			document.removeEventListener('mouseup', this.dragStop);
			document.removeEventListener('touchend', this.dragStop);
		},
		unlock() {
			this.dragStop(true);
			this.unlocked = true;
			this.$refs.lock.style.display = 'none';
			this.$emit('swiped');
		},
		dragLock(e) {
			e.preventDefault();
			const posX = this.getX(e);
			const mouseDiff = posX - this.dragProps.mouseStart;
			const maxX = this.outerRect.width - this.lockRect.width;
			let newX = this.dragProps.start + mouseDiff;
			newX = this.clamp(newX, 0, maxX);
			this.$refs.lock.style.left = newX + 'px';
			if (newX >= maxX) {
				this.unlock();
			}
		},
		clamp(value, min, max) {
			return Math.min(Math.max(value, min), max);
		},
		getX(event) {
			if (this.isTouch === true) {
				return event.touches[0].pageX;
			}

			return event.clientX;
		},
	},
};
</script>
<style lang="scss" scoped>
@import '../../styles.scss';

.container {
	width: 80%;
	text-align: center;
	margin: 0 auto;

	.outer {
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 100%;
		position: relative;
		user-select: none;
		background: transparent linear-gradient(270deg, $SP1900 0%, $SP2600 100%, $SP1100 100%, $SP1100 100%) 0 0 no-repeat
			padding-box;
		height: 55px;
		border-radius: 30px;
		.lock {
			position: absolute;
			top: -5px;
			display: block;
			left: -5px;
			content: '';
			width: 57px;
			height: 67px;
			background-color: $SP1100;
			opacity: 1;
			border: none;
			box-shadow: 0 3px 6px $SP1700;
			background-image: $angles-down;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 30px;
			transform: rotate(-90deg);
			border-radius: 30px;
			cursor: pointer;
			&.dragging {
				transition: none;
			}
		}
		.text {
			color: $SP1100;
			font-family: 'Raleway';
			font-weight: bold;
			font-size: 14px;
			letter-spacing: 0.5px;
		}
	}
}
</style>
