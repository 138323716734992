<template>
	<div
		data-testid="invoice-detail-container"
		class="pa-4 invoice-details"
	>
		<v-row
			align="center"
			justify="center"
		>
			<v-col
				cols="12"
				class="invoice-ctg-address text-h6 font-weight-black text-center"
				@click="router.push(`/propertyDetails/${invoice?.propertyId}`)"
				>{{ invoice?.address }}</v-col
			>
			<v-col
				cols="12"
				class="invoice-ctg-id"
			>
				<p class="text-subtitle-1 font-weight-black text-center font-italic">Invoice ID</p>
				<p class="text-caption font-weight-medium text-center font-italic">{{ invoice?.invoiceId }}</p>
			</v-col>
		</v-row>
		<v-row class="pa-8">
			<v-col
				cols="12"
				class="d-flex pa-2 justify-space-between"
			>
				<span class="invoice-ctg-labels font-weight-bold">Total Invoice: </span>
				<span class="invoice-ctg-value font-weight-medium">${{ invoice?.amount.toFixed(2) }}</span>
			</v-col>
			<v-col
				cols="12"
				class="d-flex pa-2 justify-space-between"
			>
				<span class="invoice-ctg-labels font-weight-bold">Number of Jobs: </span>
				<span class="invoice-ctg-value font-weight-medium">{{ invoice?.jobsId?.length }}</span>
			</v-col>
			<v-col
				cols="12"
				class="d-flex pa-2 justify-space-between"
			>
				<span class="invoice-ctg-labels font-weight-bold">Status: </span>
				<span
					class="invoice-ctg-value font-weight-medium"
					data-testid="invoice-status"
					>{{
						invoice?.paid
							? 'Paid'
							: invoice?.totalAmountPaid > invoice?.amount
								? `Over Paid $${-(invoice?.totalAmountPaid - invoice?.amount)}`
								: `Unpaid $${-(invoice?.totalAmountPaid - invoice?.amount)}`
					}}</span
				>
			</v-col>
			<v-col
				cols="12"
				class="d-flex pa-2 justify-space-between"
			>
				<span class="invoice-ctg-labels font-weight-bold">Date Invoiced: </span>
				<span class="invoice-ctg-value font-weight-medium">{{ parseDateString(dateInvoiced) }}</span>
			</v-col>
		</v-row>
		<v-row class="mt-4 justify-center">
			<v-col cols="10">
				<v-btn
					:color="colors.$SP800"
					rounded="pill"
					class="mb-2 text-white text-capitalize"
					block
					variant="flat"
					data-testid="mark-as-paid"
					size="large"
					:disabled="invoice?.paid || invoice?.totalAmountPaid - invoice?.amount >= 0"
					@click="markAsPaid"
					>Mark as Paid</v-btn
				>
				<v-btn
					:color="colors.$SP500"
					rounded="pill"
					class="mb-2 text-white text-capitalize"
					block
					variant="flat"
					data-testid="write-off"
					size="large"
					@click="writeOff"
					>Write Off</v-btn
				>
				<v-btn
					:color="colors.$SP700"
					rounded="pill"
					class="mb-2 text-white text-capitalize"
					block
					variant="flat"
					data-testid="resend"
					size="large"
					@click="resendPaymentReminder"
					>Resend Payment Reminder</v-btn
				>
			</v-col>
		</v-row>
		<div
			v-if="invoice?.jobs.length"
			class="invoices mt-9"
			data-testid="jobs-section"
		>
			<h2 class="invoices__title text-center">Jobs</h2>
			<div class="invoices__data">
				<v-list class="invoices__main">
					<v-list-item
						v-for="job in invoice?.jobs"
						:key="job.jobsId"
						class="invoices__item"
						data-test="invoices"
						@click="router.push(`/services/${job.jobsId}`)"
					>
						<!-- <router-link :to="`/services/${job.jobsId}`"> -->
						<div class="invoice-info">
							<div class="invoice-info__amount">
								Amount: <span class="value">${{ job.jobTotal.toFixed(2) }}</span>
							</div>
							<div class="invoice-info__service-date">
								Serviced Date:
								<span class="value">{{ parseDateString(job.date) }}</span>
							</div>
						</div>
						<!-- </router-link> -->
					</v-list-item>
				</v-list>
				<div class="invoices__footer jobs__footer"></div>
			</div>
		</div>
	</div>
	<v-dialog
		v-model="showMessage"
		activator="child"
		transition="dialog-bottom-transition"
	>
		<v-card class="message-Card py-8 px-4">
			<h2 class="message-Card-Title mb-2">{{ message === 'Invoice Paid Successfully' ? 'Success' : 'Error' }}</h2>
			<p class="message-Card-text mb-4">
				{{ message }}
			</p>
			<v-btn
				class="message-Card-Close"
				@click="showMessage = false"
			>
				<span class="btn-text px-4 py-12">OK</span>
			</v-btn>
		</v-card>
	</v-dialog>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useInvoicesStore } from '@/stores/invoices-store';
import { useRoute, useRouter } from 'vue-router';
import { colors } from '../../colors';
import axios from 'axios';
import { parseDateString } from '@/composables/text-parser.composables';
const router = useRouter();
const showMessage = ref(false);
const message = ref('');
const route = useRoute();
const invoiceStore = useInvoicesStore();
const invoice = computed(() => invoiceStore?.getInvoiceById(route.params.id));
const dateInvoiced = computed(() => invoice?.value?.dateInvoiced);

onMounted(async () => {
	if (invoiceStore.invoices.length === 0) {
		await invoiceStore.fetchLCPInvoices();
	}
});
const markAsPaid = async () => {
	const token = localStorage.getItem('token');
	const payload = {
		InvoiceId: route.params.id,
		PaymentType: '1',
	};

	const API_URL = process.env.VUE_APP_API_URL;
	await axios
		.post(`${API_URL}/Invoices/Pay`, payload, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		.then(() => {
			message.value = 'Invoice Paid Successfully';
			invoiceStore.fetchLCPInvoices();
		})
		.catch((error) => {
			message.value = 'Failed to mark the invoice as paid. Please try again.';
			console.error('Payment error:', error);
		});
	showMessage.value = true;
};

function writeOff() {
	// Logic for writing off the invoice
}

function resendPaymentReminder() {
	// Logic for resending the payment reminder
}
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
.invoice-details {
	padding: 20px;
	font-family: Raleway, sans-serif;
}

.invoice-info,
.invoice-amounts {
	margin-bottom: 20px;
}

.invoice-actions button {
	margin-right: 10px;
}

.invoice-ctg-labels,
.invoice-ctg-address,
.invoice-ctg-id {
	color: $SP400;
}

.invoice-ctg-value {
	color: $SP1300;
}
.invoices {
	border: 0.5px solid $SP1500;
	border-radius: 35px;
	box-sizing: border-box;
	background-color: $SP1500;
	&__title {
		padding: 1.5rem;
		color: $SP1100;
		border-top-left-radius: 35px;
		border-top-right-radius: 35px;
		font-size: 22px;
	}

	&__main {
		padding: 0;

		.invoices__item {
			border-bottom: 2px solid $SP1500;
			align-items: center;

			&:nth-child(even) {
				background-color: $SP1400;
			}

			&:nth-child(odd) {
				background-color: $SP1100;
			}

			.invoice-info {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 12px;
				text-align: left;
				color: $SP400;
				font-weight: bold;
				font-size: 15px;
				.value {
					font-weight: normal;
					font-size: 14px;
				}
			}
		}
	}
}
.jobs__footer {
	height: 4rem;
}
</style>
