import { defineStore } from 'pinia';

export const useLoaderStore = defineStore('loader', {
	state: () => ({
		active: false,
	}),
	getters: {
		getLoaderState() {
			return this.active;
		},
	},
});
