// Styles
import 'vuetify/styles';
// Vuetify
// DO NOT REMOVE THE ../../node_modules/. This will cause it to stop working on a MAC and linux. It will not build.
import { createVuetify } from '../../node_modules/vuetify';
import * as components from 'vuetify/components';

import 'material-design-icons-iconfont/dist/material-design-icons.css';

import { aliases, md } from 'vuetify/iconsets/md';

export default createVuetify({
	components,
	icons: {
		defaultSet: 'md',
		aliases,
		sets: {
			md,
		},
	},
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
