<template>
	<v-container class="main-container">
		<v-row justify="center">
			<h2
				class="name"
				@click="router.push('/clients/' + selectedClient.id)"
			>
				{{ selectedClient.firstName }} {{ selectedClient.lastName }}
			</h2>
		</v-row>
		<v-row justify="center">
			<p class="address">
				<router-link :to="'/propertyDetails/' + properties.propertyId">
					{{ selectedClient.addressLineOne }}
					{{ selectedClient.city }}
					{{ selectedClient.state }}
				</router-link>
			</p>
		</v-row>
		<v-row justify="center">
			<p
				data-test="header-text"
				class="sub-text"
			>
				{{ areInvoicesPaid ? 'All Invoices are Paid' : `Total Unpaid Amount $ ${totalUnpaidAmount}` }}
			</p>
		</v-row>
		<div
			v-if="unInvoicedJobs.length"
			class="invoices mb-9"
		>
			<h2 class="invoices__title">{{ unInvoicedJobs.length }} Un-Invoiced Jobs</h2>
			<div class="invoices__data">
				<v-list class="invoices__main">
					<v-list-item
						v-for="job in unInvoicedJobs"
						:key="job.jobsId"
						class="invoices__item"
						data-test="uninvoiced-job"
						@click="router.push(`/services/${job.jobsId}`)"
					>
						<div class="invoice-info">
							<div class="invoice-info__amount">
								Amount: <span class="value">${{ job.jobTotal.toFixed(2) }}</span>
							</div>
							<div class="invoice-info__service-date">
								Serviced Date: <span class="value">{{ parseDateString(job.date) }}</span>
							</div>
						</div>
					</v-list-item>
				</v-list>
				<div class="invoices__footer">
					<v-btn
						class="py-6 btn btn-green generate-invoice-btn"
						block
						rounded="xl"
						:loading="loading"
						@click="generateInvoicesForUninvoicedJobs"
						>Generate Invoice</v-btn
					>
				</div>
			</div>
		</div>
		<div
			v-if="unpaidInvoices.length"
			class="invoices"
		>
			<h2 class="invoices__title">
				{{ unpaidInvoices.length }} Unpaid {{ unpaidInvoices.length > 1 ? 'Invoices' : 'Invoice' }}
			</h2>
			<div class="invoices__data">
				<v-list class="invoices__main">
					<v-list-item
						v-for="invoice in unpaidInvoices"
						:key="invoice.invoiceId"
						class="invoices__item"
						data-test="unpaid-job"
						@click="router.push(`/invoices/${invoice.invoiceId}`)"
					>
						<div class="invoice-info">
							<div class="invoice-info__amount">
								Amount: <span class="value">${{ invoice.invoiceTotal.toFixed(2) }}</span>
							</div>
							<!-- <div class="invoice-info__service-date">
                Serviced Date: <span class="value">{{ parseDateString(job.servicedDate) }}</span>
              </div> -->
							<div class="invoice-info__service-date">
								Invoiced Date: <span class="value">{{ parseDateString(invoice.dateInvoiced) }}</span>
							</div>
						</div>
					</v-list-item>
				</v-list>
				<div class="invoices__footer unpaid-invoices__footer"></div>
			</div>
		</div>
		<v-dialog
			v-model="error"
			activator="child"
			transition="dialog-bottom-transition"
		>
			<v-card class="message-Card py-8 px-4">
				<h2 class="message-Card-Title mb-2">{{ error === 'Invoices Created Successfully' ? 'Success' : 'Error' }}</h2>
				<p class="message-Card-text mb-4">
					{{ error }}
				</p>
				<v-btn
					class="message-Card-Close"
					@click="closeDialog"
				>
					<span class="btn-text px-4 py-12">OK</span>
				</v-btn>
			</v-card>
		</v-dialog>
	</v-container>
</template>
<script setup>
import { onMounted, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import { parseDateString } from '@/composables/text-parser.composables';

const router = useRouter();

const route = useRoute();
// Const showMessage = ref(false);
const error = ref(null);

const token = localStorage.getItem('token');

const API_URL = process.env.VUE_APP_API_URL;
const selectedClient = ref({});
const properties = ref([]);
const unInvoicedJobs = ref([]);
const unpaidJobs = ref([]);
const invoices = ref([]);
const loading = ref(false);
const unpaidInvoices = ref([]);
const areInvoicesPaid = ref(false);

onMounted(async () => {
	const { data } = await axios.get(`${API_URL}/Client/ClientInfo?propertyOwnerDetailsId=${route.params.id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	selectedClient.value = data.clientInfo;
	properties.value = data.properties[0];
	unInvoicedJobs.value = data.unInvoicedJobs;
	unpaidJobs.value = data.unpaidJobs;
	invoices.value = data.invoices;
	unpaidInvoices.value = data.invoices.filter((invoice) => invoice.paid === false);
	areInvoicesPaid.value = data.invoicesPaid;
});
const totalUnpaidAmount = computed(() => {
	let sum = 0;
	unpaidInvoices.value.forEach((job) => {
		sum += job.invoiceTotal;
	});
	return sum;
});
// Const unpaidInvoices = computed(()=> {
//   invoices.value.filter((invoice) => invoice.paid === false);
// })
const generateInvoicesForUninvoicedJobs = async () => {
	error.value = null;
	loading.value = true;
	const { data } = await axios.post(
		`${API_URL}/Invoices/GenerateInvoicesFromList`,
		{
			Id: route.params.id,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
	if (data && data.message === 'Success') {
		error.value = 'Invoices Created Successfully';
	} else {
		error.value = 'Unable to create invoice. Please try again later';
	}

	loading.value = false;
};

const closeDialog = () => {
	if (error.value == 'Invoices Created Successfully') {
		router.push('/dashboard');
	}

	error.value = null;
};
</script>
<style lang="scss" scoped>
@import '../../styles.scss';

.main-container {
	text-align: center;
	font-family: 'Raleway';
	margin-bottom: 5rem;

	.name {
		font-size: 22px;
		margin-bottom: 12px;
		cursor: pointer;
	}

	.address {
		font-size: 14px;
		color: $SP400;
		margin-bottom: 12px;

		a {
			text-decoration: none;
			color: inherit;
		}
	}

	.sub-text {
		color: $SP500;
		font-weight: bold;
		font-size: 18px;
		margin-bottom: 3rem;
	}

	.invoices {
		border: 0.5px solid $SP1500;
		border-radius: 35px;
		box-sizing: border-box;
		background-color: $SP1500;

		&__title {
			padding: 1.5rem;
			color: $SP1100;
			border-top-left-radius: 35px;
			border-top-right-radius: 35px;
			font-size: 22px;
		}

		&__main {
			padding: 0;

			.invoices__item {
				border-bottom: 2px solid $SP1500;
				align-items: center;

				&:nth-child(even) {
					background-color: $SP1400;
				}

				&:nth-child(odd) {
					background-color: $SP1100;
				}

				.invoice-info {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					padding: 12px;
					text-align: left;
					color: $SP400;
					font-weight: bold;
					font-size: 15px;

					.value {
						font-weight: normal;
						font-size: 14px;
					}
				}
			}
		}

		&__footer {
			.btn {
				margin: 2rem 1.5rem;
				min-width: calc(100% - 3rem);
				color: $SP1100;
				text-transform: capitalize;
			}

			.btn-green {
				background-color: $SP800;
				font-weight: bold;
			}
		}
		.unpaid-invoices__footer {
			height: 4rem;
		}
	}
}
</style>
