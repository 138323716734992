<template>
	<v-dialog
		v-model="allJobsDoneDialog"
		class="reschedule-service-modal text-center"
	>
		<v-card class="rounded-xl">
			<v-card-title class="card-title mt-5"> Please Confirm! </v-card-title>
			<div class="d-flex justify-space-between font-weight-bold confirm-sec-text px-3">
				Are you sure you have completed all of these jobs?
			</div>
			<v-card-text>
				<div class="reschedule-details mt-1">
					<ul class="schedule__day-list schedule__list mt-2 text-left">
						<li
							v-for="job in jobs"
							:key="job.propertyId"
							class="pa-2"
						>
							<div class="w-100 px-2">
								<div>
									{{ job.addressLineOne }}
								</div>
								<div class="d-flex justify-space-between">
									<span>{{ job.firstName }} {{ job.lastName }}</span>
									<span :class="job.accountStatus">{{ job.accountStatus }}</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</v-card-text>
			<v-card-actions class="flex-column justify-center">
				<v-btn
					class="rounded-pill schedule_button submit-button text text-capitalize"
					variant="flat"
					width="80%"
					height="48"
					@click="markAllJobsDone"
				>
					<span>Confirm</span>
				</v-btn>
				<v-btn
					class="mb-7 rounded-pill schedule_button submit-button close-button text text-capitalize"
					variant="flat"
					width="80%"
					height="48"
					@click="allJobsDoneDialog = false"
				>
					<span>Cancel</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script setup>
import { ref } from 'vue';
import { useScheduleStore } from '@/stores/schedule';
import { storeToRefs } from 'pinia';
const emit = defineEmits(['setSevenDayArray']);
const scheduleStore = useScheduleStore();
const { allJobsDoneDialog, allJobsDoneArray, getPropertyFees } = storeToRefs(scheduleStore);
const jobs = ref(allJobsDoneArray);

const markAllJobsDone = async () => {
	const today = new Date();
	const dateFinishedMowing = formatDate(today,'MM/DD/YYYY');

	const Jobs = jobs.value.map((job) => ({
		PropertyId: job.propertyId,
		Services: getPropertyFees.value.map((service) => ({
			ServiceDetails: service.description,
			ServiceTitle: service.title,
			ServiceFee: service.amount,
		})),
	}));
	const mowingDetails = {
		Jobs,
		DateTimeMowed: dateFinishedMowing,
	};
	await scheduleStore.setMarkAllAsDone(mowingDetails);
	emit('setSevenDayArray');
	allJobsDoneDialog.value = false;
};
</script>
<style lang="scss" scoped>
@import '../../styles.scss';

.reschedule-service-modal {
	font-family: 'Raleway', sans-serif;
	color: $SP400;

	.confirm-sec-text {
		color: $SP400;
	}

	.schedule {
		&__list {
			list-style: none;

			li {
				div {
					font-family: 'Raleway', sans-serif;
					font-weight: 700;
					font-size: 20px;
					color: $SP400;
				}

				span {
					&:nth-child(1) {
						font-size: 16px;
						font-weight: 600;
						color: $SP400;
					}

					&:nth-child(2) {
						font-size: 14px;
						color: $SP400;
					}
				}

				.fee-structure {
					font-size: 14px;
					color: $SP400;
					font-weight: 400;
				}

				.notes {
					background-color: $SP600;
					display: block;
					padding: 1.5rem 20px 2rem;
					min-height: 10rem;
					border-radius: 2rem;
				}
			}
		}

		&__day-list {
			li:nth-child(even) {
				background-color: $SP100;
			}

			li:nth-child(odd) {
				background-color: $SP1100;
			}
		}
	}

	.submit-button {
		background: $SP500 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px $SP1700;
		text-align: left;
		font: normal normal bold 22px/26px Raleway;
		letter-spacing: 0px;
		color: $SP1100;
		opacity: 1;
		margin-top: 18px;
		padding: 11px 25px;
		width: 134px;
		background-color: $SP500;
	}
	.close-button {
		background-color: $SP400;
	}
}
</style>
