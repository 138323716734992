import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useOnboardingStore = defineStore('onboarding', () => {
	const state = ref({
		message: '',
		nullFields: [],
		isChargesEnabled: false,
		isDetailsSubmitted: false,
		isPayoutsEnabled: false,
		requirements: {},
		individual: null,
		redirectUrl: '',
	});

	const getState = computed(() => {
		return state.value;
	});

	const getRequirementsCurrentlyDue = computed(() => {
		return state.value.requirements.currentlyDue;
	});
	const setOnboardingData = (data) => {
		state.value.message = data.message;
		state.value.nullFields = data.nullFields;
		state.value.isChargesEnabled = data.isChargesEnabled;
		state.value.isDetailsSubmitted = data.isDetailsSubmitted;
		state.value.isPayoutsEnabled = data.isPayoutsEnabled;
		state.value.requirements = data.requirements;
		state.value.individual = data.individual;
		state.value.redirectUrl = data.redirectUrl;
	};

	return { state, setOnboardingData, getState, getRequirementsCurrentlyDue };
});
