<template>
	<v-container>
		<i
			class="back-arrow material-icons"
			@click="$router.go(-1)"
			>arrow_back_ios</i
		>
		<div class="logo">
			<a
				href="https://schedupay.com"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img
					src="schedulepay.png"
					alt=""
					data-testid="logo-image"
				/>
			</a>
			<h2 data-testid="schedupay-text">Schedu<span>Pay</span></h2>
			<h3>Pro</h3>
			<p
				v-if="!accountCreatePending && !connectedAccountId"
				class="custom-font"
			>
				Ready to streamline your payment process? Let's get started by linking your Stripe account. This will enable secure,
				fast, and easy payments, ensuring you get paid promptly for your services.
			</p>
			<p
				v-if="!accountCreatePending && !connectedAccountId"
				class="custom-sub-font float-left pt-5 pb-10 font-weight-bold"
			>
				Click "Start Onboarding" to begin.
			</p>
			<v-btn
				v-if="!accountCreatePending && !connectedAccountId"
				class="elevation-10"
				@click="startOnboarding"
				color="primary"
				data-testid="start-onboarding-button"
			>
				Start Onboarding
			</v-btn>
		</div>

		<v-col
			class="pt-10"
			v-if="accountCreatePending"
			><v-skeleton-loader type="card"></v-skeleton-loader
		></v-col>

		<div id="embedded-onboarding-container" />
		<p
			v-if="error"
			class="error"
		>
			Something went wrong!
		</p>
		<div
			v-if="connectedAccountId || accountCreatePending"
			class="dev-callout"
		>
			<p v-if="accountCreatePending">Creating a connected account...</p>
			<p v-if="onboardingExited">The Account Onboarding component has exited</p>
		</div>

		<v-col v-if="exitedStripeOnboarding">
			<h3>Create a Password for Your ScheduPay Account</h3>
			<div class="password-input pb-5">
				<v-text-field
					v-model="password"
					variant="plain"
					clearable
					class="rounded-pill input"
					:type="passwordFieldType"
					:rules="rules.passwordRules"
					required
					data-testid="password-field"
				/>
				<v-icon
					class="password-toggle-icon"
					:class="{ 'password-visible': showPassword }"
					data-testid="show-password-icon"
					:disabled="rules.passwordRules"
					@click="togglePasswordVisibility"
				>
					{{ showPassword ? 'visibility' : 'visibility_off' }}
				</v-icon>
			</div>
			<v-btn
				data-testid="start-onboarding-button"
				@click="createAccount"
				>Start ScheduPay Onboarding</v-btn
			>
		</v-col>
		<Auth2FADialog data-testid="2fa-dialog" />
	</v-container>
</template>
<script setup>
import Auth2FADialog from '@/components/Auth2FADialog.vue';
import { ref, computed } from 'vue';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import { useAuthStore } from '../stores/auth';
import { storeToRefs } from 'pinia';

const showPassword = ref(false);
const password = ref('');

const rules = ref({
	emailRules: [(v) => Boolean(v) || 'E-mail is required', (v) => /.+@.+/.test(v) || 'E-mail must be valid'],
	nameRules: [(v) => Boolean(v) || 'Name is required', (v) => v.length <= 10 || 'Name must be less than 10 characters'],
	passwordRules: [
		(v) => Boolean(v) || 'Password is required',
		(v) => (v && v.length >= 8) || 'Password must be at least 8 characters',
	],
});

const passwordFieldType = computed(() => (showPassword.value ? 'text' : 'password'));

function togglePasswordVisibility() {
	showPassword.value = !showPassword.value;
}

const exitedStripeOnboarding = ref(false);
const accountCreatePending = ref(false);
const onboardingExited = ref(false);
const error = ref(false);
const connectedAccountId = ref(null);
const stripeConnectInstance = ref(null);
const API_URL = process.env.VUE_APP_API_URL;
const STRIPE_PUB_KEY = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;

const startOnboarding = async () => {
	accountCreatePending.value = true;
	error.value = false;

	try {
		const response = await fetch(`${API_URL}/Onboarding/CreateAccount`, { method: 'POST' });

		if (!response.ok) {
			throw new Error('Failed to create account');
		}
		const json = await response.json();
		accountCreatePending.value = false;
		connectedAccountId.value = json.account;

		const fetchClientSecret = async () => {
			const response = await fetch(`${API_URL}/Onboarding/CreateAccountSession`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(connectedAccountId.value),
			});

			if (!response.ok) {
				const { error } = await response.json();
				throw new Error(`An error occurred: ${error}`);
			}

			const { client_secret: clientSecret } = await response.json();
			return clientSecret;
		};

		stripeConnectInstance.value = await loadConnectAndInitialize({
			publishableKey: STRIPE_PUB_KEY,
			fetchClientSecret,
			appearance: {
				overlays: 'drawer',
				variables: {
					colorPrimary: '#71c55d',
				},
			},
		});

		const container = document.getElementById('embedded-onboarding-container');
		const embeddedOnboardingComponent = stripeConnectInstance.value.create('account-onboarding');

		embeddedOnboardingComponent.setOnExit(async () => {
			exitedStripeOnboarding.value = true;
		});

		embeddedOnboardingComponent.setCollectionOptions({
			fields: 'currently_due', // or 'eventually_due'
			futureRequirements: 'include', // or 'include',
		});

		container.appendChild(embeddedOnboardingComponent);
	} catch (err) {
		accountCreatePending.value = false;
		error.value = true;
		console.error('Error creating account:', err);
	}
};
const authStore = useAuthStore();

const { twoFADialogDisplayed } = storeToRefs(authStore);

const createAccount = async () => {
	const lcpAccount = await fetch(`${API_URL}/Onboarding/RegisterLcpWithConnectId`, {
		method: 'POST',
		body: JSON.stringify({ connectAccountId: connectedAccountId.value, password: password.value }),
		headers: {
			'Content-Type': 'application/json',
		},
	});

	if (lcpAccount.ok) {
		const body = await lcpAccount.json();

		const response = await authStore.login({ email: body.applicationUser.email, password: password.value });

		if (response) {
			password.value = '';
		}

		twoFADialogDisplayed.value = true;
	} else {
		console.error('Error creating account');
		console.error(lcpAccount);
	}
};
</script>
<style lang="scss" scoped>
@import '../../styles.scss';
.custom-font {
	font-size: 15px;
}
.custom-sub-font {
	font-size: 12px;
}
.input {
	padding: 0rem 1rem;
	background-color: $SP600;
}
.logo {
	margin-top: 1.5rem;
	text-align: center;
	font-size: 1.5rem;
	color: $SP1500;

	& > h2 {
		font-weight: 400;

		& span {
			color: $SP400;
		}
	}

	& > h3 {
		font-style: italic;
	}
}

.password-input {
	position: relative;
	display: flex;
	align-items: center;
}
</style>
