import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import growthbook from './plugins/growthbook';
import { loadFonts } from './plugins/webfontloader';
import { markRaw } from 'vue';
import { createPinia } from 'pinia';
import axios from './plugins/axios-plugin';
import { Calendar } from 'v-calendar';
import 'v-calendar/style.css';

loadFonts();

const pinia = createPinia();
pinia.use(({ store }) => {
	store.router = markRaw(router);
});

const app = createApp(App).component('VCalendar', Calendar).use(router).use(vuetify).use(pinia).use(axios).use(growthbook);
app.mount('#app');
