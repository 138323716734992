import { ref, onMounted } from 'vue';

export default function useStripe() {
	const stripe = ref(null);
	const elements = ref(null);
	const STRIPE_PUB_KEY = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
	onMounted(() => {
		if (window.Stripe) {
			stripe.value = window.Stripe(STRIPE_PUB_KEY);
			elements.value = stripe.value.elements();
		}
	});

	return { stripe, elements };
}
