<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<h1 class="display-2 inv-title font-weight-medium">Search Invoices</h1>
			</v-col>
			<v-col cols="12">
				<div class="filter">
					<div class="filter__btns">
						<v-btn
							class="text-body-2"
							variant="outlined"
						>
							Date
						</v-btn>
						<v-btn variant="outlined"> Name </v-btn>
						<v-btn variant="outlined"> Address </v-btn>
					</div>
					<v-text-field
						v-model="searchInvoice"
						dense
						hide-details
						placeholder="Search here"
						variant="plain"
						density="comfortable"
						color="text-red"
						class="rounded-pill filter__input"
					/>
				</div>
			</v-col>
			<v-col cols="12">
				<v-list
					v-if="listOfUnpaidCustomers.length"
					class="list"
					lines="three"
				>
					<v-list-item
						v-for="(customer, i) in listOfUnpaidCustomers"
						:key="i"
						class="item"
						:value="customer"
						@click="router.push(`/invoices/${customer.invoiceId}`)"
					>
						<div>
							<div class="first-row">
								<strong>{{ customer.name }}</strong>
								<span>{{ customer.dateInvoiced }}</span>
							</div>
							<p class="item__address">
								{{ customer.address }}
							</p>
							<p
								class="item__paid"
								:class="customer.paid ? 'paid' : 'unpaid'"
							>
								{{ customer.paid ? 'Paid' : 'Unpaid' }} ${{ customer.amount }}
							</p>
						</div>
					</v-list-item>
				</v-list>
			</v-col>
		</v-row>
	</v-container>
</template>

<script setup>
import { onMounted, computed } from 'vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useInvoicesStore } from '@/stores/invoices-store';

const invoicesStore = useInvoicesStore();
const router = useRouter();
const searchInvoice = ref('');
const listOfUnpaidCustomers = ref([]);

const getAllInvoices = computed(() => invoicesStore.getAllInvoices);

onMounted(async () => {
	await invoicesStore.fetchLCPInvoices();
	listOfUnpaidCustomers.value = getAllInvoices.value;
});
</script>

<style lang="scss" scoped>
@import '../../styles.scss';

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;400&family=Inter:wght@600&family=Raleway:wght@200;400&display=swap');

.filter__btns {
	display: flex;
	gap: 0.4rem;
	margin-bottom: 1rem;
	& button {
		color: $SP1500;
		border: 1px solid $SP200;
		font-weight: 300;
		min-width: 94px;
		border-radius: 20px;
		text-transform: capitalize;

		&:hover {
			color: $SP500;
			background-color: $SP2700;
		}
	}
}

.filter__input {
	border: 1px solid $SP1300;
	padding: 0rem 1.5rem;

	& input {
		padding: 0;
		font-size: 1rem;
	}

	&:deep(.v-field__input) {
		padding: 0;
		color: $SP600;
	}
}

.list {
	text-align: left;
	font-size: 1.2rem;
	font-family: 'Raleway', sans-serif;
	& > * {
		border: 1px solid;
	}
}

.item:nth-child(even) {
	background-color: $SP1400;
}

.item .first-row {
	display: flex;
	justify-content: space-between;
}

.item__address {
	margin: 0.7rem 0rem;
	font-weight: 400;
	color: $SP400;
}

.item__paid {
	font-weight: bold;
}

.paid {
	color: green;
}

.unpaid {
	color: $SP500;
}

.inv-title {
	color: $SP400;
}
</style>
