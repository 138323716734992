<template>
	<div class="not-found">
		<h1>404 - Page Not Found</h1>
		<p>Sorry, the page you are looking for doesn't exist.</p>
		<v-btn
			v-if="$route.meta.hideNavigation"
			rounded="pill"
			class=""
		>
			<router-link
				class="go-to"
				to="/login"
				>Go to Login Page</router-link
			>
		</v-btn>
	</div>
</template>

<style lang="scss">
@import '../../styles.scss';
.not-found {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 80vh;
	text-align: center;

	.go-to {
		text-decoration: none;
		color: $SP700;
	}
}
</style>
