const API_URL = process.env.VUE_APP_API_URL;

/**
 *
 * @param {string} url can include the params as well
 * @param {object | null} bodyData
 * @param {string} method
 * @returns response.json()
 */
export const fetchComposable = async (url, bodyData = null, method) => {
	try {
		const response = await fetch(`${API_URL}${url}`, options(method, bodyData));

		if (response.status === 200) {
			return response;
		}

		throw new Error(`HTTP error! Status: ${response.status}`);
	} catch (error) {
		console.error(error);
		return error.status;
	}
};

const options = (method, bodyData) => {
	const token = localStorage.getItem('token');
	const headers = {
		Authorization: `bearer ${token}`,
	};
	if (method !== 'GET') {
		return {
			method,
			headers,
			body: bodyData,
		};
	}

	return {
		method,
		headers,
	};
};
