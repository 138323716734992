<template>
	<v-dialog
		v-model="open"
		fullscreen
	>
		<v-card class="main-drawer list-bg">
			<v-toolbar class="main-drawer__toolbar-bg px-4 py-2">
				<Icon
					icon="ic:baseline-account-circle"
					width="50"
				></Icon>
				<v-toolbar-title>
					<div class="d-flex flex-column">
						<span class="text-h6 font-weight-bold">{{
							companyStore.companyName ? companyStore.companyName : 'Company name not set.'
						}}</span>
						<span class="text-caption">{{ companyStore.email }}</span>
					</div>
				</v-toolbar-title>
				<v-btn
					icon
					:elevation="2"
					data-testid="close-nav-drawer-button"
					@click="dialogClose"
				>
					<CloseIcon class="h-full" />
				</v-btn>
			</v-toolbar>
			<v-list class="main-drawer__list pa-2 my-2">
				<v-list-item
					v-for="item in filteredItems"
					:key="item.name"
					@click="dialogClose"
				>
					<template #prepend>
						<v-icon
							:icon="item.icon"
							class="main-drawer__list__icon"
							:class="!item.href ? 'caution' : 'solid'"
						/>
					</template>
					<router-link
						:to="item.href"
						class="text-decoration-none"
						:data-testid="item.data_test_id"
					>
						<v-list-item-title
							class="main-drawer__list__title text-h5 font-weight-black py-6"
							v-text="item.name"
						/>
					</router-link>
				</v-list-item>
				<v-list-item>
					<v-btn
						class="my-5 rounded-pill logout_button"
						variant="flat"
						height="48"
						data-testid="logout-button"
						@click="logout"
					>
						<span>Logout</span>
					</v-btn>
				</v-list-item>
			</v-list>
		</v-card>
	</v-dialog>
</template>

<script setup>
import {
	CloseIcon,
	DashboardIcon,
	InvoiceIcon,
	ChatIcon,
	PauseIcon,
	SettingsIcon,
	HelpIcon,
	ChatProcessIcon,
	EmailIcon,
} from '@/assets';
import { computed } from 'vue';
import { useNavStore } from '@/stores/nav-store';
import { useCompanyStore } from '@/stores/company';
import { useFeatureFlagStore } from '@/stores/feature-flag-store';
import { useRouter } from 'vue-router';
import { Icon } from '@iconify/vue';

const router = useRouter();
const navStore = useNavStore();
const companyStore = useCompanyStore();
const featureFlagStore = useFeatureFlagStore();

const open = computed(() => {
	if (navStore.topNavOpen) {
		featureFlagStore.init();
	}

	return navStore.topNavOpen;
});

const dialogClose = () => {
	navStore.$patch({ topNavOpen: false });
};

const logout = () => {
	localStorage.clear();
	router.replace('/login');
};

const items = [
	{
		name: 'Dashboard',
		icon: DashboardIcon,
		href: '/dashboard',
		name_font: 'bold',
		data_test_id: 'dashboard',
	},
	{
		name: 'Invoices',
		icon: InvoiceIcon,
		href: '/invoices',
		name_font: 'bold',
		data_test_id: 'invoices',
	},
	{
		name: 'Get Help',
		icon: HelpIcon,
		href: '',
		name_font: 'bold',
		data_test_id: 'get-help',
	},
	{
		name: '423.341.5125',
		icon: ChatProcessIcon,
		href: '',
		name_font: '',
		data_test_id: 'phone',
	},
	{
		name: 'jbthype@gmail.com',
		icon: EmailIcon,
		href: '',
		name_font: '',
		data_test_id: 'email',
	},
];

const filteredItems = computed(() => {
	let fItems = [...items];

	if (featureFlagStore.proSettings) {
		fItems.splice(
			2,
			0,
			{
				name: 'Settings',
				icon: SettingsIcon,
				href: '/settings',
				name_font: 'bold',
				data_test_id: 'settings',
			},
		);
	}

	if (featureFlagStore.proPauseMowing) {
		fItems.splice(
			2,
			0,
			{
				name: 'Pause Mowing',
				icon: PauseIcon,
				href: '/pauseMowing',
				name_font: 'bold',
				data_test_id: 'pause-mowing',
			},
		)
	}

	if (featureFlagStore.proChat) {
		fItems.splice(
			2,
			0,
			{
				name: 'Chat',
				icon: ChatIcon,
				href: '/chat',
				name_font: 'bold',
				data_test_id: 'chat',
			},
		);
	}

	return fItems;
});
</script>

<style lang="scss">
@import '../../styles.scss';

.main-drawer {
	background-color: $SP1400 !important;

	&__toolbar-bg {
		background: $SP1100 !important;
	}

	.v-toolbar-title div {
		color: $SP400;
	}

	&__list {
		background: transparent !important;

		&__icon {
			opacity: 1 !important;
			width: 35px !important;
			height: 35px !important;

			svg {
				width: 35px !important;
				height: 35px !important;
			}
		}

		&__title {
			color: $SP400;
		}
		.logout_button {
			background-color: $SP500;
			color: $SP1600;
			text-transform: capitalize;
			width: 100%;
		}
	}
}

.solid {
	color: $SP900;
}

.caution {
	color: $SP500;
}
.outlined-icon {
	border: 2px solid currentColor;
	border-radius: 50%;
	padding: 4px;
}
</style>
