<template>
	<div
		v-if="props.clickedTab === 'week'"
		id="weekly-tab"
		class="week-content"
	>
		<!--THIS IS WEEKLY VIEW -->
		<v-container class="pa-0 overflow-hidden">
			<v-row class="pa-0 mb-10">
				<v-col
					v-if="props.schedules.length"
					cols="12"
					class="d-flex overflow-x-auto pl-11 pr-11 schedule__card-wrapper"
				>
					<v-card
						v-for="schedule in props.schedules"
						:key="schedule.title"
						class="schedule__card mr-8 mt-5 pt-5 pb-5"
						min-width="306"
					>
						<p class="schedule__title text-center px-5">{{ schedule.label }}'s Schedule</p>
						<p class="schedule__date text-center mt-2">
							{{ dateFormat(schedule.date, 'dddd MM/DD/YYYY') }}
						</p>
						<div
							v-if="!getActiveSchedules(schedule.schedules).length"
							class="d-flex align-center justify-center pa-4"
						>
							<span class="my-15 no-jobs text-h4 font-weight-bold text-center"
								>You don't have any jobs scheduled for today...</span
							>
						</div>
						<div v-else>
							<p class="schedule__jobs text-center mt-2">
								{{ schedule.schedules.length }} Schedule Jobs {{ schedule.label }}
							</p>
							<ul class="schedule__day-list schedule__list mt-10">
								<li
									v-for="job in getActiveSchedules(schedule.schedules)"
									:key="job.propertyId"
									class="pa-2"
								>
									<div class="w-100 px-2">
										<div
											data-testid="properyRoute"
											@click="
												$router.push(`/propertyDetails/${job.propertyId}`);
												selectedPropertyId = job.propertyId;
											"
										>
											{{ job.addressLineOne }}
										</div>
										<div class="d-flex justify-space-between">
											<span
												data-testid="clientInfo"
												@click="$router.push(`/clients/${job.propertyOwnerId}`)"
												>{{ job.firstName }} {{ job.lastName }}</span
											>
											<span :id="job.unpaidInvoice ? 'un-paid' : 'paid'">{{ job.unpaidInvoice ? 'Un-Paid' : 'Paid' }}</span>
										</div>
									</div>
								</li>
							</ul>
							<div
								v-if="schedule.schedules.length > 0"
								class="d-block text-center mt-10"
							>
								<v-btn
									class="my-3 rounded-pill schedule__button reschedule-btn text"
									variant="flat"
									width="232"
									height="48"
									@click="emit('rescheduleJobsModal', schedule)"
								>
									<span>Reschedule Today's Service</span>
								</v-btn>
								<v-btn
									class="my-3 rounded-pill schedule__button close-btn"
									variant="flat"
									width="232"
									height="48"
									@click="
										allJobsDoneDialog = true;
										allJobsDoneArray = schedule.schedules;
									"
								>
									<span>Mark All as Done</span>
								</v-btn>
							</div>
						</div>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script setup>
import { dateFormat } from '@/utils/dateFormatter';
import { defineProps } from 'vue';
import { useScheduleStore } from '@/stores/schedule';
import { storeToRefs } from 'pinia';

const scheduleStore = useScheduleStore();
const { selectedPropertyId, allJobsDoneDialog, allJobsDoneArray } = storeToRefs(scheduleStore);
const emit = defineEmits(['rescheduleJobsModal']);

const props = defineProps({
	clickedTab: {
		type: String,
		default: '', // Provide a default value here
	},
	schedules: {
		type: Array,
		default: () => [], // Provide a default value here
	},
});

const getActiveSchedules = (schedule) =>
	schedule.filter((schedule) => schedule.accountStatus === 'Active' && schedule.propertyStatus === 'Active');
</script>
<style lang="scss" scoped>
@import '../../styles.scss';

.schedule {
	&__title {
		font-family: 'Raleway', sans-serif;
		font-weight: 500;
		font-size: 24px;
		line-height: 32px;
		color: $SP400;
	}

	&__date {
		font-family: 'Raleway', sans-serif;
		font-weight: 600;
		font-size: 18px;
		color: $SP400;
	}

	&__jobs {
		font-family: 'Raleway', sans-serif;
		font-weight: 200;
		font-size: 20px;
		color: $SP400;
	}

	&__button {
		text-transform: capitalize;
		box-shadow: 0px 3px 6px $SP1700;

		span {
			color: $SP1600;
			font-family: Raleway, sans-serif;
			letter-spacing: 0;
		}
	}
	&__list {
		list-style: none;

		li {
			div {
				font-family: 'Raleway', sans-serif;
				font-weight: 700;
				font-size: 20px;
				color: $SP400;
			}

			span {
				&:nth-child(1) {
					font-size: 16px;
					font-weight: 300;
					color: $SP400;
				}

				&:nth-child(2) {
					font-size: 14px;
					color: $SP400;
				}
			}

			#paid {
				color: $SP800;
			}

			#un-paid {
				color: $SP500;
			}

			.fee-structure {
				font-size: 14px;
				color: $SP400;
				font-weight: 400;
			}

			.notes {
				background-color: $SP600;
				display: block;
				padding: 1.5rem 20px 2rem;
				min-height: 10rem;
				border-radius: 2rem;
			}
		}
	}

	&__day-list {
		li:nth-child(even) {
			background-color: $SP1400;
		}

		li:nth-child(odd) {
			background-color: $SP1600;
		}
	}

	&__card {
		background-color: $SP1100;
		border-radius: 56px;
		box-shadow: 0 3px 6px $SP1700;
		.no-jobs {
			color: $SP500;
		}
	}
}
.reschedule-btn {
	background-color: $SP500 !important;
}
.close-btn {
	background-color: $SP800 !important;
}
</style>
