import { defineStore } from 'pinia';
import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

export const useApplicationStore = defineStore('application', {
	state: () => ({
		states: [],
		submitMessageDialogDisplayed: false,
		submitMessageTitle: '',
		submitMessage: '',
	}),
	actions: {
		async fetchStates() {
			const token = localStorage.getItem('token');
			await axios
				.get(`${API_URL}/State/ReadAll`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then((res) => {
					this.states = res.data;
				});
		},
	},
});
