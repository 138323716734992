import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

export const useInvoicesStore = defineStore('invoicesStore', () => {
	const invoices = ref([]);

	const getInvoiceById = computed(
		() => (idToGetInvoice) => invoices.value.find((invoice) => invoice.invoiceId === idToGetInvoice),
	);

	const getAllInvoices = computed(() => invoices.value);

	const fetchLCPInvoices = async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await axios.get(`${API_URL}/Invoices/GetLcpInvoices`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			invoices.value = response.data;
		} catch (error) {
			console.error(error);
		}
	};

	return {
		invoices,
		getInvoiceById,
		getAllInvoices,
		fetchLCPInvoices,
	};
});
