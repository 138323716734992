<template>
	<v-dialog
		v-model="rescheduleJobModalVisible"
		class="reschedule-modal text-center"
	>
		<v-card class="rounded-xl">
			<v-card-title class="card-title mt-5"> Reschedule Today's Jobs </v-card-title>
			<v-card-text>
				<div class="reschedule__header mt-7">
					<h3 class="heading mr-7">Reschedule To</h3>
					<div class="calendar-icon">
						<Icon
							icon="material-symbols:calendar-month"
							width="40"
							@click="showWholeDayCalendar = true"
						/>
						<div class="calendar">
							<VCalendar
								v-if="showWholeDayCalendar"
								v-model="rescheduleWholeDayDate"
								mode="date"
								:attributes="calendarAttributesForAllJobs"
								@dayclick="selectDateForAllJobs"
							/>
						</div>
					</div>
				</div>
				<div class="reschedule-details mt-1">
					<div
						v-for="data in rescheduleTodaysJobData"
						:key="data.propertyId"
						class="reschedule-data"
					>
						<div class="left">
							<h2 class="name">{{ data.firstName }} {{ data.lastName }}</h2>
							<div class="street">
								{{ data.addressLineOne }}
							</div>
							<div class="city">
								{{ data.city }}
							</div>
						</div>
						<div class="date">
							{{ data.RescheduledToDate }}
						</div>
						<div class="calendar-icon">
							<Icon
								icon="material-symbols:calendar-month"
								width="40"
								@click="showSpecificDayCalendar(data.propertyId)"
							/>
							<div class="calendar">
								<VCalendar
									v-if="displayedCalendarId === data.propertyId"
									v-model="data.RescheduledToDate"
									mode="date"
									:attributes="data.calendarAttributes"
									@dayclick="selectDateForSpecificJob"
								/>
							</div>
						</div>
					</div>
					<h3 class="reason mt-9 mb-3">Reschedule Reason/Details</h3>
					<v-textarea
						v-model="rescheduleWholeDayReason"
						variant="plain"
						class="rounded-xl input"
						clearable
					></v-textarea>
				</div>
			</v-card-text>
			<v-card-actions class="flex-column justify-center">
				<v-btn
					data-testid="submit-button"
					class="rounded-pill schedule_button submit-button text text-capitalize"
					variant="flat"
					width="80%"
					height="48"
					:disabled="!canRescheduleWholeDay"
					@click="rescheduleWholeDay"
				>
					<span>Submit</span>
				</v-btn>
				<v-btn
					data-testid="close-button"
					class="mb-7 rounded-pill schedule_button submit-button close-button text text-capitalize"
					variant="flat"
					width="80%"
					height="48"
					@click="rescheduleJobModalVisible = false"
				>
					<span>Close Dialog</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script setup>
import { Icon } from '@iconify/vue';
import { ref, watch } from 'vue';
import { useScheduleStore } from '@/stores/schedule';
import { storeToRefs } from 'pinia';
import { dateFormat } from '@/utils/dateFormatter';

const scheduleStore = useScheduleStore();
const { rescheduleJobModalVisible, rescheduleTodaysJobData } = storeToRefs(scheduleStore);
const showWholeDayCalendar = ref(false);
const canRescheduleWholeDay = ref(false);
const closeCalendar = () => {
	showWholeDayCalendar.value = false;
};

const emit = defineEmits(['setSevenDayArray']);

const whatDateIsYesterday = ref(new Date(new Date().setDate(new Date().getDate() - 1)));
const displayedCalendarId = ref(null);

const showSpecificDayCalendar = (id) => {
	if (displayedCalendarId.value !== null) {
		displayedCalendarId.value = null;
	} else {
		displayedCalendarId.value = id;
	}
};

const rescheduleWholeDayDate = ref(new Date());

const selectDateForAllJobs = (dateval) => {
	calendarAttributesForAllJobs.value = [{ highlight: true, dates: dateval.date }];
	rescheduleWholeDayDate.value = dateFormat(dateval.date, 'MM/DD/YYYY');
	rescheduleTodaysJobData.value.forEach((val) => {
		val.RescheduledToDate = rescheduleWholeDayDate.value;
	});
	canRescheduleWholeDay.value = new Date(dateval.date) > whatDateIsYesterday.value;
	showWholeDayCalendar.value = null;
};

const rescheduleWholeDayReason = ref('');

const rescheduleWholeDay = async () => {
	const payload = [];
	rescheduleTodaysJobData.value.forEach((item) => {
		payload.push({
			propertyId: item.propertyId,
			RescheduledToDate: item.RescheduledToDate,
			Reason: rescheduleWholeDayReason.value,
		});
	});
	await scheduleStore.rescheduleWholeDay(payload);
	emit('setSevenDayArray');
	rescheduleJobModalVisible.value = false;
	canRescheduleWholeDay.value = false;
};

const selectDateForSpecificJob = (dateVal) => {
	rescheduleTodaysJobData.value.forEach((val) => {
		if (val.propertyId === displayedCalendarId.value) {
			val.RescheduledToDate = dateFormat(dateVal.date, 'MM/DD/YYYY');
		}
	});
	canRescheduleWholeDay.value = new Date(dateVal.date) > whatDateIsYesterday.value;
	displayedCalendarId.value = null;
};

const calendarAttributesForAllJobs = ref();

watch(
	() => rescheduleTodaysJobData.value,
	(newValue) => {
		if (newValue) {
			newValue.forEach((item) => {
				rescheduleWholeDayDate.value = dateFormat(item.nextMow, 'MM/DD/YYYY');
				item.RescheduledToDate = rescheduleWholeDayDate.value;
				item.calendarAttributes = [{ highlight: true, dates: rescheduleWholeDayDate.value }];
			});
			calendarAttributesForAllJobs.value = [{ highlight: true, dates: rescheduleWholeDayDate.value }];
		}
	},
);
</script>
<style lang="scss">
@import '../../styles.scss';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap');

.reschedule-modal {
	font-family: 'Raleway', sans-serif;
	color: $SP400;

	.card-title {
		white-space: normal;
	}

	.reschedule__header {
		display: flex;
		justify-content: flex-end;
		color: $SP400;
		align-items: center;
	}

	.reschedule-data {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: $SP400;
		margin-top: 12px;

		.left {
			text-align: left;
			line-height: 1.2;

			.name {
				font-weight: bold;
				font-size: 20px;
			}

			.street,
			.city {
				font-size: 14px;
			}
		}

		.date {
			font-size: 18px;
			font-weight: 500;
		}
	}

	.reason {
		font-size: 20px;
	}

	.input {
		padding: 0rem 1rem;
		background-color: $SP600;
	}

	.calendar-icon {
		cursor: pointer;

		.calendar {
			position: absolute;
			right: 10px;
			z-index: 10;
		}
	}

	.submit-button {
		background: $SP500 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px $SP1700;
		text-align: left;
		font: normal normal bold 22px/26px Raleway;
		letter-spacing: 0px;
		color: $SP1100;
		opacity: 1;
		margin-top: 18px;
		padding: 11px 25px;
		width: 134px;
	}

	.close-button {
		background-color: $SP400;
	}
}
</style>
