<template>
	<div
		v-if="pendingInvites.length"
		class="pending-invites my-3"
	>
		<h1 class="invites__heading mb-4">Pending Invites</h1>
		<v-table class="table__card">
			<tbody class="table__body">
				<tr
					v-for="(item, index) in pendingInvites"
					:key="index"
				>
					<div class="invites-list pa-3 px-4">
						<div class="invite-data">
							<div class="invite-email mb-1">{{ item.email }}</div>
							<div class="invite-phone mb-1">{{ item.phone }}</div>
							<div class="invite-date mb-1">
								Date Invited: <span data-testid="invite-date-value">{{ dateFormat(item.inviteDate, 'MM/DD/YYYY') }}</span>
							</div>
						</div>
						<div>
							<v-btn
								class="rounded-pill px-3 resend-btn"
								variant="flat"
								height="40"
								@click="resendInvitation(item)"
							>
								<span>Re-Send</span>
							</v-btn>
						</div>
					</div>
				</tr>
			</tbody>
		</v-table>
	</div>
	<v-card
		class="mx-auto mb-10"
		max-width="360"
		flat
	>
		<h1 class="search__heading">Search Clients</h1>
		<v-text-field
			v-model="searchedText"
			class="search__input rounded-pill pl-4"
			clearable
			variant="plain"
			placeholder="Search here.."
		></v-text-field>
	</v-card>
	<v-card class="table__card">
		<v-table>
			<tbody class="table__body">
				<tr
					v-for="(item, index) in searchedClients"
					:key="index"
					@click="
						dialog = true;
						getActiveClient(item.propertyOwnerDetailesId);
					"
				>
					<div class="client__list__main pa-1 px-5">
						<div class="client__name mb-1">{{ item.firstName }} {{ item.lastName }}</div>
						<div class="client__address mb-1">{{ item.addressLineOne }}</div>
						<div class="client__paymeny__status d-flex justify-flex-start mb-2">
							<div
								:class="item.paymentClass"
								class="account-status"
							>
								{{ item.accountStatus ? item.accountStatus : 'Please set account status' }}
							</div>
							<div
								:class="item.statusClass"
								class="ml-10"
							>
								{{ item.invoiceState }}
							</div>
						</div>
					</div>
				</tr>
			</tbody>
		</v-table>
	</v-card>
	<SubmitMessageDialog />
</template>

<script setup>
import { ref, watchEffect, onMounted } from 'vue';

import { dateFormat } from '@/utils/dateFormatter.js';

import axios from 'axios';
import SubmitMessageDialog from '../components/SubmitMessageDialog';

const API_URL = process.env.VUE_APP_API_URL;
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useApplicationStore } from '@/stores/application';

const router = useRouter();
const searchedText = ref('');
const clients = ref([]);
const pendingInvites = ref([]);

const applicationStore = useApplicationStore();
const { submitMessageDialogDisplayed, submitMessageTitle } = storeToRefs(applicationStore);

onMounted(async () => {
	await getAllClients();
});

async function getAllClients() {
	const token = localStorage.getItem('token');
	await axios
		.get(`${API_URL}/Client/GetAllClient`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		.then((res) => {
			clients.value = res.data.clients;
			pendingInvites.value = res.data.pendingInvites;
		})
		.catch((err) => {
			clients.value = [];
			pendingInvites.value = [];
			console.error(err);
		});
}

const searchedClients = ref(clients.value);

function getActiveClient(id) {
	router.push(`/clients/${id}`);
}

watchEffect(() => {
	searchedClients.value = clients.value;
	searchedClients.value = clients.value.filter(
		(client) =>
			(client.firstName && client.firstName.toUpperCase().includes(searchedText.value.toUpperCase())) ||
			(client.lastName && client.lastName.toUpperCase().includes(searchedText.value.toUpperCase())),
	);
});

async function resendInvitation(invite) {
	let isEmail = invite.email && invite.email.trim().length > 0;
	const payload = {
		IsSMS: !isEmail,
		IsEmail: isEmail,
		Phone: invite.phone,
		Email: invite.email,
	};

	const token = localStorage.getItem('token');
	axios.defaults.headers.common = { Authorization: `bearer ${token}` };
	await axios
		.post(`${API_URL}/Client/SendInvite`, payload, {
			headers: {
				'content-type': 'application/json; charset=utf-8',
				'cache-control': 'private',
			},
		})
		.then(async () => {
			submitMessageTitle.value = 'Invitation sent';
			submitMessageDialogDisplayed.value = true;
			await getAllClients();
		})
		.catch((reason) => {
			submitMessageTitle.value = 'Unable to send invitation';
			console.error(reason);
			submitMessageDialogDisplayed.value = true;
		});
}
</script>

<style lang="scss">
@import '../../styles.scss';
.pending-invites {
	text-align: center;
	.v-table {
		border: none;
		tr {
			.invites-list {
				text-align: left;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border: 1px solid $SP2200;
				.resend-btn {
					background-color: $SP1600;
					border: 0.5px solid $SP800;
					color: $SP800;
					border-radius: 9999px;
					span {
						text-transform: capitalize;
						color: $SP800;
						font-family: Raleway;
					}
				}
			}
		}
	}
}

.search__heading,
.invites__heading {
	color: $SP2200;
	font-family: 'Raleway', sans-serif;
	font-weight: 700;
	font-size: 24px;
}

.search__input {
	border: 1px solid $SP2300;
	border-radius: 25px;
	height: 3rem;
}

.table__card {
	width: 90%;
	margin: auto;
	border: 1px solid $SP1800;
}

.client__list__main {
	border-bottom: 2px solid $SP2400;
	cursor: pointer;
}

.table__body > tr:nth-child(even) {
	background-color: $SP2500;
}

.table__body > tr:nth-child(odd) {
	background-color: $SP1100;
}

.client__name,
.invite-email,
.invite-phone {
	color: $SP400;
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
	font-size: 20px;
}

.client__address,
.invite-date {
	color: $SP2100;
	font-family: 'Raleway', sans-serif;
	font-weight: 300;
	font-size: 18px;
}

.client__paymeny__status {
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
	font-size: 18px;
}

.check__status--paid {
	color: $SP2000;
}

.check__status--unpaid {
	color: $SP1900;
}

.check__status--active {
	color: $SP800;
}

.check__status--inactive,
.account-status {
	color: $SP1900;
}
</style>
