<template>
	<v-dialog v-model="showEditPropertiesDialog" class="property-modal text-center">
		<v-card>
			<v-card-title class="card-title mt-5 mb-3" data-testid="card-title">
				{{ !props.addNew ? 'Edit Property Details' : 'Property Details' }}
			</v-card-title>
			<v-form ref="form">
				<v-card-text>
					<h3>Address Line One</h3>
					<v-text-field v-model="addressLineOne" variant="plain" class="code__input rounded-pill input"
						clearable :rules="[validationRequiredRule('Address')]"
						data-testid="addressLineOne"></v-text-field>

					<h3>Address Line Two</h3>
					<v-text-field v-model="addressLineTwo" variant="plain" class="code__input rounded-pill input"
						clearable data-testid="addressLineTwo"></v-text-field>

					<h3>City</h3>
					<v-text-field v-model="city" variant="plain" class="code__input rounded-pill input city-input"
						clearable :rules="[validationRequiredRule('City')]" data-testid="city"></v-text-field>

					<h3>State</h3>
					<v-select v-model="state" class="card__input rounded-pill" variant="plain" clearable required
						label="Select" :items="states" item-title="state" item-value="abbreviation"
						:rules="[validationRequiredRule('State')]" data-testid="state" />

					<h3>Zip</h3>
					<v-text-field v-model="zip" variant="plain" class="code__input rounded-pill input" clearable
						:rules="[validationRequiredRule('Zip')]" data-testid="zip"></v-text-field>
					<h3>Mowing Rate</h3>
					<v-text-field v-model="mowingRate" variant="plain" class="code__input rounded-pill input" clearable
						:rules="[validationRequiredRule('Mowing Rate'), (v) => (/^[0-9]*\.?[0-9]*$/.test(v) || 'Value needs to be numeric')]"
						data-testid="mowingRate"></v-text-field>
					<h3>Mowing Frequency</h3>
					<v-select v-model="mowingFrequency" :items="[
						{ text: '1', value: '1' },
						{ text: '7 (week)', value: '7' },
						{ text: '10', value: '10' },
						{ text: '14 (bi-weekly)', value: '14' },
					]" item-title="text" item-value="value" variant="plain" class="code__input rounded-pill input" clearable
						:rules="[validationRequiredRule('Mowing Frequency')]"
						data-testid="mowingFrequency"></v-select>

					<h3>Status</h3>
					<v-select v-model="status" :items="['Active', 'Inactive', 'Paused']" variant="plain" clearable
						class="code__input rounded-pill input" :rules="[validationRequiredRule('Status')]"
						data-testid="status"></v-select>

					<h3>Notes</h3>
					<v-textarea v-model="notes" variant="plain" class="rounded-xl input notes" clearable
						data-testid="notes"></v-textarea>
				</v-card-text>

				<v-card-actions class="d-flex flex-column justify-center" data-testid="v-card-actions">
					<v-btn class="mb-7 rounded-pill submit_button submit-button text" variant="flat" width="80%"
						height="48" data-testid="submit-button" :loading="isUpdating" :disabled="isUpdating" @click="saveProperty">
						<span>Submit</span>
					</v-btn>
					<v-btn class="mb-7 rounded-pill schedule_button close-button text" variant="flat" width="80%"
						height="48" data-testid="close-dialog-button" @click="showEditPropertiesDialog = false">
						<span>Cancel</span>
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>

	<v-dialog v-model="showMessage" activator="child" transition="dialog-bottom-transition">
		<v-card class="message-Card py-8 px-4">
			<h2 class="message-Card-Title mb-2">{{ errorMessage === '' ? 'Success' : 'Error' }}</h2>
			<p class="message-Card-text mb-4">
				{{ errorMessage === '' ? `Property Details Saved Successfully` : errorMessage }}
			</p>
			<v-btn class="message-Card-Close" @click="showMessage = false">
				<span class="btn-text px-4 py-12">OK</span>
			</v-btn>
		</v-card>
	</v-dialog>
</template>
<script setup>
import { useScheduleStore } from '@/stores/schedule';
import { useApplicationStore } from '@/stores/application';
import { useCompanyStore } from '@/stores/company';
import { storeToRefs } from 'pinia';
import { onMounted, ref, watch } from 'vue';

const props = defineProps({
	addNew: {
		type: Boolean,
		default: false,
	},
	clientId: {
		type: String,
		default: '',
	},
});

const emits = defineEmits(['onChange']);

const scheduleStore = useScheduleStore();
const { showEditPropertiesDialog, selectedPropertyDetails } = storeToRefs(scheduleStore);
const companyStore = useCompanyStore();
const states = ref([]);

const addressLineOne = ref('');
const addressLineTwo = ref('');
const city = ref('');
const state = ref('');
const mowingRate = ref('');
const mowingFrequency = ref('');
const zip = ref('');
const notes = ref('');
const status = ref('');

const form = ref(null);
const showMessage = ref(false);
const errorMessage = ref('');
const isUpdating = ref(false);

onMounted(async () => {
	await useApplicationStore().fetchStates();
	useApplicationStore().states.forEach((state) => {
		states.value.push({ state: state.name, abbreviation: state.abbreviation });
	});

	if (props.addNew && companyStore.lcpDetailsId === null) {
		await companyStore.fetchCompanyInfo();
	}
});

const populatePropertyDetails = () => {
	addressLineOne.value = selectedPropertyDetails.value.addressLineOne;
	addressLineTwo.value = selectedPropertyDetails.value.addressLineTwo;
	city.value = selectedPropertyDetails.value.city;
	state.value = selectedPropertyDetails.value.state;
	mowingRate.value = selectedPropertyDetails.value.mowingRate;
	mowingFrequency.value = selectedPropertyDetails.value.mowingFrequency;
	zip.value = selectedPropertyDetails.value.zip;
	notes.value = selectedPropertyDetails.value.notes;
	status.value = selectedPropertyDetails.value.status;
};

const validationRequiredRule = (field) => (v) => !!v || `${field} cannot be empty`;

const clearInputs = () => {
	if (form.value) {
		form.value.reset();

		addressLineOne.value = '';
		addressLineTwo.value = '';
		city.value = '';
		state.value = '';
		mowingRate.value = '';
		mowingFrequency.value = '';
		zip.value = '';
		notes.value = '';
		status.value = '';
	}
};

const validateForm = async () => {
	return await form.value.validate();
}

const saveProperty = async () => {
	errorMessage.value = '';
	const validationResult = await validateForm();
	if (!validationResult.valid) {
		errorMessage.value = validationResult.errors[0].errorMessages[0];
		showMessage.value = true;

		return;
	}

	const payload = {
		addressLineOne: addressLineOne.value,
		addressLineTwo: addressLineTwo.value,
		city: city.value,
		state: state.value,
		mowingRate: mowingRate.value,
		mowingFrequency: mowingFrequency.value.toString(),
		status: status.value,
		zip: zip.value,
		notes: notes.value,
	};

	const addOrUpdate = props.addNew ? scheduleStore.addProperty : scheduleStore.updateProperty;

	if (props.addNew) {
		payload.propertyOwnerDetailsId = props.clientId;
		payload.lawnCareProDetailsId = companyStore.lcpDetailsId;
	} else {
		payload.propertyId = selectedPropertyDetails.value.propertyId;
	}

	isUpdating.value = true;
	try {
		await addOrUpdate(payload);
		errorMessage.value = '';
		showEditPropertiesDialog.value = false;
		emits('onChange');
	} catch (error) {
		console.error(`Failed to ${props.addNew ? 'add' : 'update'} property.`, error);
		errorMessage.value = 'Unable to save property details. Please try again later.';
	}
	finally {
		isUpdating.value = false;
	}

	showMessage.value = true;
};

watch(showEditPropertiesDialog, () => {
	if (showEditPropertiesDialog.value) {
		if (!props.addNew) {
			populatePropertyDetails();
			return;
		}
	}

	clearInputs();
});
</script>
<style lang="scss" scoped>
@import '../../styles.scss';

.property-modal {

	.v-card,
	.v-card {
		border-radius: 20px;
	}

	h3 {
		text-align: center;
		font-size: 16px;
		margin: 15px 0;
	}

	.submit_button,
	.close-button {
		color: $SP1600;
		font-weight: bold;
		font-size: 16px;
		text-transform: capitalize;
	}

	.notes {
		background-color: $SP600;
		padding: 1rem 20px;
	}

	.submit_button {
		background-color: $SP500;
	}

	.close-button {
		background-color: $SP400;
	}
}

.message-Card {
	border-radius: 50px !important;
	padding: 70px 42px 55px 45px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font: normal normal normal 16px/22px Raleway;
	color: $SP400;
	text-align: center;

	.message-Card-Title {
		font: normal normal 600 20px/22px Raleway;
		color: $SP400;
	}

	.message-Card-Close {
		display: flex;
		background: $SP800 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px $SP1700;
		border-radius: 28px;

		.btn-text {
			color: $SP1100;
		}
	}
}
</style>
