<template>
	<section class="InvoicesStatus">
		<div class="InvoicesStatus__container">
			<template v-if="invoices.length">
				<div
					v-for="(invoice, index) in invoices"
					:key="index"
					class="InvoicesStatus__card"
					:class="'InvoicesStatus__card--' + invoice.cardName"
				>
					<h3
						v-if="invoice.category"
						class="InvoicesStatus__category mb-2"
					>
						{{ invoice.category }}
					</h3>
					<span class="InvoicesStatus__amount mb-2">${{ invoice.amount.toFixed(2) }}</span>
				</div>
			</template>
		</div>
	</section>
</template>

<script setup>
import { computed } from 'vue';
import { useScheduleStore } from '@/stores/schedule';
const scheduleStore = useScheduleStore();

const invoices = computed(() => [
	{
		cardName: 'uninvoiced-jobs',
		category: 'Total Un-Invoiced',
		amount: scheduleStore.dashboardAnalytics.monthUnInvoicedJobs?.amount || 0,
	},
	{
		cardName: 'unpaid-invoices',
		category: 'Total Unpaid Invoices',
		amount: scheduleStore.dashboardAnalytics.monthUnPaidInvoices?.amount || 0,
	},

	{
		cardName: 'paid-invoices',
		category: 'Total Paid Invoices',
		amount: scheduleStore.dashboardAnalytics.monthPaidInvoices?.amount || 0,
	},

	{
		cardName: 'revenue',
		category: 'Total Revenue',
		amount: scheduleStore.dashboardAnalytics.monthRevenue?.amount || 0,
	},
]);
</script>

<script>
export default {
	name: 'InvoicesStatus',
};
</script>

<style lang="scss">
@import 'styles';

.InvoicesStatus {
	&__container {
		display: flex;
		flex-direction: row;
		align-items: center;
		overflow-x: scroll;
		gap: 38px;
		padding: 10px 30px 10px 30px;

		&::-webkit-scrollbar {
			display: none;
		}
		.InvoicesStatus__card {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}

	&__category,
	&__duration,
	&__amount,
	&__description {
		font-family: Raleway, sans-serif;
		// font-weight:  ;
	}

	&__duration,
	&__description {
		color: $SP400;
	}

	&__category {
		font-size: 20px;
		line-height: 31px;
	}

	&__duration {
		font-size: 15px;
		line-height: 18px;
	}

	&__amount {
		display: block;
		font-weight: 900;
		font-size: 45px;
		line-height: 62px;
	}

	&__description {
		max-width: 166px;
		margin: 0 auto;
		font-size: 19px;
		line-height: 22px;
	}

	&__card {
		background: $SP1100;
		box-shadow: 0 3px 6px $SP1700;
		border-radius: 56px;
		width: 250px;
		min-width: 250px;
		height: 180px;

		&--uninvoiced-jobs {
			.InvoicesStatus__category,
			.InvoicesStatus__amount {
				color: red;
			}
			.InvoicesStatus__amount {
				text-shadow: 2px 0 red;
			}
		}
		&--unpaid-invoices {
			.InvoicesStatus__category,
			.InvoicesStatus__amount {
				color: $SP500;
			}
			.InvoicesStatus__amount {
				text-shadow: 2px 0 $SP500;
			}
		}

		&--paid-invoices {
			.InvoicesStatus__category,
			.InvoicesStatus__amount {
				color: $SP800;
			}
			.InvoicesStatus__amount {
				text-shadow: 2px 0 $SP800;
			}
		}

		&--revenue {
			.InvoicesStatus__category,
			.InvoicesStatus__amount {
				color: $SP700;
			}
			.InvoicesStatus__amount {
				text-shadow: 2px 0 $SP700;
			}
		}
	}
}
</style>
