<template>
	<v-dialog
		v-model="rescheduleServiceModalVisible"
		class="reschedule-service-modal text-center"
	>
		<v-card class="rounded-xl">
			<v-card-title class="card-title mt-5"> Reschedule the Yard </v-card-title>
			<v-form ref="form">
				<v-card-text>
					<div class="reschedule-details mt-1">
						<div class="address mb-7">
							{{ reschedulePropertySelected.addressLineOne }}<br />
							{{ reschedulePropertySelected.city }}, {{ reschedulePropertySelected.state }}
						</div>
						<div class="field mb-7">
							<div class="label font-weight-bold">Current Mow Date</div>
							{{ dateFormat(reschedulePropertySelected.nextMow, 'dddd MM/DD/YYYY') }}
						</div>
						<div
							data-testid="last-mow-text"
							class="field mb-7"
						>
							<div class="label font-weight-bold">Lasted Mowed</div>
							{{
								reschedulePropertySelected.lastMowed === '0001-01-01T00:00:00' ||
								reschedulePropertySelected.lastMowed == null
									? 'First Mow'
									: dateFormat(reschedulePropertySelected.lastMowed, 'dddd MM/DD/YYYY')
							}}
						</div>
						<div class="field">
							<div class="label font-weight-bold">Select New Mow Date</div>
							
							<VDatePicker
								v-model="rescheduleDate"
								class="w-full"
							>
								<template #default="{ togglePopover, inputValue, inputEvents }">
									<div class="">
										<button
											class="w-100 code__input rounded-pill mar-t-6"
											@click="() => togglePopover()"
										>
											<v-text-field
												clearable
												variant="plain"
												readonly
												:value="inputValue"
												class="w-100 flex-grow px-2"
												v-on="inputEvents"
											/>
										</button>
									</div>
								</template>
							</VDatePicker>
						</div>
						<div class="field">
							<h3 class="reason mt-9 mb-3">Reschedule Reason/Details</h3>
							<v-textarea
								v-model="rescheduleReason"
								variant="plain"
								placeholder="Enter reason for rescheduling"
								class="rounded-xl reason-input"
								clearable
								:rules="textareaRules"
								required
							></v-textarea>
						</div>
					</div>
				</v-card-text>

				<v-card-actions class="flex-column justify-center">
					<v-btn
						class="rounded-pill schedule_button submit-button text text-capitalize"
						variant="flat"
						width="80%"
						height="48"
						@click="rescheduleJob()"
					>
						<span>Submit</span>
					</v-btn>
					<v-btn
						class="mb-7 rounded-pill schedule_button close-button text text-capitalize"
						variant="flat"
						width="80%"
						height="48"
						@click="rescheduleServiceModalVisible = false"
					>
						<span>Close Dialog</span>
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>
<script setup>
import { ref, watch, defineProps } from 'vue';
import { useScheduleStore } from '@/stores/schedule';
import { storeToRefs } from 'pinia';
import { dateFormat } from '@/utils/dateFormatter.js';

const scheduleStore = useScheduleStore();
const { rescheduleServiceModalVisible, reschedulePropertySelected, sevenDayArray } = storeToRefs(scheduleStore);
const emit = defineEmits(['setSevenDayArray']);
const form = ref(null);
const props = defineProps({
	propertyToReschedule: {
		type: String,
		required: true,
		default: '',
	},
});

const rescheduleReason = ref('');
const rescheduleDate = ref(new Date());

const rescheduleJob = async () => {
	const isValid = await form.value.validate();
	if (isValid.valid) {
		const payload = [];
		payload.push({
			propertyId: reschedulePropertySelected.value.propertyId,
			RescheduledToDate: dateFormat(rescheduleDate.value, 'MM/DD/YYYY'),
			Reason: rescheduleReason.value,
		});

		await scheduleStore.rescheduleWholeDay(payload).then(() => {
			emit('setSevenDayArray');
			rescheduleServiceModalVisible.value = false;
		});
	}
};

watch(rescheduleServiceModalVisible, () => {
	if (props.propertyToReschedule.length !== 0) {
		reschedulePropertySelected.value = sevenDayArray.value.filter(
			(item) => item.propertyId == props.propertyToReschedule,
		)[0];
	}

	if (!rescheduleServiceModalVisible.value) {
		rescheduleDate.value = new Date();
		rescheduleReason.value = '';
	}
});
</script>
<style lang="scss" scoped>
@import '../../styles.scss';
.reschedule-service-modal {
	font-family: 'Raleway', sans-serif;
	color: $SP400;

	.address {
		line-height: 1.2;
		color: $SP400;
	}

	.field {
		color: $SP400;

		.label {
			font-size: 17px;
		}

		.input {
			background-color: $SP1600;
		}

		.reason {
			font-size: 20px;
		}
		.reason-input {
			padding: 0rem 1rem;
			background-color: $SP600;
		}
	}
}
.calendar-icon {
	position: relative;
	cursor: pointer;

	.calendar {
		position: absolute;
		right: 0;
		top: 100%;
		z-index: 10;
	}
}

.submit-button {
	background: $SP500 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px $SP1700;
	text-align: left;
	font: normal normal bold 22px/26px Raleway;
	letter-spacing: 0px;
	color: $SP1100;
	opacity: 1;
	margin-top: 18px;
	padding: 11px 25px;
	width: 134px;
}

.close-button {
	background: $SP900 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px $SP1700;
	text-align: left;
	font: normal normal bold 22px/26px Raleway;
	letter-spacing: 0px;
	color: $SP1100;
	opacity: 1;
	margin-top: 18px;
	padding: 11px 25px;
	width: 134px;
}
</style>
