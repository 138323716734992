<template>
	<v-dialog
		v-model="forgotPassIsDisplayed"
		class="reset-password-dialog"
		fullscreen
		data-testid="forgot-password"
	>
		<v-card class="reset-password-dialog__card">
			<i
				class="back-arrow material-icons"
				@click="forgotPassIsDisplayed = false"
				>arrow_back_ios</i
			>
			<v-card-title class="reset-password-dialog__title"> Reset Password </v-card-title>
			<v-card-text class="reset-password-dialog__text">
				Forgot your password? No problem! Enter your email below and check your email messages for a reset password link from
				us.
			</v-card-text>
			<v-form>
				<v-row>
					<v-col
						cols="12"
						md="4"
					>
						<v-text-field
							v-model="email"
							clearable
							hide-details="auto"
							variant="plain"
							class="rounded-pill input"
							:rules="rules.emailRules"
							required
							data-testid="email-input"
						/>
					</v-col>
				</v-row>
				<v-btn
					data-testid="submit-button"
					@click="requestReset"
				>
					Submit
				</v-btn>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script setup>
import axios from 'axios';
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

const API_URL = process.env.VUE_APP_API_URL;

const authStore = useAuthStore();
const { forgotPassIsDisplayed, emailDialogDisplayed } = storeToRefs(authStore);

const email = ref('');
const rules = ref({
	emailRules: [
		(v) => Boolean(v) || 'E-mail is required',
		(v) => {
			const pattern = new RegExp(
				'^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|' +
					'(".+"))@' +
					'((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|' +
					'(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))',
			);
			return pattern.test(v) || 'E-mail must be valid';
		},
	],
});

async function requestReset() {
	if (email.value === '') {
		alert('Please provide an email');
		return;
	}

	const response = await axios.get(`${API_URL}/Auth/ForgotPassword?email=${email.value}`);

	if (response.status === 200) {
		forgotPassIsDisplayed.value = false;
		emailDialogDisplayed.value = true;
		return;
	}

	forgotPassIsDisplayed.value = false;
	emailDialogDisplayed.value = true;
	alert('Reset password could not be sent. Please contact support.');
}
</script>

<style lang="scss" scoped>
@import 'styles';

.back-arrow {
	font-size: 2.5rem !important;
	color: $SP400;
	cursor: pointer;
}

.reset-password-dialog {
	height: 100vh;
	font-family: 'Raleway', sans-serif;
	&__card {
		display: block !important;
		padding: 2.5rem 1.5rem 1rem 2rem;
		height: 100%;
	}

	&__title {
		margin-top: 20px;
		color: $SP400;
		font-size: 30px;
		text-align: center;
		display: block;
		padding-bottom: 180px;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			top: 55px;
			left: 50%;
			transform: translateX(-50%);
			width: 128px;
			height: 115px;
			background: $lock-open no-repeat center/contain;
			margin-top: 1rem;
		}
	}
	.input {
		padding: 5px;
		background-color: $SP600;
		&:deep(.v-field__input) {
			padding: 0 10px;
		}
	}

	&__link {
		display: block;
		margin: 20px 0;
		color: $SP200;
		font-size: 17px !important;
	}

	button {
		width: 100%;
		background-color: $SP500;
		color: $SP1600;
		display: flex;
		justify-content: center;
		align-content: center;
		padding: 33px 0;
		font-size: 33px !important;
		text-transform: capitalize;
		border-radius: 26px;
	}

	&__text {
		color: $SP400;
		border-radius: 22px;
		font-size: 21px !important;
		line-height: 38px !important;
		margin-bottom: 40px;
		font-weight: 300 !important;
		padding: 0 !important;
	}
}
</style>
