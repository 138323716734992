import { defineStore } from 'pinia';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

const API_URL = process.env.VUE_APP_API_URL;

export const useCompanyStore = defineStore('company', {
	state: () => ({
		invoices: [],
	}),
	getters: {
		firstName() {
			return this.fullName.split(' ')[0];
		},
		lastName() {
			return this.fullName.split(' ')[1];
		},
		fullName() {
			return localStorage.getItem('unique_name');
		},
		email() {
			return localStorage.getItem('email');
		},
		companyName() {
			return localStorage.getItem('companyName');
		},
		websiteName() {
			return localStorage.getItem('website');
		},
		phone() {
			return localStorage.getItem('phone');
		},
		addressLineOne() {
			return localStorage.getItem('addressLineOne');
		},
		addressLineTwo() {
			return localStorage.getItem('addressLineTwo');
		},
		city() {
			return localStorage.getItem('city');
		},
		state() {
			return localStorage.getItem('state');
		},
		zip() {
			return localStorage.getItem('zip');
		},
		businessType() {
			return localStorage.getItem('businessType');
		},
		userId() {
			return localStorage.getItem('userId');
		},
		lcpDetailsId() {
			return localStorage.getItem('lcpDetailsId');
		},
		ein() {
			return localStorage.getItem('ein');
		},
		getInvoices(state) {
			return state.invoices;
		},
	},
	actions: {
		updateCompanyInfo(formData) {
			const token = localStorage.getItem('token');

			axios.defaults.headers.common = {
				Authorization: `bearer ${token}`,
			};

			axios
				.post(`${API_URL}/Lcp/UpdateAccountInfo`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data;charset=utf-8;',
						'cache-control': 'private',
					},
				})
				.then((resp) => {
					if (resp.data.token) {
						const decoded = jwt_decode(resp.data.token);
						localStorage.setItem('token', resp.data.token);
						Object.entries(decoded).forEach(([key, value]) => {
							localStorage.setItem(key, value);
						});
					}
				})
				.catch((error) => {
					console.error(error);
				});
		},
		fetchInvoices() {
			const token = localStorage.getItem('token');

			axios.defaults.headers.common = {
				Authorization: `bearer ${token}`,
			};

			axios
				.get(`${API_URL}/Lcp/GetInvoices`, {
					headers: {
						'Content-Type': 'multipart/form-data;charset=utf-8;',
						'cache-control': 'private',
					},
				})
				.then((resp) => {
					if (resp.data.invoices) {
						this.invoices = resp.data.invoices;
					}
				})
				.catch((error) => {
					console.error(error);
				});
		},
		async fetchCompanyInfo() {
			const token = localStorage.getItem('token');
			axios.defaults.headers.common = {
				Authorization: `Bearer ${token}`,
			};
			axios
				.get(`${API_URL}/Lcp/GetUser?LLCPUserId=${this.userId}`, {
					headers: {
						'Content-Type': 'multipart/form-data;charset=utf-8;',
						'cache-control': 'private',
					},
				})
				.then((resp) => {
					if (resp.data) {
						localStorage.setItem('companyName', resp.data.companyName);
						localStorage.setItem('website', resp.data.website);
						localStorage.setItem('addressLineOne', resp.data.addressLineOne);
						localStorage.setItem('addressLineTwo', resp.data.addressLineTwo);
						localStorage.setItem('zip', resp.data.zip);
						localStorage.setItem('state', resp.data.state);
						localStorage.setItem('city', resp.data.city);
						localStorage.setItem('businessType', resp.data.businessType);
						localStorage.setItem('lcpDetailsId', resp.data.lcpDetailsId);
					}
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},
});
