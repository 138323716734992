<template>
	<v-dialog v-model="submitMessageDialogDisplayed" activator="child" transition="dialog-bottom-transition">
		<v-card class="success-Card py-8 px-4">
			<h2 class="success-Card-Title">
				{{ submitMessageTitle }}
			</h2>
			<span class="success-Card-subTitle">{{ submitMessage }}</span>
			<v-btn class="success-Card-Close" href="#contact-section" @click="closeDialog()">
				<span class="btn-text">OK</span>
			</v-btn>
		</v-card>
	</v-dialog>
</template>

<script setup>
import { useApplicationStore } from '../stores/application';
import { storeToRefs } from 'pinia';
const applicationStore = useApplicationStore();
const { submitMessageDialogDisplayed, submitMessageTitle, submitMessage } = storeToRefs(applicationStore);

const closeDialog = () => {
	submitMessageDialogDisplayed.value = false;
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap');

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
	transition: transform 0.2s ease-in-out;
}

success-Card {
	border-radius: 50px !important;
	padding: 70px 42px 55px 45px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.success-Card-Title {
		margin-bottom: 12px;
		text-align: center;
		font: normal normal 600 27px/31px Raleway;
		letter-spacing: 0px;
		color: $SP400;
		opacity: 1;
	}

	.success-Card-Image {
		margin-bottom: 28px;
		width: 255px;
		height: 255px;
	}

	.success-Card-subTitle {
		margin-bottom: 58px;
		text-align: center;
		font: normal normal normal 21px/38px Raleway;
		letter-spacing: 0px;
		color: $SP400;
		opacity: 1;
	}

	.success-Card-Close,
	.success-Card-No {
		width: 229px;
		height: 55px;
		background: $SP800 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px $SP1700;
		border-radius: 28px;
		opacity: 1;

		span {
			text-align: center;
			font: normal normal bold 23px/27px Raleway;
			letter-spacing: 0px;
			color: $SP800;
			opacity: 1;
		}

		.btn-text {
			color: $SP1100;
			z-index: 10;
		}
	}
	.success-Card-No {
		background: $SP500 0% 0% no-repeat padding-box;
		span {
			color: $SP500;
		}
	}
}
</style>
