<template>
	<span class="services-title text-center font-weight-light text-h6"
		>Seamlessly integrate Quickbooks, or Square into ScheduPay.
	</span>
	<v-radio-group v-model="radios">
		<v-radio
			value="quickbook"
			class="mb-6 label-wrap"
		>
			<template #label>
				<div class="d-flex justify-start h-75 w-100">
					<img
						class="w-100"
						src="../assets/Logos/Intuit_QuickBooks_Logo.svg"
						alt="Quickbook"
					/>
				</div>
			</template>
		</v-radio>
		<v-radio
			value="square"
			class="mb-6"
		>
			<template #label>
				<div class="d-flex justify-start h-75 w-100">
					<img
						class="w-100"
						src="../assets/Logos/Square_Service_Logo.svg"
						alt="Square"
					/>
				</div>
			</template>
		</v-radio>
		<v-radio value="no">
			<template #label>
				<span class="option w-100 text-center">Neither</span>
			</template>
		</v-radio>
	</v-radio-group>
</template>

<script setup>
import { ref } from 'vue';
const radios = ref('quickbook');
</script>

<style lang="scss" scoped>
@import '../../styles.scss';

.services-title,
.option {
	color: $SP400;
	font-family: 'Raleway', sans-serif !important;
}

:deep(.v-label) {
	opacity: 1 !important;
}
</style>
