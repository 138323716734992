<template>
	<v-dialog
		v-model="invalidCredentialsDialogDisplayed"
		transition="dialog-bottom-transition"
	>
		<v-card class="invalid-credentials-card">
			<h2 class="invalid-credentials-card-title">Login Failed</h2>
			<span class="invalid-credentials-card-subtitle">
				Please check your email and password. More than likely, you have entered the email or password incorrectly. If you
				think you forgot your password, try resetting your password.
			</span>
			<v-btn
				class="invalid-credentials-card-close"
				@click="closeVerificationDialog"
			>
				<span>OK</span>
			</v-btn>
		</v-card>
	</v-dialog>
</template>

<script setup>
import { useAuthStore } from '../stores/auth';
import { storeToRefs } from 'pinia';

const authStore = useAuthStore();
const { invalidCredentialsDialogDisplayed } = storeToRefs(authStore);

const closeVerificationDialog = () => {
	invalidCredentialsDialogDisplayed.value = false;
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap');

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
	transition: transform 0.2s ease-in-out;
}

.invalid-credentials-card {
	border-radius: 50px !important;
	padding: 70px 42px 55px 45px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	.invalid-credentials-card-title {
		margin-bottom: 12px;
		text-align: center;
		font: normal normal 600 27px/31px Raleway;
		letter-spacing: 0px;
		color: $SP400;
		opacity: 1;
	}

	.invalid-credentials-card-subtitle {
		margin-bottom: 25px;
		font: normal normal normal 21px/38px Raleway;
		letter-spacing: 0px;
		color: $SP400;
		opacity: 1;
	}

	.invalid-credentials-card-close {
		width: 229px;
		height: 55px;
		background: $SP800 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px $SP1700;
		border-radius: 28px;
		opacity: 1;

		span {
			text-align: center;
			font: normal normal bold 23px/27px Raleway;
			letter-spacing: 0px;
			color: $SP1100;
			opacity: 1;
		}
	}
}
</style>
