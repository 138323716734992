export const colors = {
	$SP100: '#C4F1F2',
	$SP200: '#5CB2B5',
	$SP300: '#7CEEF2',
	$SP400: '#5D7273',
	$SP500: '#D6590B',
	$SP600: '#DBDBDB',
	$SP700: '#5C93B5',
	$SP800: '#8DCC86',
	$SP900: '#303C3D',
	$SP1100: '#FBFBFB',
	$SP1200: '#1A77F2',
	$SP1300: '#707070',
};
