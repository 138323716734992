<template>
	<v-app
		class="overflow-y-hidden"
		data-testid="app"
	>
		<div class="loader color-primary">
			<v-progress-linear
				:active="active"
				:height="10"
				indeterminate
				data-testid="progress-bar"
			/>
		</div>
		<template v-if="routerReady">
			<TopToolbar
				v-if="!$route.meta.hideNavigation"
				:title="$route.meta.pageTitle"
				data-testid="top-tool-bar"
			/>
			<ToggleNavDialog
				v-if="!$route.meta.hideNavigation"
				data-testid="toggle-nav-dialog"
			/>
			<v-main
				class="mb-15"
				data-testid="main"
			>
				<router-view />
			</v-main>
			<MainNav
				v-if="!$route.meta.hideNavigation"
				data-testid="main-nav"
			/>
		</template>
	</v-app>
</template>

<script setup>
import MainNav from '@/components/MainNav.vue';
import ToggleNavDialog from './components/ToggleNavDialog.vue';
import TopToolbar from './components/TopToolbar.vue';
import { useLoaderStore } from '@/stores/loader';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { ref } from 'vue';

const loaderStore = useLoaderStore();
const router = useRouter();

const routerReady = ref(false);
(async () => {
	await router.isReady();
	routerReady.value = true;
})();

const { active } = storeToRefs(loaderStore);
</script>
<style lang="scss">
@import './styles.scss';
.loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}

/* Normalize the Auto-fill to prevent background overwrite */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-background-clip: text;
}

input:-webkit-autofill {
	-webkit-text-fill-color: $SP400 !important;
}
</style>
