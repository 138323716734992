<template>
	<p class="jobs-text my-6 mx-auto">You have {{ unresolvedJobs.length }} jobs that have no actions</p>
	<v-card class="table__card">
		<v-table>
			<tbody class="table__body">
				<tr
					v-for="(item, index) in unresolvedJobs"
					:key="index"
				>
					<div class="jobs__list__main pa-1 px-5 d-flex align-center justify-space-between">
						<div class="left">
							<div class="client__name mb-1">{{ item.firstName }} {{ item.lastName }}</div>
							<div class="client__address mb-1">{{ item.addressLineOne }}</div>
							<div class="client__paymeny__status d-flex justify-flex-start mb-2">
								<div
									class="account-status"
									:class="item.accountStatus"
								>
									{{ item.accountStatus ? item.accountStatus : 'Please set account status' }}
								</div>
								<div
									class="ml-10"
									:class="item.unpaidInvoices > 0 || item.amountOfUnInvoicedJobs > 0 ? 'Unpaid' : 'Paid'"
								>
									{{ item.unpaidInvoices > 0 || item.amountOfUnInvoicedJobs > 0 ? 'Unpaid' : 'Paid' }}
								</div>
							</div>
						</div>
						<div class="right">
							<v-btn
								class="resolve-btn"
								variant="outlined"
								rounded="xl"
								@click="
									serviceConfirmDialogOpen = true;
									selectedPropertyId = item.propertyId;
								"
								>Resolve</v-btn
							>
						</div>
					</div>
				</tr>
			</tbody>
		</v-table>
	</v-card>
	<QuestionDialog
		v-model="serviceConfirmDialogOpen"
		:confirm-action="openDoneMowingSingleYardDialog"
		:reject-action="openRescheduleJobConfirmDialog"
		:question="serviceQuestionText"
	/>
	<QuestionDialog
		v-model="rescheduleJobConfirmDialogOpen"
		:reject-action="openPropertyStatusDialog"
		:confirm-action="openRescheduleJobDialog"
		:question="rescheduleQuestionText"
	/>
	<QuestionDialog
		v-model="propertyStatusDialogOpen"
		:reject-action="propertyStatusReschedule"
		:confirm-action="propertyStatusConfirm"
		:question="propertyStatusQuestionText"
		:confirm-btn-text="'confirm'"
		:reject-btn-text="'Reschedule'"
		:is-it-ok-text="'Is this ok?'"
	/>
	<RescheduleSingleYardDialog
		:property-to-reschedule="selectedPropertyId"
		@set-seven-day-array="fetchsevenDayArray"
	/>
	<DoneMowingSingleJobDialog
		:resolving-job="true"
		@set-seven-day-array="fetchsevenDayArray"
		@edit-additional-fee="editAdditionalFee"
	/>
	<AddFeeDialog
		:fee-amount="additionalFeeAmount"
		:fee-title="additionalFeeTitle"
		:fee-description="additionalFeeDescription"
	/>
	<v-dialog
		v-model="showPropertyUpdatedDialog"
		transition="dialog-bottom-transition"
	>
		<v-card class="success-Card py-8 px-4">
			<h2 class="success-Card-Title">
				{{ propertyUpdateSuccess ? `Success` : `Error` }}
			</h2>
			<span
				v-if="propertyUpdateSuccess"
				class="success-Card-subTitle"
			>
				Property marked as Inactive Successfully.
			</span>
			<span
				v-else
				class="success-Card-subTitle"
			>
				Unable to update Property. Please Try again later.
			</span>
			<v-btn
				class="success-Card-Close"
				href="#contact-section"
				@click="showPropertyUpdatedDialog = false"
			>
				<span class="btn-text">OK</span>
			</v-btn>
		</v-card>
	</v-dialog>
</template>

<script setup>
import DoneMowingSingleJobDialog from '@/components/DoneMowingSingleJobDialog.vue';
import AddFeeDialog from '@/components/AddFeeDialog.vue';
import { onMounted, ref, computed, toRaw } from 'vue';
import { storeToRefs } from 'pinia';
import { useScheduleStore } from '@/stores/schedule';
import QuestionDialog from '@/components/QuestionDialog.vue';
import RescheduleSingleYardDialog from '@/components/RescheduleSingleYardDialog.vue';
import { useRouter } from 'vue-router';
import { dateFormat } from '@/utils/dateFormatter';
const sevenDayArrayData = computed(() => scheduleStore.getSevenDayArray);
const unresolvedJobs = ref([]);
const serviceConfirmDialogOpen = ref(false);
const rescheduleJobConfirmDialogOpen = ref(false);
const propertyStatusDialogOpen = ref(false);
const serviceQuestionText = 'Did you service this property?';
const rescheduleQuestionText = 'Do you want to reschedule the job?';
const propertyStatusQuestionText =
	'You have chosen not to reschedule this job. Are you wanting to set this property to inactive?';

const scheduleStore = useScheduleStore();
const { doneMowingSingleYardDialog, selectedPropertyId, showAddFeeDialog, editingFee, rescheduleServiceModalVisible } =
	storeToRefs(scheduleStore);
const router = useRouter();
const showPropertyUpdatedDialog = ref(false);
const propertyUpdateSuccess = ref(false);
onMounted(async () => {
	fetchsevenDayArray();
});
const fetchsevenDayArray = async () => {
	unresolvedJobs.value = [];
	await scheduleStore.fetchSevenDaysSchedule();
	for (let i = 0; i < sevenDayArrayData.value.length; i++) {
		const jobsScheduledDate = dateFormat(new Date(sevenDayArrayData.value[i].nextMow).getTime(), 'YYYY-MM-DD');
		if (
			new Date(jobsScheduledDate) < new Date(dateFormat(new Date(), 'YYYY-MM-DD')) &&
			sevenDayArrayData.value[i].mowingFrequency
		) {
			unresolvedJobs.value.push(sevenDayArrayData.value[i]);
		}
	}

	if (unresolvedJobs.value.length === 0) {
		router.replace('/schedule');
	}
};

const editAdditionalFee = (feeTobeEdited) => {
	editingFee.value = toRaw(feeTobeEdited);
	showAddFeeDialog.value = true;
};

const openDoneMowingSingleYardDialog = () => {
	serviceConfirmDialogOpen.value = false;
	doneMowingSingleYardDialog.value = true;
};

const openRescheduleJobConfirmDialog = () => {
	serviceConfirmDialogOpen.value = false;
	rescheduleJobConfirmDialogOpen.value = true;
};

const openRescheduleJobDialog = () => {
	rescheduleServiceModalVisible.value = true;
	rescheduleJobConfirmDialogOpen.value = false;
};

const propertyStatusConfirm = async () => {
	let selectedProperty = sevenDayArrayData.value.filter((data) => {
		return data.propertyId === selectedPropertyId.value;
	})[0];

	let payload = {
		...selectedProperty,
		mowingFrequency: selectedProperty?.mowingFrequency?.toString(),
		status: 'Inactive',
	};
	try {
		await scheduleStore.updateProperty(payload);
		propertyUpdateSuccess.value = true;
	} catch (error) {
		propertyUpdateSuccess.value = false;
	}
	fetchsevenDayArray();
	propertyStatusDialogOpen.value = false;
	showPropertyUpdatedDialog.value = true;
};

const propertyStatusReschedule = () => (propertyStatusDialogOpen.value = false);
const openPropertyStatusDialog = () => {
	propertyStatusDialogOpen.value = true;
	rescheduleJobConfirmDialogOpen.value = false;
};

const additionalFeeTitle = ref('');
const additionalFeeAmount = ref();
const additionalFeeDescription = ref('');
</script>

<style lang="scss">
@import '../../styles.scss';
.jobs-text {
	font-family: 'Raleway', sans-serif;
	color: $SP400;
	text-align: center;
}
.table__card {
	width: 90%;
	margin: auto;
	border: 1px solid $SP1800;
}

.jobs__list__main {
	border-bottom: 2px solid $SP2400;
}

.table__body > tr:nth-child(even) {
	background-color: $SP2500;
}

.table__body > tr:nth-child(odd) {
	background-color: $SP1100;
}
.table__body > tr {
	border-bottom: 2px solid $SP2400;
}
.client__name {
	color: $SP400;
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
	font-size: 20px;
}

.client__address {
	color: $SP2100;
	font-family: 'Raleway', sans-serif;
	font-weight: 300;
	font-size: 18px;
}

.client__paymeny__status {
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
	font-size: 18px;
}

.Active {
	color: $SP800;
}
.Inactive,
.Unpaid {
	color: $SP500;
}
.Paid {
	color: $SP2000;
}

.resolve-btn {
	border-color: $SP800 !important;
	color: $SP800 !important;
}
</style>
