<template>
	<v-toolbar
		v-if="!open"
		color="transparent"
		height="105"
		density="compact"
	>
		<template #prepend>
			<v-img
				src="/schedulepay.png"
				alt="SchedulePay"
				width="25"
				aspect-ratio="1/1"
			/>
		</template>
		<v-toolbar-title class="title-bar text-left">{{ props.title }}</v-toolbar-title>
		<v-btn
			icon="mdi-dots-vertical"
			:elevation="2"
			class="drawerButton mr-5"
			data-testid="top-nav-drawer-button"
			@click="dialogOpen"
		>
			<template v-if="!open">
				<VerticalDotsIcon />
			</template>
		</v-btn>
	</v-toolbar>
</template>

<script setup>
import VerticalDotsIcon from '@/assets/VerticalDotsIcon.vue';
import { defineProps, computed } from 'vue';
import { useNavStore } from '@/stores/nav-store';

const navStore = useNavStore();

const open = computed(() => navStore.topNavOpen);

const dialogOpen = () => {
	navStore.$patch({ topNavOpen: true });
};

const props = defineProps({
	title: {
		type: String,
		required: true,
		default: () => '',
	},
});
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
.title-bar {
	text-align: center;
	font: normal normal bold 30px/35px Raleway;
	letter-spacing: 0px;
	color: $SP400;
	opacity: 1;
}
.drawerButton {
	background-color: $SP1600;
	position: fixed;
	top: 1.3rem;
	right: 0;
	z-index: 1000;
}
.text-left {
  text-align: left;
  padding-left:1px;
}
</style>
