import ClientsView from '@/views/ClientsView.vue';
import ClientInfo from '@/views/ClientInfo.vue';
import DashboardView from '@/views/DashboardView.vue';
import LoginView from '@/views/LoginView.vue';
import InvoicesView from '@/views/InvoicesView.vue';
import JobDetails from '@/views/ServiceDetails.vue';
import AddClientView from '@/views/AddClientView.vue';
import ScheduleView from '@/views/ScheduleView.vue';
import PauseMowingView from '@/views/PauseMowingView.vue';
import StartMowingView from '@/views/StartMowingView.vue';
import DesktopView from '@/views/DesktopView.vue';
import StripeRefreshView from '@/views/StripeRefreshView.vue';
import CreateAccountView from '@/views/CreateAccountView.vue';
import ChatView from '@/views/ChatView.vue';
import SettingsView from '@/views/SettingsView.vue';
import { createRouter, createWebHistory } from 'vue-router';
import OpenBillDetailsView from '@/views/OpenBillDetailsView.vue';
import PropertyDetails from '@/views/PropertyDetails.vue';
import InvoiceDetailsView from '@/views/InvoiceDetailsView.vue';
import UnresolvedJobs from '@/views/UnresolvedJobs.vue';

import axios from 'axios';

import NotFound from '@/views/NotFound';
import { useLoaderStore } from '@/stores/loader';
import { storeToRefs } from 'pinia';

const API_URL = process.env.VUE_APP_API_URL;

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			name: 'home',
			component: DashboardView,
			meta: { requiredAuth: true, pageTitle: 'Home' },
		},
		{
			path: '/chat',
			name: 'chat',
			component: ChatView,
			meta: { requiredAuth: true, pageTitle: 'Client Chat' },
		},
		{
			path: '/settings',
			name: 'settings',
			component: SettingsView,
			meta: { requiredAuth: true, pageTitle: 'Settings' },
		},
		{
			path: '/pauseMowing',
			name: 'pauseMowing',
			component: PauseMowingView,
			meta: { requiredAuth: true, pageTitle: 'Pause Mowing' },
		},
		{
			path: '/clients',
			name: 'clients',
			component: ClientsView,
			meta: { requiredAuth: true, pageTitle: 'Clients' },
		},
		{
			path: '/clients/:id',
			name: 'clientsInfo',
			component: ClientInfo,
			meta: { requiredAuth: true, pageTitle: 'Client Info' },
		},
		{
			path: '/invoices',
			name: 'invoices',
			component: InvoicesView,
			meta: { requiredAuth: true, pageTitle: 'Invoices' },
		},
		{
			path: '/invoices/:id',
			name: 'invoiceDetails',
			component: InvoiceDetailsView,
			meta: { requiredAuth: true, pageTitle: 'Invoice Details' },
		},
		{
			path: '/services/:id',
			name: 'jobdetails',
			component: JobDetails,
			meta: { requiredAuth: true, pageTitle: 'Job Details' },
		},
		{
			path: '/addClient',
			name: 'addClient',
			component: AddClientView,
			meta: { requiredAuth: true, pageTitle: 'Add Client' },
		},
		{
			path: '/dashboard',
			name: 'dashboard',
			component: DashboardView,
			meta: { requiredAuth: true, pageTitle: 'Dashboard' },
		},
		{
			path: '/schedule',
			name: 'schedule',
			component: ScheduleView,
			meta: { requiredAuth: true, pageTitle: 'Schedule' },
		},
		{
			path: '/account',
			name: 'AccountView',
			// Route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/AccountView.vue'),
			meta: { requiredAuth: true, pageTitle: 'Account' },
		},
		{
			path: '/login',
			name: 'LoginView',
			component: LoginView,
			meta: { requiredAuth: true, pageTitle: 'Login', hideNavigation: true },
		},
		{
			path: '/create-account',
			name: 'CreateAccountView',
			component: CreateAccountView,
			meta: { requiredAuth: false, pageTitle: 'Create Account', hideNavigation: true },
		},
		{
			path: '/start-mowing',
			name: 'StartMowing',
			component: StartMowingView,
			meta: { requiredAuth: true, pageTitle: 'Re-Start Mowing' },
		},
		{
			path: '/openbills/:id',
			name: 'OpenBills',
			component: OpenBillDetailsView,
			meta: { requiredAuth: true, pageTitle: 'Open Bill Details' },
		},
		{
			path: '/propertyDetails/:id',
			name: 'PropertyDetails',
			component: PropertyDetails,
			meta: { requiredAuth: true, pageTitle: 'Property Details' },
		},
		{
			path: '/unresolvedJobs',
			name: 'Unresolved Jobs',
			component: UnresolvedJobs,
			meta: { requiredAuth: true, pageTitle: 'Unresolved Jobs' },
		},
		{
			name: '404',
			path: '/:pathMatch(.*)*',
			component: NotFound,
			meta: { hideNavigation: !localStorage.getItem('token') },
		},
		{
			path: '/desktop',
			name: 'DesktopView',
			component: DesktopView,
			meta: { requiredAuth: false, hideNavigation: true, pageTitle: 'Desktop' },
		},
		{
			path: '/refresh/:id',
			name: 'Refresh',
			component: StripeRefreshView,
			meta: { requiredAuth: false, hideNavigation: true, pageTitle: 'Stripe Refresh' },
		},
		{
			path: '/privacy-policy',
			name: 'PrivacyPolicy',
			component: () => import('../views/PrivacyPolicy.vue'),
			meta: { requiredAuth: false, hideNavigation: true, pageTitle: 'Privacy Policy' },
		}
	],
});
router.beforeResolve((to, from, next) => {
	const loaderStore = useLoaderStore();
	const { active } = storeToRefs(loaderStore);

	// If this isn't an initial page load.
	if (to.name) {
		// Start the route progress bar.
		active.value = true;
	}

	next();
});
router.onError(() => {
	const loaderStore = useLoaderStore();
	const { active } = storeToRefs(loaderStore);
	active.value = false;
});
router.beforeEach(async (to) => {
	window.scrollTo(0, 0);
	document.title = to.meta.pageTitle;
	// Removes the noindex meta in case it's present on route other than login

	if (to.path !== '/login') {
		const meta = document.createElement('meta');
		meta.content = 'noindex';
		meta.name = 'robots';
		const metaIsFound = document.querySelector("[name='robots']");
		if (metaIsFound === null) {
			document.head.appendChild(meta);
		}
	} else {
		const metaIsFound = document.querySelector("[name='robots']");
		if (metaIsFound !== null) {
			document.querySelector("[name='robots']").remove();
		}
	}

	const loaderStore = useLoaderStore();
	const { active } = storeToRefs(loaderStore);
	active.value = true;
	if (window.matchMedia('only screen and (max-width: 760px)').matches && to.fullPath === '/desktop') {
		return {
			path: '/login',
		};
	}

	if (!window.matchMedia('only screen and (max-width: 760px)').matches && to.fullPath !== '/desktop') {
		return {
			path: '/desktop',
		};
	}

	if (!to.meta.requiredAuth || to.fullPath === '/login' || to.fullPath === '/create-account') {
		return;
	}

	const token = localStorage.getItem('token');

	if (!token) {
		return {
			path: '/login',
		};
	}

	const exp = localStorage.getItem('exp');

	const dateTimeNow = Date.now();

	// Convert to seconds
	if (exp * 1000 < dateTimeNow) {
		return {
			path: '/login',
		};
	}

	await axios
		.get(`${API_URL}/Auth/RefreshToken`, {
			headers: {
				Authorization: `Bearer ${token}`,
				// 'User-Agent': 'ScheduPay Application',
			},
		})
		.then((res) => {
			localStorage.setItem('token', res.data.token);
		})
		.catch((err) => {
			console.error(err);

			localStorage.clear();
			return router.push({ path: '/login' });
		});
});

router.afterEach(() => {
	const loaderStore = useLoaderStore();
	const { active } = storeToRefs(loaderStore);
	active.value = false;
});

export default router;
