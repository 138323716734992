<template>
	<v-card
		id="contact-section"
		class="main-card"
	>
		<ContactPicker @change-selection="changeForm" />
		<v-title class="card-title mar-b-30"> {{ showManualForm ? 'Contact Details' : 'Send Invite' }} </v-title>
		<div v-if="showManualForm">
			<v-row>
				<v-col
					cols="12"
					md="4"
				>
					<h3>First Name</h3>
					<v-text-field
						v-model="firstName"
						class="code__input rounded-pill"
						clearable
						variant="plain"
						min="3"
						:error-messages="errors.fname"
						@blur="validateInput('firstName', firstName)"
						@input="validateInput('firstName', firstName)"
					/>
				</v-col>
				<v-col
					cols="12"
					md="4"
				>
					<h3 for="lastname">Last Name</h3>
					<v-text-field
						v-model="lastName"
						:error-messages="errors.lname"
						class="code__input rounded-pill"
						clearable
						variant="plain"
						@blur="validateInput('lname', lastName)"
						@input="validateInput('lname', lastName)"
					/>
				</v-col>
				<v-col
					cols="12"
					md="4"
				>
					<h3 for="email">Email</h3>
					<v-text-field
						v-model="email"
						:error-messages="errors.email"
						class="code__input rounded-pill"
						clearable
						variant="plain"
						@blur="validateInput('email', email)"
						@input="validateInput('email', email)"
					/>
				</v-col>
				<v-col
					cols="12"
					md="4"
					class="mar-b-30"
				>
					<h3 for="phone">Phone</h3>
					<v-text-field
						v-model="phone"
						:error-messages="errors.phone"
						class="code__input rounded-pill"
						clearable
						variant="plain"
						@blur="validateInput('phone', phone)"
						@input="validateInput('phone', phone)"
						v-mask="'###-###-####'"
						placeholder="123-456-7890"
					/>
				</v-col>
			</v-row>
			<v-title class="card-title mar-t-30 mar-b-30">Address Details</v-title>

			<v-row v-if="radios === 'yes'">
				<v-col
					cols="12"
					md="4"
				>
					<v-title class="card-title mar-b-30 text-center">Billing &amp; Service Address</v-title>
					<h3>Address Line One</h3>
					<v-text-field
						v-model="addressLineOne"
						class="code__input rounded-pill"
						clearable
						variant="plain"
						:error-messages="errors.address1"
						@blur="validateInput('address1', addressLineOne)"
						@input="validateInput('address1', addressLineOne)"
					/>
				</v-col>
				<v-col
					cols="12"
					md="4"
				>
					<h3>Address Line Two</h3>
					<v-text-field
						v-model="address2"
						class="code__input rounded-pill"
						clearable
						variant="plain"
						:error-messages="errors.address2"
						@blur="validateInput('address2', address2)"
						@input="validateInput('address2', address2)"
					/>
				</v-col>
				<v-col
					cols="12"
					md="4"
				>
					<h3>City</h3>
					<v-text-field
						v-model="city"
						class="code__input rounded-pill"
						clearable
						variant="plain"
						:error-messages="errors.city"
						@blur="validateInput('city', city)"
						@input="validateInput('city', city)"
					/>
				</v-col>
				<v-col
					cols="12"
					md="4"
				>
					<h3>State</h3>
					<v-autocomplete
						v-model="state"
						class="card__input rounded-pill"
						variant="plain"
						clearable
						required
						label="Select"
						:items="stateOptions"
						:error-messages="errors.state"
						@blur="validateInput('state', state)"
						@input="validateInput('state', state)"
					/>
				</v-col>
				<v-col
					cols="12"
					md="4"
					class="mar-b-30"
				>
					<h3>Zip</h3>
					<v-text-field
						v-model="zip"
						class="code__input rounded-pill"
						clearable
						variant="plain"
						:error-messages="errors.zip"
						@blur="validateInput('zip', zip)"
						@input="validateInput('zip', zip)"
					/>
				</v-col>
			</v-row>

			<v-row class="d-flex flex-column">
				<v-col>
					<span class="address-sub-title"> Is this billing and service address the same? </span>
					<v-radio-group v-model="radios">
						<v-radio
							label="Yes"
							value="yes"
						/>
						<v-radio
							label="No"
							value="no"
							@click="changeBillingOption()"
						/>
					</v-radio-group>
				</v-col>
			</v-row>

			<v-row v-if="radios === 'no'">
				<v-col
					cols="12"
					md="4"
				>
					<v-title class="card-title mar-b-30">Service Address</v-title>
					<h3>Address Line One</h3>
					<v-text-field
						v-model="serviceAddress1"
						class="code__input rounded-pill"
						clearable
						variant="plain"
						:error-messages="errors.serviceAddress1"
						@blur="validateInput('serviceAddress1', serviceAddress1)"
						@input="validateInput('serviceAddress1', serviceAddress1)"
					/>
				</v-col>
				<v-col
					cols="12"
					md="4"
				>
					<h3>Address Line Two</h3>
					<v-text-field
						v-model="serviceAddress2"
						class="code__input rounded-pill"
						clearable
						variant="plain"
						:error-messages="errors.serviceAddress2"
						@blur="validateInput('serviceAddress2', serviceAddress2)"
						@input="validateInput('serviceAddress2', serviceAddress2)"
					/>
				</v-col>
				<v-col
					cols="12"
					md="4"
				>
					<h3>City</h3>
					<v-text-field
						v-model="serviceCity"
						class="code__input rounded-pill"
						clearable
						variant="plain"
						:error-messages="errors.serviceCity"
						@blur="validateInput('serviceCity', serviceCity)"
						@input="validateInput('serviceCity', serviceCity)"
					/>
				</v-col>
				<v-col
					cols="12"
					md="4"
				>
					<h3>State</h3>

					<v-autocomplete
						v-model="serviceState"
						class="card__input rounded-pill"
						variant="plain"
						clearable
						required
						label="Select"
						:items="stateOptions"
						:error-messages="errors.serviceState"
						@blur="validateInput('serviceState', serviceState)"
						@input="validateInput('serviceState', serviceState)"
					/>
				</v-col>
				<v-col
					cols="12"
					md="4"
					class="mar-b-30"
				>
					<h3>Zip</h3>
					<v-text-field
						v-model="serviceZip"
						class="code__input rounded-pill"
						clearable
						variant="plain"
						:error-messages="errors.serviceZip"
						@blur="validateInput('serviceZip', serviceZip)"
						@input="validateInput('serviceZip', serviceZip)"
					/>
				</v-col>

				<v-col
					cols="12"
					md="4"
				>
					<v-title class="card-title mar-b-30">Billing Address</v-title>
					<h3>Address Line One</h3>
					<v-text-field
						v-model="addressLineOne"
						class="code__input rounded-pill"
						clearable
						variant="plain"
						:error-messages="errors.address1"
						@blur="validateInput('address1', addressLineOne)"
						@input="validateInput('address1', addressLineOne)"
					/>
				</v-col>
				<v-col
					cols="12"
					md="4"
				>
					<h3>Address Line Two</h3>
					<v-text-field
						v-model="address2"
						class="code__input rounded-pill"
						clearable
						variant="plain"
						:error-messages="errors.address2"
						@blur="validateInput('address2', address2)"
						@input="validateInput('address2', address2)"
					/>
				</v-col>
				<v-col
					cols="12"
					md="4"
				>
					<h3>City</h3>
					<v-text-field
						v-model="city"
						class="code__input rounded-pill"
						clearable
						variant="plain"
						:error-messages="errors.city"
						@blur="validateInput('city', city)"
						@input="validateInput('city', city)"
					/>
				</v-col>
				<v-col
					cols="12"
					md="4"
				>
					<h3>State</h3>
					<v-autocomplete
						v-model="state"
						class="card__input rounded-pill"
						variant="plain"
						clearable
						required
						label="Select"
						:items="stateOptions"
						:error-messages="errors.state"
						@blur="validateInput('state', state)"
						@input="validateInput('state', state)"
					/>
				</v-col>
				<v-col
					cols="12"
					md="4"
					class="mar-b-30"
				>
					<h3>Zip</h3>
					<v-text-field
						v-model="zip"
						class="code__input rounded-pill"
						clearable
						variant="plain"
						:error-messages="errors.zip"
						@blur="validateInput('zip', zip)"
						@input="validateInput('zip', zip)"
					/>
				</v-col>
			</v-row>
		</div>
		<v-row>
			<ClientInvite
				v-if="!showManualForm"
				@set-err="setErrors"
			/>
			<h3
				v-else
				class="service-details-title card-title mar-t-30 mar-b-30"
			>
				Service Details
			</h3>
			<v-col
				cols="12"
				md="4"
			>
				<h3>Mowing Rate</h3>
				<v-text-field
					v-model="mowingdRate"
					class="code__input rounded-pill mar-t-6"
					clearable
					variant="plain"
					:error-messages="errors.mowingdRate"
					@blur="validateInput('mowingdRate', mowingdRate)"
					@input="validateInput('mowingdRate', mowingdRate)"
				/>
			</v-col>
			<v-col
				cols="12"
				md="4"
			>
				<h3>Mowing Frequency (days)</h3>
				<v-select
					v-model="mowingdFreq"
					class="card__input rounded-pill"
					variant="plain"
					clearable
					required
					label="Select"
					:items="[
						{ state: '1', abbr: 1 },
						{ state: '7 (week)', abbr: 7 },
						{ state: '10', abbr: 10 },
						{ state: '14 (bi-weekly)', abbr: 14 },
					]"
					item-title="state"
					item-value="abbr"
					:error-messages="errors.mowingdFreq"
					@blur="validateInput('mowingdFreq', mowingdFreq)"
					@input="validateInput('mowingdFreq', mowingdFreq)"
				/>
			</v-col>
			<v-col
				cols="12"
				md="4"
				class="mar-b-5"
			>
				<h3 class="mar-t-30 mar-b-11">Service Start Date</h3>
				<span class="service-sub-title">
					If this isn't a new client, put the next date that the property will be mowed.
				</span>
				<VDatePicker
					v-model="serviceDate"
					class="w-full"
				>
					<template #default="{ togglePopover, inputValue, inputEvents }">
						<div class="">
							<button
								class="w-100 code__input rounded-pill mar-t-6"
								@click="() => togglePopover()"
							>
								<v-text-field
									clearable
									variant="plain"
									readonly
									:value="inputValue"
									class="w-100 flex-grow px-2"
									v-on="inputEvents"
								/>
							</button>
						</div>
					</template>
				</VDatePicker>
			</v-col>
			<v-col
				v-if="showManualForm"
				cols="12"
				md="4"
				data-testid="invoicing-period"
			>
				<h3>Invoicing Period</h3>
				<span class="service-sub-title text-center mb-4">
					Choose the frequency of when you would like to invoice your customers.</span
				>
				<v-select
					v-model="invoicingPeriod"
					class="card__input rounded-pill"
					variant="plain"
					clearable
					required
					label="Select"
					:items="[
						{ state: 'Time Of Service', abbr: 0 },
						{ state: 'Monthly', abbr: 1 },
						{ state: '60 days', abbr: 2 },
						{ state: '90 days', abbr: 3 },
					]"
					item-title="state"
					item-value="abbr"
					:error-messages="errors.invoicingPeriod"
					@blur="validateInput('invoicingPeriod', invoicingPeriod)"
					@input="validateInput('invoicingPeriod', invoicingPeriod)"
				/>
			</v-col>
		</v-row>
	</v-card>
</template>

<script setup>
import { ref, watch, onMounted, defineEmits, defineProps } from 'vue';
import ClientInvite from '@/components/ClientInvite.vue';
import ContactPicker from '@/components/ContactPicker.vue';
import { useApplicationStore } from '@/stores/application';
import { dateFormat } from '@/utils/dateFormatter.js';
import { storeToRefs } from 'pinia';
import { useInviteClientStore } from '@/stores/invite-client-store';
const clientInviteStore = useInviteClientStore();
const { email, phone, lastName, firstName, addressLineOne, zip, city, state, showManualForm } =
	storeToRefs(clientInviteStore);

const emit = defineEmits(['input-values-updated']);

const props = defineProps({
	resetForm: Boolean,
});
watch(
	() => props.resetForm,
	() => {
		if (props.resetForm) {
			resetFields();
		}
	},
	{ deep: true },
);

const errors = ref({});
const address2 = ref('');
const mowingdRate = ref('');
const mowingdFreq = ref('');
const invoicingPeriod = ref('');
const serviceDate = ref(new Date());
const radios = ref('yes');
const stateOptions = ref([]);
const serviceAddress1 = ref('');
const serviceAddress2 = ref('');
const serviceCity = ref('');
const serviceState = ref('');
const serviceZip = ref('');

watch(serviceDate, (newDate) => {
	validateInput('serviceDate', newDate);
});

watch(phone, (newValue) => {
	phone.value = formatUSPhoneNumber(newValue);
});

function formatUSPhoneNumber(number) {
	if (number) {
		let digits = number.replace(/\D/g, '');
		digits = digits.substring(0, 10);
		let formattedNumber = digits.replace(/(\d{3})(\d{0,3})(\d{0,4})/, (match, p1, p2, p3) => {
			let parts = [p1];
			if (p2) parts.push(`-${p2}`);
			if (p3) parts.push(`-${p3}`);
			return parts.join('');
		});
		return formattedNumber;
	}
	return number;
}

const changeBillingOption = () => (radios.value = 'no');

onMounted(async () => {
	await useApplicationStore().fetchStates();
	useApplicationStore().states.forEach((state) => {
		stateOptions.value.push(state.name);
	});
	validateInput('email', email.value);
});

const validateInput = (dataProperty, value) => {
	const requiredError = 'Field is required';
	delete errors.value[dataProperty];
	const notRequiredFields = ['address2', 'serviceAddress2', 'invoicingPeriod', 'email'];
	if (!notRequiredFields.includes(dataProperty) && !value) {
		errors.value[dataProperty] = requiredError;
		return errors.value;
	}

	switch (dataProperty) {
		case 'firstName':
			errors.value[dataProperty] = value?.length < 3 && 'First Name length must be greater than 3';
			break;
		case 'lastName':
			errors.value[dataProperty] = value?.length < 3 && 'Last Name length must be greater than 3';
			break;
		case 'email':
			errors.value[dataProperty] =
				value?.length == 0
					? ''
					: !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) && `Ex: Incorrect -> [${value}], Correct -> example@domain.com`;
			break;
		case 'phone':
			errors.value[dataProperty] =
				!/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value) && `Ex: Incorrect -> [${value}], Correct -> 423-423-4321`;
			break;
		case 'addressLineOne':
			errors.value[dataProperty] = !/^.{1,99}$/.test(value) && 'Address field must be less than 100 characters long';
			break;
		case 'address2':
			errors.value[dataProperty] =
				value !== '' && !/^.{1,99}$/.test(value) && 'Address field must be less than 100 characters long';
			break;
		case 'city':
			errors.value[dataProperty] = value?.length > 19 && 'City length must be less than 19';
			break;
		case 'zip':
			errors.value[dataProperty] = !/^\d{5}$/.test(value) && 'ZIP must be 5 digit long';
			break;

		case 'serviceAddress1':
			errors.value[dataProperty] = !/^.{1,99}$/.test(value) && 'Address field must be less than 100 characters long';
			break;
		case 'serviceAddress2':
			errors.value[dataProperty] =
				value !== '' && !/^.{1,99}$/.test(value) && 'Address field must be less than 100 characters long';
			break;
		case 'serviceCity':
			errors.value[dataProperty] = value?.length > 19 && 'City length must be less than 19';
			break;
		case 'serviceZip':
			errors.value[dataProperty] = !/^\d{5}$/.test(value) && 'ZIP must be 5 digit long';
			break;

		case 'mowingdRate':
			errors.value[dataProperty] = (value <= 0 || value > 10000) && 'Mowing Rate must be in Numbers and less than 10,000';
			break;
		default:
			break;
	}

	// body
	const body = {
		FirstName: firstName.value,
		LastName: lastName.value,
		Phone: phone.value,
		Email: email.value,
		InvoicingPeriod: invoicingPeriod.value === '' ? 4 : invoicingPeriod.value,
		MowingFrequency: `${mowingdFreq.value}`,
		MowingRate: mowingdRate.value,
		StartServiceDate: serviceDate.value ? dateFormat(serviceDate.value,"MM/DD/YYYY") : '',
		BillingAddress: {
			AddressLineOne: addressLineOne.value,
			AddressLineTwo: address2.value,
			City: city.value,
			State: state.value,
			Zip: zip.value,
		},
		ServiceAddress: {
			AddressLineOne: addressLineOne.value,
			AddressLineTwo: address2.value,
			City: city.value,
			State: state.value,
			Zip: zip.value,
		},
	};
	if (radios.value === 'no') {
		body.ServiceAddress = {
			AddressLineOne: serviceAddress1.value,
			AddressLineTwo: serviceAddress2.value,
			City: serviceCity.value,
			State: serviceState.value,
			Zip: serviceZip.value,
		};
	}

	const otherProps = {
		errors: errors.value,
	};
	emit('input-values-updated', body, otherProps);
};

const setErrors = (err) => {
	errors.value[err.field] = err.error;
};

const resetFields = () => {
	firstName.value = '';
	lastName.value = '';
	phone.value = '';
	email.value = '';
	addressLineOne.value = '';
	address2.value = '';
	city.value = '';
	state.value = '';
	zip.value = '';
	mowingdFreq.value = '';
	mowingdRate.value = '';
	serviceDate.value = new Date();
	serviceAddress1.value = '';
	serviceAddress2.value = '';
	serviceCity.value = '';
	serviceState.value = '';
	serviceZip.value = '';
	invoicingPeriod.value = '';
};

const changeForm = (val) => {
	showManualForm.value = val;
};
</script>

<style lang="scss" scoped>
.service-details-title {
	margin-left: auto;
	margin-right: auto;
}
</style>
