<template>
	<v-container v-if="!editing" class="container-fitting mb-8 px-8">
		<v-btn class="mb-10 rounded-pill edit_button" variant="flat" width="120" height="48" @click="showEditDialog">
			<span>Edit</span>
		</v-btn>
		<v-row class="client-account mb-10">
			<h2>Account Details</h2>
			<div class="client-account__container">
				<span class="title-label">Account Status:</span>
				<span class="title-value account-value" data-testid="account-status"
					:class="clientContact.accountStatus">{{ clientContact.accountStatus ? clientContact.accountStatus :
						'Not Set' }}</span>
			</div>
			<div class="client-account__container">
				<span class="title-label">Billing Status:</span>
				<span class="title-value account-value" data-testid="billing-status" :class="billingStatus">{{
					billingStatus }}</span>
			</div>
			<div class="client-account__container">
				<span class="title-label">Invite Status:</span>
				<span class="title-value Active" data-testid="invite-status">Accepted</span>
			</div>
			<div class="client-account__container">
				<span class="title-label">Invoicing Period:</span>
				<span class="title-value Active" data-testid="invoicing-period">{{ invoicingPeriodComputed }}</span>
			</div>
		</v-row>
		<v-row class="client-contact mb-10">
			<h2>Contact Details</h2>
			<div class="client-contact__container">
				<span class="title-label">Name:</span>
				<span class="title-value">{{ clientContact.firstName }} {{ clientContact.lastName }}</span>
			</div>
			<div class="client-contact__container">
				<span class="title-label">Email:</span>
				<span class="title-value">{{ clientContact.email }}</span>
			</div>
			<div class="client-contact__container">
				<span class="title-label">Phone:</span>
				<span class="title-value">{{ clientContact.phone || 'N/A' }}</span>
			</div>
			<div class="client-contact__container">
				<span class="title-label">Address</span>
				<span data-testid="client-address" class="title-value">{{ clientContact.addressLineOne }}</span>
				<span data-testid="client-city-state-zip" class="title-value">{{ clientContact.city?.trim() }}, {{
					clientContact.state }} {{ clientContact.zip }}</span>
			</div>
		</v-row>
		<v-row class="client-payment mb-10">
			<h2>Payment Type</h2>
			<p data-testid="payment-type">{{ paymentType }}</p>
		</v-row>
		<v-row class="client-properties mb-10">
			<h2>Properties</h2>
			<v-col cols="12" class="px-0 py-0">
				<p v-if="clientProperties.length === 0">There are no properties associated with this client</p>
				<v-list v-else class="Properties-list">
					<v-list-item v-for="(property, i) in clientProperties" :key="i" class="property"
						@click="$router.push(`/propertyDetails/${property.propertyId}`)">
						<p>
							{{ formatAddress(property) }}
						</p>
					</v-list-item>
				</v-list>
				<p>
					<v-btn class="mb-10 rounded-pill add_property_button" variant="flat" width="140" height="48"
						@click="showAddPropertyDialog">
						<span>Add Property</span>
					</v-btn>
				</p>
			</v-col>
		</v-row>
		<v-row class="client-invoices client-properties mb-10">
			<h2>Invoices</h2>
			<v-col cols="12" class="px-0 py-0">
				<p v-if="clientInvoices.length === 0">No Invoice found for this client</p>
				<v-list v-else class="Invoice-list">
					<v-list-item v-for="(invoice, i) in clientInvoices" :key="invoice.invoiceId" class="item pa-4"
						@click="$router.push(`/invoices/${invoice.invoiceId}`)">
						<div class="invoice_row mb-6">
							Amount:<span class="invoice_value"> ${{ invoice.invoiceTotal.toFixed(2) }} </span>
						</div>
						<div class="invoice_row">
							Invoiced Date:
							<span class="invoice_date">
								{{ parseDateString(invoice.dateInvoiced) }}
							</span>
						</div>
						<div class="invoice_row">
							Date Paid:
							<span class="invoice_date">
								{{ invoice.datePaid === null ? 'unpaid' : parseDateString(invoice.datePaid) }}
							</span>
						</div>
					</v-list-item>
				</v-list>
			</v-col>
		</v-row>
	</v-container>
	<v-dialog v-model="editing" persistent>
		<v-card class="edit-client-contact__card px-10 pb-16" text="Edit Client Details">
			<v-form ref="form">
				<v-label text="First Name" />
				<v-text-field v-model="newFirstName" clearable variant="solo"
					:rules="[(v) => !!v || 'First Name can not be empty']" />

				<v-label text="Last Name" />
				<v-text-field v-model="newLastName" clearable variant="solo"
					:rules="[(v) => !!v || 'Last Name can not be empty']" />

				<v-label text="Email" />
				<v-text-field v-model="newEmail" clearable variant="solo" />

				<v-label text="Phone" />
				<v-text-field v-model="newPhone" clearable variant="solo"
					:rules="[(v) => !!v || 'Phone can not be empty']" />
				<v-label text="Address Line One" />
				<v-text-field v-model="newAddressLineOne" clearable variant="solo"
					:rules="[(v) => !!v || 'Address line one can not be empty']" />

				<v-label text="Address Line Two" />
				<v-text-field v-model="newAddressLineTwo" clearable variant="solo" />

				<v-label text="State" />
				<v-text-field v-model="newState" clearable variant="solo"
					:rules="[(v) => !!v || 'State can not be empty']" />

				<v-label text="City" />
				<v-text-field v-model="newCity" clearable variant="solo"
					:rules="[(v) => !!v || 'City can not be empty']" />
				<v-label text="Zip" />
				<v-text-field v-model="newZip" clearable variant="solo"
					:rules="[(v) => !!v || 'Zip can not be empty']" />
				<v-label text="Payment Method" />
				<v-select v-model="newPaymentMethod" clearable variant="solo" :items="paymentMethods"
					:rules="[(v) => !!v || 'Payment Method can not be empty']" />
				<v-label text="Account Status" />
				<v-select v-model="newAccountStatus" clearable variant="solo" :items="['Active', 'Inactive', 'Not Set']"
					:rules="[(v) => !!v || 'Account Status can not be empty']" />
				<v-label text="Invoicing Period" />
				<v-select v-model="newInvoicingPeriod" class="invoicingperiod__select" clearable variant="solo"
					:items="invoicingPeriodOption" item-title="title" item-value="value" />
				<v-btn rounded="pill" size="large" class="update-btn" :loading="isUpdating" @click="updateClientInfo">
					Update
				</v-btn>
				<v-btn rounded="pill" size="large" class="cancel-btn" @click="editing = false">
					Cancel
				</v-btn>
			</v-form>
		</v-card>
	</v-dialog>
	<v-dialog v-model="showEmptyEmailConfirmationDialog" activator="child" transition="dialog-bottom-transition">
		<v-card class="success-Card py-8 px-4">
			<h2 class="success-Card-Title">Proceed Without Email?</h2>
			<span class="success-Card-subTitle">Are you sure you want to continue without Email?</span>
			<v-card-actions class="flex justify-center">
				<v-btn class="success-Card-No" href="#" @click="handleEmptyEmailConfirmation(false)">
					<span class="btn-text">No</span>
				</v-btn>
				<v-btn class="success-Card-Close" href="#" @click="handleEmptyEmailConfirmation(true)">
					<span class="btn-text">Yes</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
	<v-dialog v-model="showMessage" activator="child" transition="dialog-bottom-transition">
		<v-card class="message-Card py-8 px-4">
			<h2 class="message-Card-Title mb-2">{{ errorMessage === '' ? 'Success' : 'Error' }}</h2>
			<p class="message-Card-text mb-4">
				{{ errorMessage === '' ? `Client Information Updated Successfully` : errorMessage }}
			</p>
			<v-btn class="message-Card-Close" @click="showMessage = false">
				<span class="btn-text px-4 py-12">OK</span>
			</v-btn>
		</v-card>
	</v-dialog>
	<EditPropertyDetailsDialog :add-new="true" :client-id="$route.params.id" @onChange="loadClientInfo()" />
</template>

<script setup>
import axios from 'axios';
import { ref, onMounted, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { parseDateString } from '@/composables/text-parser.composables';
import { useRoute } from 'vue-router'; // Import useRoute from vue-router
import { useScheduleStore } from '@/stores/schedule';
import EditPropertyDetailsDialog from '@/components/EditPropertyDetailsDialog';

const route = useRoute(); // Use useRoute to get the current route
const clientContact = ref({});
const clientPayment = ref({});
const clientInvoices = ref([]);
const clientProperties = ref([]);
const clientUnInvoicedJobs = ref([]);
const invoicingPeriod = ref(0);
const invoicingPeriodSelection = ref(['Time of Service', 'Monthly', 'Bi-Monthly', 'Quarterly', 'Manual']);
const invoicingPeriodOption = ref([
	{ title: 'Time of Service', value: 0 },
	{ title: 'Monthly', value: 1 },
	{ title: 'Bi-Monthly', value: 2 },
	{ title: 'Quarterly', value: 3 },
	{ title: 'Manual', value: 4 },
]);

let newPaymentMethod = ref('');
let paymentMethods = ref(['Cash', 'Check', 'Card']);

const invoicingPeriodComputed = computed(() => {
	if (invoicingPeriod.value === 'Not Set') {
		return 'Not Set';
	}

	return invoicingPeriodSelection.value[invoicingPeriod.value];
});
const formatAddress = (property) => {
	return `${property.addressLineOne ? property.addressLineOne + ', ' : ''} 
		${property.addressLineTwo ? property.addressLineTwo + ', ' : ''}
		${property.city ? property.city + ', ' : ''} ${property.state}`;
};
const billingStatus = ref(false);
const isUpdating = ref(false);

const newFirstName = ref('');
const newLastName = ref('');
const newEmail = ref('');
const newPhone = ref('');
const newAddressLineOne = ref('');
const newAddressLineTwo = ref('');
const newState = ref('');
const newCity = ref('');
const newZip = ref('');
const newAccountStatus = ref('');
const newInvoicingPeriod = ref('');
const editing = ref(false);

const scheduleStore = useScheduleStore();
const { showEditPropertiesDialog } = storeToRefs(scheduleStore);

let showMessage = ref(false);
let showEmptyEmailConfirmationDialog = ref(false);
let errorMessage = ref('');
const paymentType = computed(() => {
	return paymentMethods.value[clientContact.value.paymentType];
});

onMounted(async () => {
	await loadClientInfo();
});

async function loadClientInfo() {
	const token = localStorage.getItem('token');

	const API_URL = process.env.VUE_APP_API_URL;

	try {
		const { data } = await axios.get(`${API_URL}/Client/ClientInfo?propertyOwnerDetailsId=${route.params.id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		clientContact.value = data.clientInfo;
		clientPayment.value = data.paymentDetails;
		clientProperties.value = data.properties;
		(clientUnInvoicedJobs.value = data.unInvoicedJobs), (clientInvoices.value = data.invoices);
		billingStatus.value = data.invoicesPaid ? 'Paid' : 'Unpaid';
		invoicingPeriod.value = data.clientInfo.invoicingPeriod === null ? 'Not Set' : data.clientInfo.invoicingPeriod;
	} catch (exp) {
		console.error(exp);
		errorMessage.value = 'Unable to load client information. Please try again later.';
		showMessage.value = true;
	}
}

function showEditDialog() {
	newFirstName.value = clientContact.value.firstName;
	newLastName.value = clientContact.value.lastName;
	newEmail.value = clientContact.value.email;
	newPhone.value = clientContact.value.phone;
	newAddressLineOne.value = clientContact.value.addressLineOne;
	newAddressLineTwo.value = clientContact.value.addressLineTwo;
	newState.value = clientContact.value.state;
	newCity.value = clientContact.value.city;
	newZip.value = clientContact.value.zip;
	newAccountStatus.value = clientContact.value.accountStatus;
	newPaymentMethod.value = paymentMethods.value[clientContact.value.paymentType];
	newInvoicingPeriod.value = invoicingPeriod.value;
	editing.value = true;
}

function showAddPropertyDialog() {
	showEditPropertiesDialog.value = true;
}

const form = ref(null);
async function updateClientInfo() {
	errorMessage.value = '';
	isUpdating.value = true;
	const isValid = await form.value.validate();

	if (isValid.valid) {
		updateValidatedClientInfo();

		isUpdating.value = false;
		editing.value = false;
		if (!newEmail.value || newEmail.value.trim().length === 0) {
			isUpdating.value = false;
			editing.value = false;
			showEmptyEmailConfirmationDialog.value = true;
			return;
		}
	} else {
		isUpdating.value = false;
		errorMessage.value = isValid.errors[0].errorMessages[0];
		showMessage.value = true;
	}
}

async function updateValidatedClientInfo() {
	const token = localStorage.getItem('token');
	// eslint-disable-next-line no-undef
	const API_URL = process.env.VUE_APP_API_URL;
	const requestData = {
		clientInfo: {
			Id: route.params.id,
			firstName: newFirstName.value,
			lastName: newLastName.value,
			phone: newPhone.value,
			email: newEmail.value,
			addressLineOne: newAddressLineOne.value,
			addressLineTwo: newAddressLineTwo.value,
			city: newCity.value,
			state: newState.value,
			zip: newZip.value,
			accountStatus: newAccountStatus.value,
			invoicingPeriod: newInvoicingPeriod.value,
			paymentType: paymentMethods.value.indexOf(newPaymentMethod.value),
		},
	};

	await axios
		.post(`${API_URL}/Client/ClientInfo`, requestData, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		.then((response) => {
			clientContact.value = response.data;
			invoicingPeriod.value = response.data.invoicingPeriod;
		})
		.catch((error) => {
			isUpdating.value = false;
			console.error(error);
			errorMessage.value = 'Something Went Wrong. Please try again later.';
		});

	isUpdating.value = false;
	showEmptyEmailConfirmationDialog.value = false;
	editing.value = false;

	showMessage.value = true;
}

function handleEmptyEmailConfirmation(continueWithoutEmail) {
	if (continueWithoutEmail) {
		isUpdating.value = true;
		updateValidatedClientInfo();
	} else {
		showEmptyEmailConfirmationDialog.value = false;
		editing.value = true;
	}
}
</script>
<style lang="scss">
@import 'styles';

.container-fitting {
	font-family: 'Raleway', sans-serif;
	padding: 41px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	.edit_button {
		color: $SP1600;
		font-weight: bold;
		font-size: 16px;
		text-transform: capitalize;
		background-color: $SP1200;
	}

	.add_property_button {
		color: $SP1600;
		font-weight: bold;
		font-size: 16px;
		text-transform: capitalize;
		background-color: $SP800;
	}

	.email-Card-Image {
		cursor: pointer;
	}
}

.client-contact,
.client-account {
	row-gap: 17px;
	margin-bottom: 61px;
	width: 100%;

	& h2 {
		display: inline-flex;
		justify-content: center;
		width: 100%;
		margin-bottom: 10px;
		text-align: center;
		font: normal normal 600 27px/31px Raleway;
		letter-spacing: 0px;
		color: $SP400;
		opacity: 1;

		span {
			margin-left: 25px;
			display: flex;
			align-items: center;
		}
	}

	&__container {
		display: flex;
		width: 100%;
		justify-content: space-between;

		& .title-label {
			min-width: 150px;
			text-align: left;
			font: normal normal medium 20px/24px Raleway;
			letter-spacing: 0px;
			color: $SP400;
			opacity: 1;
		}

		& .title-value {
			text-align: right;
			word-break: break-word;
			text-align: right;
			font: normal normal medium 20px/24px Raleway;
			letter-spacing: 0px;
			color: $SP400;
			opacity: 1;
		}
	}
}

.client-contact__container {
	display: block;

	span {
		display: block;
	}

	& .title-label,
	& .title-value {
		text-align: left;
	}

	& .title-label {
		font-weight: bold;
	}
}

.client-account {

	.account-value,
	.Unpaid {
		color: $SP500;
	}

	.Active,
	.Paid {
		color: $SP800;
	}
}

.client-payment,
.client-properties,
.client-invoices {
	row-gap: 17px;
	margin-bottom: 6rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;

	& h2 {
		display: inline-flex;
		justify-content: center;
		width: 100%;
		margin-bottom: 10px;
		text-align: center;
		font: normal normal 600 27px/31px Raleway;
		letter-spacing: 0px;
		color: $SP400;
		opacity: 1;

		span {
			margin-left: 16px;
			display: flex;
			align-items: center;
		}
	}

	.v-list {
		width: 100%;
		font-weight: 600;
	}

	.v-list-item:nth-child(even) {
		background-color: $SP1400;
	}

	.v-list-item:nth-child(odd) {
		background-color: $SP1100;
	}

	p {
		font: normal normal medium 20px/24px Raleway;
		letter-spacing: 0px;
		color: $SP400;
		opacity: 1;
	}

	&__container {
		display: flex;
		width: 100%;
		justify-content: space-between;

		& .title-label {
			text-align: left;
			min-width: 150px;
			font: normal normal medium 20px/24px Raleway;
			letter-spacing: 0px;
			color: $SP400;
			opacity: 1;
		}

		& .title-value {
			word-break: break-word;
			text-align: right;
			text-align: right;
			font: normal normal 300 20px/24px Raleway;
			letter-spacing: 0px;
			color: $SP400;
			opacity: 1;
		}
	}
}

.Properties-list {
	.property {
		cursor: pointer;
	}
}

.client-invoices {
	text-align: left;
	width: 100%;

	p {
		text-align: center;
	}

	.Invoice-list {
		.invoice_row {
			min-width: 150px;
			text-align: left;
			font-size: 1rem !important;
			font: normal normal 500 20px/24px Raleway;
			letter-spacing: 0px;
			color: $SP400;
			opacity: 1;
			margin: 2px;

			.invoice_value,
			.invoice_date {
				color: $SP900;
				font-size: 1rem !important;
				word-break: break-word;
				font: normal normal 300 20px/24px Raleway;
			}
		}
	}
}

.edit-client-contact__card,
.edit-client-payment__card {
	font-family: Raleway;
	padding: 0 2rem;
	text-align: center;

	.v-card-text {
		text-align: center;
		font-size: 1.5rem;
		margin: 1rem 0;
		color: $SP400;
	}

	.v-label {
		margin-bottom: 5px;
		overflow: clip;
		// height: 4rem;
	}

	.v-input__control {
		.v-field {
			border-radius: 100px;
			background-color: $SP600;
			// height: 2.6rem;

			input {
				padding-top: 0;
			}

			&__clearable {
				padding-top: 0.6rem;
			}
		}
	}

	button {
		margin: 1rem auto;
		background-color: $SP500;
		color: $SP1100;
		width: 85%;
		font-weight: bold;
		text-transform: capitalize;
	}

	.update-btn {
		background-color: $SP800;
	}
}

.message-Card {
	border-radius: 50px !important;
	padding: 70px 42px 55px 45px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font: normal normal normal 16px/22px Raleway;
	color: $SP400;

	.message-Card-Title {
		font: normal normal 600 20px/22px Raleway;
		color: $SP400;
	}

	.message-Card-Close {
		display: flex;
		background: $SP800 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px $SP1700;
		border-radius: 28px;

		.btn-text {
			color: $SP1100;
		}
	}
}

.success-Card {

	.success-Card-No,
	.success-Card-Close {
		width: 150px !important;
	}
}
</style>
