<template>
	<div class="settings-container">
		<div
			class="time-change-options"
			data-testid="time-of-charge"
		>
			<h3 class="heading">Time of Changing</h3>

			<v-radio-group>
				<v-radio
					label="After each service is completed"
					value="1"
				></v-radio>
				<v-radio
					label="End of Month"
					value="2"
				></v-radio>
			</v-radio-group>
		</div>
		<div
			v-if="communicationFeatureFlag"
			data-testid="communication"
		>
			<h3 class="heading">Communication</h3>
			<p class="communication-text">A default ScheduPay message will be sent along with your custom messages below.</p>
			<div class="text-messaging">
				<div class="text-messaging__header">
					<h4 class="heading">Text Messaging</h4>
					<div class="toggle-button">
						<v-switch inset></v-switch>
					</div>
				</div>
				<div class="text-messaging__input">
					<textarea
						id="textarea"
						name=""
						cols="30"
						rows="4"
						placeholder="Type your custom message here"
					></textarea>
				</div>
			</div>
			<div class="email">
				<div class="email__header">
					<h4 class="heading">Email</h4>
					<div class="toggle-button">
						<v-switch inset></v-switch>
					</div>
				</div>
				<div class="email__input">
					<textarea
						id="textarea"
						name=""
						cols="30"
						rows="4"
						placeholder="Type your custom message here"
					></textarea>
				</div>
			</div>
		</div>
		<div data-testid="payment-types">
			<h3 class="heading payment-heading">Payment Types Accepted</h3>
			<div class="payment--options">
				<div class="option">
					<h4 class="text">Cash</h4>
					<div class="toggle-button">
						<v-switch inset></v-switch>
					</div>
				</div>
				<div class="option">
					<h4 class="text">Check</h4>
					<div class="toggle-button">
						<v-switch inset></v-switch>
					</div>
				</div>
				<div class="option">
					<h4 class="text">Invoicing</h4>
					<div class="toggle-button">
						<v-switch inset></v-switch>
					</div>
				</div>
				<div class="option">
					<h4 class="text">Credit/Debit Card</h4>
					<div class="toggle-button">
						<v-switch inset></v-switch>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
import { useFeatureFlagStore } from '@/stores/feature-flag-store';

const ffStore = useFeatureFlagStore();
const communicationFeatureFlag = ffStore.proSettingsCommunication;
</script>

<style lang="scss">
@import 'styles';
.settings-container {
	font-family: 'Raleway';
	padding: 0 1.5rem 4rem;
	.time-change-options {
		.v-input {
			margin-top: 1.5rem;
		}
		.v-selection-control {
			margin-bottom: 9px;
		}
		.v-selection-control__wrapper {
			position: absolute !important;
			left: calc(100% - var(--v-selection-control-size));
		}
		.v-label.v-label--clickable {
			width: 70%;
			line-height: 1.3;
			font-weight: 450;
			font-size: 15px;
		}
	}
	.communication-text {
		text-align: center;
		font-weight: 350;
		font-size: 14px;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
	.text-messaging,
	.email {
		margin-top: 1rem;
		.v-selection-control {
			.v-switch__track {
				border: 3px solid $SP500;
				background-color: transparent;
				width: 43px;
				height: 24px;
			}
			.v-switch__thumb {
				border: 3px solid $SP500;
			}
		}
		.v-selection-control--dirty {
			.v-switch__track {
				background-color: $SP800;
				border: none;
			}
			.v-switch__thumb {
				border: none;
			}
		}
		.v-input__details {
			display: none;
		}
		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.heading {
				color: $SP1300;
			}
		}
		&__input {
			#textarea {
				background-color: $SP1400;
				border-radius: 40px;
				padding: 1rem;
				width: 100%;
				font-size: 14px;
				&:focus-visible {
					outline: none;
				}
			}
		}
	}
	.payment-heading {
		margin-top: 2rem;
	}
	.payment--options {
		.option {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.text {
				font-weight: normal;
				font-size: 14px;
				color: $SP1300;
			}
			.toggle-button {
				height: 44px;
			}
			.v-selection-control {
				.v-switch__track {
					border: 3px solid $SP500;
					background-color: transparent;
					width: 43px;
					height: 24px;
				}
				.v-switch__thumb {
					border: 3px solid $SP500;
				}
			}
			.v-selection-control--dirty {
				.v-switch__track {
					background-color: $SP800;
					border: none;
				}
				.v-switch__thumb {
					border: none;
				}
			}
			.v-input__details {
				display: none;
			}
		}
	}
}
</style>
