import { defineStore } from 'pinia';
import { computed, inject, shallowRef } from 'vue';

export const useFeatureFlagStore = defineStore('featureFlagStore', () => {
	let growthBookInjectable = inject('growthbook');
	let growthbook = shallowRef(null);

	const proChat = computed(() => {
		return growthbook.value && !!growthbook.value.isOn('pro-chat');
	});
	const proPauseMowing = computed(() => {
		return growthbook.value && !!growthbook.value.isOn('pro-pause-mowing');
	});
	const proSettings = computed(() => {
		return growthbook.value && !!growthbook.value.isOn('pro-settings');
	});
	const proLcpInvoices = computed(() => {
		return growthbook.value && !!growthbook.value.isOn('pro-lcp-invoices');
	});
	const proSettingsCommunication = computed(() => {
		return growthbook.value && !!growthbook.value.isOn('pro-settings-communication');
	});

	const init = async () => {
		if (growthBookInjectable) {
			growthbook.value = null;
			growthbook.value = await growthBookInjectable.init();
		}
	};

	return {
		proChat,
		proPauseMowing,
		proSettings,
		proLcpInvoices,
		proSettingsCommunication,
		init,
	};
});
