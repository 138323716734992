import axios from 'axios';
import { useLoaderStore } from '@/stores/loader';
import { storeToRefs } from 'pinia';

export default {
	install() {
		const loaderStore = useLoaderStore();
		const { active } = storeToRefs(loaderStore);

		axios.interceptors.request.use((config) => {
			active.value = true;
			return config;
		});
		axios.interceptors.response.use((response) => {
			active.value = false;
			return response;
		});
	},
};
