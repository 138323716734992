<template>
	<v-tabs
		v-model="tab"
		class="schedule__tabs rounded-pill ma-10"
		centered
		grow
		hide-slider
	>
		<v-tab
			class="rounded-pill"
			:class="{ 'v-tab--selected': clickedTab === 'week' }"
			value="week"
			@click="clickedTab = 'week'"
		>
			week
		</v-tab>
		<v-tab
			class="rounded-pill"
			value="today"
			:class="{ 'v-tab--selected': clickedTab === 'today' }"
			@click="clickedTab = 'today'"
		>
			today
		</v-tab>
	</v-tabs>

	<div class="tab-content">
		<TodayTab
			ref="rescheduleServiceModalVisible"
			:schedules="schedules"
			:clicked-tab="clickedTab"
			@update-schedules="updateSchedules"
		/>
		<WeekTab
			:schedules="schedules"
			:clicked-tab="clickedTab"
			@reschedule-jobs-modal="openRescheduleJobsModal"
		/>
	</div>
	<RescheduleWholeDayDialog @set-seven-day-array="setSevenDayArray" />
	<RescheduleSingleYardDialog :property-to-reschedule="schedules.propertyId" />
	<AllJobsDoneDialog @set-seven-day-array="setSevenDayArray" />
	<DoneMowingSingleJobDialog
		@set-seven-day-array="setSevenDayArray"
		@edit-additional-fee="editAdditionalFee"
	/>
	<AddFeeDialog
		:fee-amount="additionalFeeAmount"
		:fee-title="additionalFeeTitle"
		:fee-description="additionalFeeDescription"
	/>
</template>

<script setup>
import { onMounted, ref, computed, toRaw } from 'vue';
import { useScheduleStore } from '@/stores/schedule';
import WeekTab from '@/components/WeekTab.vue';
import TodayTab from '@/components/TodayTab.vue';
import RescheduleWholeDayDialog from '@/components/RescheduleWholeDayDialog.vue';
import RescheduleSingleYardDialog from '@/components/RescheduleSingleYardDialog.vue';
import AllJobsDoneDialog from '@/components/AllJobsDoneDialog.vue';
import DoneMowingSingleJobDialog from '@/components/DoneMowingSingleJobDialog.vue';
import AddFeeDialog from '@/components/AddFeeDialog.vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { dateFormat } from '@/utils/dateFormatter';

const router = useRouter();

const scheduleStore = useScheduleStore();
const { rescheduleTodaysJobData, rescheduleJobModalVisible, showAddFeeDialog, editingFee } = storeToRefs(scheduleStore);
const tab = ref('today');
const clickedTab = ref('today');
const schedules = ref([]);

const additionalFees = ref([]);
const additionalFeeTitle = ref('');
const additionalFeeAmount = ref();
const additionalFeeDescription = ref('');

// Const emits = defineEmits(['update-schedules']);

const updateSchedules = (newSchedules) => {
	schedules.value = newSchedules;
};

const openRescheduleJobsModal = (schedule) => {
	rescheduleJobModalVisible.value = true;
	rescheduleTodaysJobData.value = schedule.schedules;
};

const sevenDayArrayData = computed(() => scheduleStore.getSevenDayArray);
onMounted(async () => {
	await scheduleStore.fetchSevenDaysSchedule();
	setSevenDayArray();
});

const setSevenDayArray = () => {
	additionalFees.value = scheduleStore.fees;
	const dates = [];
	for (let i = 0; i < 7; i++) {
		const date = new Date();
		date.setDate(new Date().getDate() + i);
		dates.push({
			label: dateFormat(date, 'dddd'),
			date,
			schedules: sevenDayArrayData.value.filter(
				(schedule) => dateFormat(schedule.nextMow, 'dddd') === dateFormat(date, 'dddd'),
			),
		});
	}

	dates[0].label = 'Today';
	dates[1].label = 'Tomorrow';
	schedules.value = dates;
	rescheduleTodaysJobData.value = [];
	schedules.value[0].schedules.forEach((item) => {
		rescheduleTodaysJobData.value.push({
			propertyId: item.propertyId,
			firstName: item.firstName,
			lastName: item.lastName,
			addressLineOne: item.addressLineOne,
			name: item.firstName + ' ' + item.lastName,
			RescheduledToDate: new Date(item.nextMow),
			address: item.addressLineOne,
			city: item.city,
		});
	});
	for (let i = 0; i < sevenDayArrayData.value.length; i++) {
		const jobsScheduledDate = new Date(sevenDayArrayData.value[i].nextMow);
		if (
			new Date(dateFormat(jobsScheduledDate, 'YYYY-MM-DD')) < new Date(dateFormat(new Date(), 'YYYY-MM-DD')) &&
			sevenDayArrayData.value[i].mowingFrequency
		) {
			router.push('/unresolvedJobs');
		}
	}
};

const editAdditionalFee = (feeTobeEdited) => {
	editingFee.value = toRaw(feeTobeEdited);
	showAddFeeDialog.value = true;
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';

.reschedule-modal {
	font-family: 'Raleway', sans-serif;
	color: $SP400;

	.reschedule__header {
		display: flex;
		justify-content: flex-end;
		color: $SP400;
		align-items: center;
	}

	.reschedule-data {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: $SP400;
		margin-top: 12px;

		.left {
			text-align: left;
			line-height: 1.2;

			.name {
				font-weight: bold;
				font-size: 20px;
			}

			.street,
			.city {
				font-size: 14px;
			}
		}

		.date {
			font-size: 18px;
			font-weight: 500;
		}
	}

	.reason {
		font-size: 20px;
	}
}

.input {
	padding: 0rem 1rem;
	background-color: $SP600;
	margin-bottom: 20px;
}

.Active {
	color: $SP800 !important;
}

.Inactive {
	color: $SP500 !important;
}

.v-tab--selected {
	background-color: $SP2700;
	border: 2px solid $SP300;
}

.schedule {
	&__tabs {
		background-color: $SP1400;
		color: $SP500;
	}
}
</style>
