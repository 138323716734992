<template>
	<v-card>
		<v-card-title class="card-title mt-5 mb-3"> Edit Fee </v-card-title>
		<v-card-text>
			<v-text-field
				v-model="feesToBeEdited.title"
				placeholder="Title"
				dense
				variant="plain"
				class="rounded-pill input"
				clearable
			></v-text-field>
			<v-text-field
				v-model="feesToBeEdited.amount"
				placeholder="Fees"
				variant="plain"
				class="rounded-pill input"
				type="number"
				clearable
			></v-text-field>
			<v-textarea
				v-model="feesToBeEdited.description"
				placeholder="Description"
				variant="plain"
				class="rounded-xl input"
				clearable
			></v-textarea>
		</v-card-text>

		<v-card-actions class="justify-center flex-column">
			<v-btn
				class="mb-7 rounded-pill submit-button text"
				variant="flat"
				width="232"
				height="48"
				@click="updateAdditionalFees"
			>
				<span>Update</span>
			</v-btn>
			<v-btn
				class="mb-7 rounded-pill close-button text"
				variant="flat"
				width="232"
				height="48"
				@click="emit('closeCard')"
			>
				<span>Cancel</span>
			</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { useScheduleStore } from '@/stores/schedule';
import { storeToRefs } from 'pinia';
const scheduleStore = useScheduleStore();
const { editingFee, fees } = storeToRefs(scheduleStore);
const emit = defineEmits(['closeCard']);

const feesToBeEdited = ref('');

const updateAdditionalFees = () => {
	fees.value.map((additionalFee) => {
		if (additionalFee.id === feesToBeEdited.value.id) {
			additionalFee.title = feesToBeEdited.value.title;
			additionalFee.amount = Number(feesToBeEdited.value.amount);
			additionalFee.description = feesToBeEdited.value.description;
		}

		return additionalFee;
	});
	emit('closeCard');
};

onMounted(() => (feesToBeEdited.value = JSON.parse(JSON.stringify(editingFee.value))));
</script>

<style lang="scss" scoped>
@import '../../styles.scss';

.input {
	padding: 0rem 1rem;
	background-color: $SP600;
	margin-bottom: 20px;
}
.submit-button,
.close-button {
	color: $SP1600;
	font-weight: bold;
	font-size: 16px;
	text-transform: capitalize;
}
.submit-button {
	background-color: $SP500;
}
.close-button {
	background-color: $SP400;
}
</style>
