<template>
	<v-container>
		<v-row class="text-center">
			<v-col><InvoicesStatus /></v-col
		></v-row>
		<v-row
			v-if="UnpaidInvoices.length"
			class="text-center"
		>
			<v-col
				cols="12"
				class="main_heading"
			>
				<h3>Unpaid Invoices</h3>
			</v-col>
			<v-col
				cols="12"
				class="col__main mb-3"
			>
				<v-list
					lines="three"
					class="list__item__main unpaid-invoices__list"
				>
					<v-list-item
						v-for="(customer, i) in UnpaidInvoices"
						:key="i"
						:value="customer"
						class="list__item"
						@click="redirectToOpenBillDetailsPage(customer)"
					>
						<div class="customer__info__main">
							<div class="customer__details">
								<div class="customer__name text-left">
									{{ customer.fullName }}
								</div>
								<div class="text-left">
									{{ customer.streetAddress }}
								</div>
							</div>
							<div class="customer__jobdetails">
								<div class="uninvoiced-amount__value">${{ customer.totalDollarAmountUnpaid.toFixed(2) }}</div>
								<div class="no-of-open-invoices">
									{{ getInvoicesText(customer) }}
								</div>
							</div>
						</div>
					</v-list-item>
				</v-list>
			</v-col>
		</v-row>
		<v-row
			v-if="unInvoicedClients.length"
			class="text-center"
		>
			<v-col
				cols="12"
				class="main_heading"
			>
				<h3>Un-Invoiced Jobs</h3>
			</v-col>
			<v-col
				cols="12"
				class="col__main mb-3"
			>
				<v-list
					lines="three"
					class="list__item__main uninvoiced-clients__list"
				>
					<v-list-item
						v-for="(customer, i) in unInvoicedClients"
						:key="i"
						:value="customer"
						class="list__item"
						@click="redirectToOpenBillDetailsPage(customer)"
					>
						<div class="customer__info__main">
							<div class="customer__details">
								<div class="customer__name text-left">
									{{ customer.fullName }}
								</div>
								<div class="text-left">
									{{ customer.addressLineOne }}
								</div>
							</div>
							<div class="customer__jobdetails">
								<div class="uninvoiced-amount__label">Un-Invoiced Amount</div>
								<div class="uninvoiced-amount__value">${{ customer.totalDollarAmountOfUnInvoicedJobs.toFixed(2) }}</div>
							</div>
						</div>
					</v-list-item>
				</v-list>
			</v-col>
		</v-row>
	</v-container>
</template>

<script setup>
import InvoicesStatus from '@/components/InvoicesStatus.vue';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useScheduleStore } from '@/stores/schedule';
import { storeToRefs } from 'pinia';
const router = useRouter();

const scheduleStore = useScheduleStore();
const { UnpaidInvoices, unInvoicedClients } = storeToRefs(scheduleStore);

onMounted(async () => {
	await scheduleStore.dashboardData();
});

const redirectToOpenBillDetailsPage = (customer) => {
	router.push(`/openbills/${customer.propertyOwnerId}`);
};
const getInvoicesText = (customer) => {
	return (
		(customer.amountOfOpenInvoices || 0) + ' open ' + ((customer.amountOfOpenInvoices || 0) > 1 ? 'invoices' : 'invoice')
	);
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap');

.main_heading {
	font-family: 'Raleway', sans-serif;
	font-weight: 600;
	font-size: 1.4rem !important;

	h3 {
		color: $SP500;
	}
}

.col__main {
	background-color: $SP400;
}

.list__item__main {
	background-color: $SP400;
	padding: 5px;
}

.list__item {
	align-items: center;
	position: relative;
	overflow: clip;
	padding: 0 !important;
	border-bottom: 2px solid $SP1100;
}
// .list__item:not(:last-child){
//   border-bottom: 2px solid $SP1100;
// }

.customer__info__main {
	font-family: 'Raleway', sans-serif;
	display: flex;
	justify-content: space-between;
	padding: 0;
	.customer__details {
		color: $SP1100;
	}
	.no-of-open-invoices {
		color: $SP1100;
		font-size: 1rem;
	}
}

.customer__name {
	font-weight: 600;
	font-size: 1.4rem !important;
}

.customer__jobdetails {
	text-align: right;
	color: $SP2600;
	font-size: 1.2rem;
	.uninvoiced-amount__label {
		font-size: 1.1rem;
	}
	.uninvoiced-amount__value {
		font-weight: bold;
	}
}
</style>
