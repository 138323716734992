import { defineStore, storeToRefs } from 'pinia';
import router from '@/router';
import jwt_decode from 'jwt-decode';
import { useLoaderStore } from './loader';
import { fetchComposable } from '@/composables/fetch.composables';
import { useFeatureFlagStore } from './feature-flag-store';
import { useOnboardingStore } from './onboarding-store';
import { ref, computed } from 'vue';

export const useAuthStore = defineStore('auth', () => {
	const isLoggedIn = ref(false);
	const loginUnSuccessful = ref(false);
	const forgotPassIsDisplayed = ref(false);
	const emailDialogDisplayed = ref(false);
	const invalidCredentialsDialogDisplayed = ref(false);
	const createAccountIsDisplayed = ref(false);
	const token = ref('');
	const twoFASent = ref(false);
	const verified2FA = ref(false);
	const twoFADialogDisplayed = ref(false);
	const onboardingStore = useOnboardingStore();

	const tokenExpiryTime = computed(() => {
		const storedToken = JSON.parse(localStorage.getItem('token'));
		const storedDateTime = new Date(storedToken.expiryDate);
		return storedDateTime.getTime();
	});

	const verify2FACode = async (code) => {
		// Code with be sent to the api for validation
		// if the code is valid, will get a true 200 from the server
		const formData = new FormData();
		formData.append('email', localStorage.getItem('email'));
		formData.append('code', code);
		const response = await fetchComposable('/Auth/Validate2FA', formData, 'POST');

		if (response.status === 200) {
			verified2FA.value = true;
			twoFASent.value = false;
			setLoggedInStatus();
			await setupFeatureFlags();
			const data = await response.json();

			if (data.redirectUrl) {
				onboardingStore.setOnboardingData(data);
				router.push(data.redirectUrl); // navigate to the redirectUrl
			}
		} else {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}
	};

	const send2FAEmail = (token) => {
		if (token) {
			twoFASent.value = true;
		} else {
			isLoggedIn.value = false;
			loginUnSuccessful.value = true;
		}
	};

	const setLoggedInStatus = () => {
		if (token.value) {
			const decoded = jwt_decode(token.value);

			localStorage.setItem('token', token.value);
			Object.entries(decoded).forEach(([key, value]) => {
				localStorage.setItem(key, value);
			});
			isLoggedIn.value = true;
			loginUnSuccessful.value = false;

			router.push('/dashboard');
		} else {
			isLoggedIn.value = false;
			loginUnSuccessful.value = true;
		}
	};

	const login = async (user) => {
		const formData = new FormData();
		formData.append('Email', user.email);
		formData.append('Password', user.password);
		const response = await fetchComposable('/Auth/Authenticate', formData, 'POST');
		if (response) {
			const res = await response.json();

			if (response.status === 200) {
				localStorage.setItem('email', user.email);
				localStorage.setItem('token', res.token);
			} else {
				const loaderStore = useLoaderStore();
				const { active } = storeToRefs(loaderStore);
				if (response.status === 400 || response.status === 401) {
					invalidCredentialsDialogDisplayed.value = true;
					active.value = false;
					return false;
				}
			}

			// Send 2fa to user here
			if (res.token) {
				token.value = res.token;
				send2FAEmail(res.token);
				return true;
			}
		} else {
			const loaderStore = useLoaderStore();
			const { active } = storeToRefs(loaderStore);
			invalidCredentialsDialogDisplayed.value = true;
			active.value = false;
			return false;
		}
	};

	const setupFeatureFlags = async () => {
		const ffStore = useFeatureFlagStore();
		await ffStore.init();
	};

	return {
		isLoggedIn,
		loginUnSuccessful,
		forgotPassIsDisplayed,
		emailDialogDisplayed,
		invalidCredentialsDialogDisplayed,
		createAccountIsDisplayed,
		token,
		twoFASent,
		verified2FA,
		twoFADialogDisplayed,
		tokenExpiryTime,
		verify2FACode,
		send2FAEmail,
		setLoggedInStatus,
		login,
	};
});
