<template>
	<v-dialog
		v-model="emailDialogDisplayed"
		transition="dialog-bottom-transition"
	>
		<v-card class="email-Card">
			<h2 class="email-Card-Title">Email Notification Sent</h2>
			<img
				src="@/assets/Images/emailBox.jpg"
				class="email-Card-Image"
			/>
			<span class="email-Card-subTitle">Please check your email for further instructions.</span>
			<v-btn
				class="email-Card-Close"
				data-testid="close-button"
				@click="closeVerificationDialog"
			>
				<span>OK</span>
			</v-btn>
		</v-card>
	</v-dialog>
</template>

<script setup>
import { useAuthStore } from '../stores/auth';
import { storeToRefs } from 'pinia';
const authStore = useAuthStore();
const { emailDialogDisplayed } = storeToRefs(authStore);

const closeVerificationDialog = () => {
	emailDialogDisplayed.value = false;
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap');

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
	transition: transform 0.2s ease-in-out;
}

.email-Card {
	border-radius: 50px !important;
	padding: 70px 42px 55px 45px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.email-Card-Title {
		margin-bottom: 12px;
		text-align: center;
		font: normal normal 600 27px/31px Raleway;
		letter-spacing: 0px;
		color: $SP400;
		opacity: 1;
	}

	.email-Card-Image {
		margin-bottom: 28px;
		width: 255px;
		height: 255px;
	}

	.email-Card-subTitle {
		margin-bottom: 58px;
		text-align: left;
		font: normal normal normal 21px/38px Raleway;
		letter-spacing: 0px;
		color: $SP400;
		opacity: 1;
	}

	.email-Card-Close {
		width: 229px;
		height: 55px;
		background: $SP800 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px $SP1700;
		border-radius: 28px;
		opacity: 1;

		span {
			text-align: center;
			font: normal normal bold 23px/27px Raleway;
			letter-spacing: 0px;
			color: $SP1100;
			opacity: 1;
		}
	}
}
</style>
