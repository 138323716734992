<template>
	<v-col>
		<v-row justify="center">
			<v-radio-group
				v-model="selectedOption"
				inline
				class="radio-btns"
			>
				<v-radio
					class="mr-4"
					label="Email"
					value="email"
				></v-radio>
				<v-radio
					label="SMS Phone"
					value="phone"
				></v-radio>
			</v-radio-group>
		</v-row>
		<v-row>
			<v-col
				cols="12"
				md="4"
			>
				<v-text-field
					v-if="selectedOption === 'email'"
					v-model="email"
					:error-messages="errors.inviteEmail"
					class="code__input rounded-pill"
					clearable
					variant="plain"
					@input="validateInput('inviteEmail')"
				/>
				<v-text-field
					v-else
					v-model="phone"
					:error-messages="errors.invitePhone"
					class="code__input rounded-pill"
					clearable
					variant="plain"
					@input="validateInput('invitePhone')"
				/>
			</v-col>
		</v-row>
	</v-col>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useInviteClientStore } from '@/stores/invite-client-store';
const clientInviteStore = useInviteClientStore();
const { selectedClients, email, phone, selectedOption } = storeToRefs(clientInviteStore);

const emit = defineEmits(['setErr']);
const errors = ref({});

const validateInput = (dataProperty) => {
	delete errors.value[dataProperty];

	if (
		(dataProperty === 'inviteEmail' && selectedClients[0]?.email?.value === '') ||
		(dataProperty === 'invitePhone' && selectedClients[0]?.phone?.value === '')
	) {
		return emit('setErr', { field: dataProperty, error: false });
	}

	switch (dataProperty) {
		case 'inviteEmail':
			errors.value[dataProperty] =
				!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email.value) &&
				`Ex: Incorrect -> [${email.value}], Correct -> example@domain.com`;
			break;
		case 'invitePhone':
			errors.value[dataProperty] =
				!/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(phone.value) && `Ex: Incorrect -> [${phone.value}], Correct -> 423-423-4321`;
			break;
		default:
			break;
	}

	emit('setErr', { field: dataProperty, error: errors.value[dataProperty] });
};
</script>

<style scoped>
.radio-btns {
	max-width: fit-content;
	margin: 0 auto;
	height: 2.3rem;
	margin-top: 16px;
	font-family: 'Raleway', sans-serif;
}
</style>
