import { GrowthBook } from '@growthbook/growthbook';

// Export default growthbook;

export default {
	async install(app) {
		let growthbook = null;

		const init = async () => {
			if (growthbook) {
				growthbook.refreshFeatures();

				return growthbook;
			}

			try {
				growthbook = new GrowthBook({
					apiHost: 'https://cdn.growthbook.io',
					clientKey: 'sdk-4dmxkj9Yay64p55r',
					enableDevMode: true,
					// TrackingCallback: (experiment, result) => {
					//   // TODO: Use your real analytics tracking system
					//   // console.log('Viewed Experiment', {
					//   //   experimentId: experiment.key,
					//   //   variationId: result.key,
					//   // });
					// },
				});

				// Wait for features to be available
				await growthbook.init({
					streaming: true,
				});

				return growthbook;
			} catch (e) {
				console.error('GrowthBook Vue plugin failed', e);
				return null;
			}
		};

		app.provide('growthbook', {
			init,
		});
	},
};
