<template>
	<div
		v-if="props.clickedTab === 'today'"
		id="today-tab"
		class="week-content"
	>
		<!--THIS IS TODAY VIEW-->
		<v-container class="pa-0 overflow-hidden">
			<h2
				v-if="activeSchedules?.length > 0"
				class="mb-3 text-center schedule__main-title mb-8 mt-5"
			>
				{{ activeSchedules.length }} Scheduled Jobs Today
			</h2>
			<v-row
				v-if="activeSchedules?.length > 0"
				class="pa-0 mb-15"
			>
				<v-col
					cols="12"
					class="d-flex overflow-x-auto pl-11 pr-11 schedule__card-wrapper"
				>
					<v-card
						v-for="schedule in activeSchedules"
						:key="schedule.propertyId"
						class="mr-9 schedule__card pt-5 pb-5"
						min-width="306"
					>
						<v-card-text class="px-6">
							<div
								class="address"
								@click="
									router.push(`/propertyDetails/${schedule.propertyId}`);
									selectedPropertyId = schedule.propertyId;
								"
							>
								<p class="text-h4 text--primary text-center schedule__title">
									{{ schedule.addressLineOne }}
								</p>
								<p class="text-h4 text--primary text-center schedule__title">{{ schedule.city }}, {{ schedule.state }}</p>
							</div>
							<p
								class="text-center text--primary text-h6 schedule__name mt-5 mb-7"
								@click="router.push(`/clients/${schedule.propertyOwnerId}`)"
							>
								{{ schedule.firstName }} {{ schedule.lastName }}
							</p>
							<ul class="schedule__list">
								<li class="ma-0 mb-7 pa-0 d-flex justify-space-between align-items-center">
									<span>Last Mowed</span>
									<span class="text-right">{{
										schedule.isFirstMow ? 'First Mow is Today!' : dateFormat(schedule.lastMowed, 'ddd MMM DD, YYYY')
									}}</span>
								</li>
								<li class="ma-0 mb-7 pa-0 d-flex justify-space-between align-items-center">
									<span>Mowing Frequency</span>
									<span class="text-right">Every {{ schedule.mowingFrequency }} days</span>
								</li>
								<li class="ma-0 mb-7 pa-0 d-flex justify-space-between">
									<span>Account status</span>
									<span class="text-right">{{ schedule.accountStatus ? schedule.accountStatus : 'NOT SET' }}</span>
								</li>
								<li class="ma-0 mb-7 pa-0 d-flex justify-space-between">
									<span>Current Unpaid Invoices</span>
									<span class="text-right">{{ schedule.unpaidInvoice }} unpaid</span>
								</li>
								<li class="ma-0 mb-7 pa-0 d-flex justify-space-between">
									<span>Mowing Rate</span>
									<span class="text-right">${{ schedule.mowingRate }}</span>
								</li>

								<li
									v-if="getPropertyFees.length"
									class="ma-0 mb-7 pa-0"
								>
									<div
										v-if="schedule.isEditCardOpen"
										class="new-fee"
									>
										<DropdownEditFeeCard @close-card="closeEditCard(schedule.propertyId)" />
									</div>

									<div v-else>
										<span>Extra Service Fees</span>
										<div
											v-for="(feeItem, index) in scheduleStore.fetchPropertyFees(schedule.propertyId)"
											:key="index"
											class="fee-structure mt-1 d-flex justify-space-between"
											@click="editFees(feeItem, schedule.propertyId)"
										>
											<span>{{ feeItem.title }}</span>
											<span class="text-right">${{ feeItem.amount }}</span>
										</div>
									</div>
								</li>
								<li class="ma-0 mb-7 pa-0">
									<span class="d-block mb-3">Notes</span>
									<v-textarea
										v-model="schedule.notes"
										variant="plain"
										class="rounded-xl input notes"
										clearable
										data-testid="notes"
										@input="handleInput(schedule)"
										@blur="updateNotes(schedule)"
									></v-textarea>
								</li>
							</ul>
							<div class="d-flex flex-column align-center">
								<v-btn
									class="mb-7 rounded-pill schedule__button reschedule-btn text"
									variant="flat"
									width="232"
									height="48"
									@click="
										rescheduleServiceModalVisible = true;
										reschedulePropertySelected = schedule;
									"
								>
									<span>Reschedule Today's Service</span>
								</v-btn>
								<v-btn
									class="mb-7 rounded-pill schedule__button add-service-btn"
									variant="flat"
									width="232"
									height="48"
									@click="
										showAddFeeDialog = true;
										selectedPropertyId = schedule.propertyId;
									"
								>
									<span>Add a Service</span>
								</v-btn>

								<v-btn
									class="mb-7 rounded-pill schedule__button done-mow-btn"
									variant="flat"
									width="232"
									height="48"
									@click="
										doneMowingSingleYardDialog = true;
										selectedPropertyId = schedule.propertyId;
									"
								>
									<span>Done - Property Mowed</span>
								</v-btn>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
		<v-col
			v-if="activeSchedules?.length !== 0"
			class="swipe-btn mb-16 text-center"
		>
			<SwipeButton
				:reset-slider="rescheduleJobModalVisible"
				@swiped="showRescheduleJobModal"
			/>
		</v-col>
	</div>
</template>
<script setup>
import { defineProps, computed } from 'vue';
import SwipeButton from '../components/SwipeButton.vue';
import { useRouter } from 'vue-router';
import { useScheduleStore } from '@/stores/schedule';
import { storeToRefs } from 'pinia';
import DropdownEditFeeCard from './DropdownEditFeeCard.vue';
import { dateFormat } from '@/utils/dateFormatter';
const props = defineProps({
	clickedTab: {
		type: String,
		default: 'today',
	},
	schedules: {
		type: Array,
		default: null,
	},
});
const scheduleStore = useScheduleStore();
const {
	rescheduleJobModalVisible,
	rescheduleServiceModalVisible,
	reschedulePropertySelected,
	showAddFeeDialog,
	doneMowingSingleYardDialog,
	selectedPropertyId,
	getPropertyFees,
	editingFee,
} = storeToRefs(scheduleStore);
const router = useRouter();

const emits = defineEmits(['update-schedules']);

const editFees = (fee, id) => {
	const jobs = props.schedules[0]?.schedules.map((job) => {
		if (job.propertyId === id) {
			return { ...job, isEditCardOpen: true };
		}

		return job;
	});

	emits('update-schedules', [{ ...props.schedules[0], schedules: jobs }]);
	editingFee.value = fee;
};

const closeEditCard = (id) => {
	props.schedules[0]?.schedules.map((job) => {
		if (job.propertyId === id) {
			job.isEditCardOpen = false;
			return job;
		}

		return job;
	});
};

const activeSchedules = computed(() => {
	if (!props.schedules) {
		return [];
	}

	return props.schedules[0]?.schedules.filter(
		(schedule) => schedule.accountStatus === 'Active' && schedule.propertyStatus === 'Active',
	);
});
const showRescheduleJobModal = () => {
	rescheduleJobModalVisible.value = true;
};

const typingTimers = {};

const handleInput = (property) => {
	clearTimeout(typingTimers[property.propertyId]);
	typingTimers[property.propertyId] = setTimeout(() => {
		updateNotes(property);
	}, 15000);
};

const updateNotes = async (property) => {
	const payload = {
		addressLineOne: property.addressLineOne,
		city: property.city,
		state: property.state,
		mowingRate: property.mowingRate,
		mowingFrequency: property.mowingFrequency.toString(),
		status: property.accountStatus,
		zip: property.zip,
		propertyId: property.propertyId,
		notes: property.notes,
	};
	await scheduleStore.updateProperty(payload);
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';

.schedule {
	font-family: 'Raleway', sans-serif;

	&__main-title {
		font-family: 'Raleway', sans-serif;
		font-weight: 400;
		font-size: 24px;
		line-height: 28px;
		color: $SP400;
	}

	&__title {
		font-family: 'Raleway', sans-serif;
		font-weight: 500;
		font-size: 24px;
		line-height: 32px;
		color: $SP400;
	}

	&__name {
		font-family: 'Raleway', sans-serif;
		font-weight: 400;
		font-size: 20px;
		line-height: 24px;
		color: $SP400;
		cursor: pointer;
	}

	&__card {
		.address {
			.text-h4 {
				font-family: 'Raleway', sans-serif !important;
			}
		}
	}

	&__date {
		font-family: 'Raleway', sans-serif;
		font-weight: 600;
		font-size: 18px;
		color: $SP400;
	}

	&__card-wrapper {
		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__button {
		text-transform: capitalize;
		box-shadow: 0px 3px 6px $SP1700;

		span {
			color: $SP1600;
			font-family: Raleway, sans-serif;
			letter-spacing: 0;
		}
	}
	&__list {
		list-style: none;

		li {
			div {
				font-weight: 700;
				font-size: 20px;
				color: $SP400;
			}

			span {
				&:nth-child(1) {
					font-size: 16px;
					font-weight: 600;
					color: $SP400;
				}

				&:nth-child(2) {
					font-size: 14px;
					color: $SP400;
				}
			}

			.fee-structure {
				font-family: 'Raleway', sans-serif;
				font-size: 14px;
				color: $SP400;
				font-weight: 400;
			}

			.notes {
				background-color: $SP600;
				display: block;
				padding: 0rem 20px 2rem;
				min-height: 10rem;
				border-radius: 2rem;
			}
		}
	}

	&__card {
		background-color: $SP1100;
		border-radius: 56px;
	}
}
.reschedule-btn {
	background-color: $SP500;
}
.add-service-btn {
	background-color: $SP700;
}
.done-mow-btn {
	background-color: $SP800;
}
</style>
