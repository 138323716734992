<template>
	<div class="logo">
		<a
			href="https://schedupay.com"
			target="_blank"
			rel="noopener noreferrer"
		>
			<img
				src="schedulepay.png"
				alt=""
				data-testid="logo-image"
			/>
		</a>
		<h2 data-testid="schedupay-text">Schedu<span>Pay</span></h2>
		<h3>Pro</h3>
	</div>
	<v-form
		ref="form"
		v-model="valid"
		lazy-validation
		data-testid="form"
	>
		<v-container>
			<v-row>
				<v-col
					cols="12"
					md="4"
				>
					<div>
						<h3>Email</h3>
						<v-text-field
							v-model="email"
							variant="plain"
							class="rounded-pill input"
							:rules="rules.emailRules"
							required
							clearable
							data-testid="email-field"
						/>
					</div>
				</v-col>

				<v-col
					cols="12"
					md="4"
				>
					<div>
						<h3>Password</h3>
						<div class="password-input">
							<v-text-field
								v-model="password"
								variant="plain"
								clearable
								class="rounded-pill input"
								:type="passwordFieldType"
								:rules="rules.passwordRules"
								required
								data-testid="password-field"
							/>
							<v-icon
								class="password-toggle-icon"
								:class="{ 'password-visible': showPassword }"
								data-testid="show-password-icon"
								@click="togglePasswordVisibility"
							>
								{{ showPassword ? 'visibility' : 'visibility_off' }}
							</v-icon>
						</div>
					</div>
				</v-col>
				<v-col
					cols="12"
					md="4"
					class="forgotPassLink"
				>
					<span
						data-testid="reset-password-button"
						@click="clickedForgotPass"
						>Reset Password</span
					>
					<ForgotPassDialog data-testid="forgot-password" />
				</v-col>
				<v-col
					cols="12"
					md="4"
					class="no-underline"
				>
					<v-btn
						rounded="pill"
						class="login-btn"
						block
						:loading="loading"
						variant="flat"
						data-testid="login-button"
						@click="validate"
					>
						<img
							class="login-icon"
							src="SP-icon.svg"
							alt=""
						/>
						Login
					</v-btn>
					<router-link
						to="/create-account"
						data-testid="create-account-nav-link"
					>
						<v-btn
							class="createAccount-btn"
							rounded="pill"
							variant="flat"
							block
							data-testid="create-account-button"
						>
							Create Account
						</v-btn>
					</router-link>
					<CreateAccountDialog data-testid="create-account-dialog" />
				</v-col>
				<!--
                  <v-col cols="12" md="4">
          <div class="line">
            <hr />
            <span>or</span>
            <hr />
          </div>
        </v-col>

        <v-col cols="12" md="4">
          <v-btn
            class="login-facebook"
            rounded="pill"
            block
            @click="validate"
            variant="flat"
          >
            <img src="icon-facebook.svg" alt="" />
            Login with <span style="font-weight: bold"> Facebook</span></v-btn
          >

          <v-btn
            class="login-google"
            rounded="pill"
            variant="flat"
            block
            @click="clickedCreateAccount"
            ><img src="icon-google.svg" alt="" /> Login with
            <span style="font-weight: bold"> Google</span></v-btn
          >
          <CreateAccountDialog />
        </v-col>
          -->
			</v-row>
			<Auth2FADialog data-testid="2fa-dialog" />
		</v-container>
	</v-form>

	<v-snackbar
		v-model="loginUnSuccessful"
		data-testid="failed-login"
	>
		Login Failed... Please try again.

		<template #actions>
			<v-btn
				color="pink"
				variant="text"
				@click="loginUnSuccessful = false"
			>
				Close
			</v-btn>
		</template>
	</v-snackbar>

	<EmailSentDialog data-testid="email-sent-dialog" />
	<InvalidCredentialsDialog data-testid="invalid-credentials-dialog" />
</template>

<script setup>
import { useAuthStore } from '../stores/auth';
import { storeToRefs } from 'pinia';
import ForgotPassDialog from '@/components/ForgotPassDialog.vue';
import CreateAccountDialog from '@/components/CreateAccountDialog.vue';
import Auth2FADialog from '@/components/Auth2FADialog.vue';
import EmailSentDialog from '@/components/EmailSentDialog.vue';
import InvalidCredentialsDialog from '@/components/InvalidCredentialsDialog.vue';
import { ref, computed } from 'vue';

const authStore = useAuthStore();

const { loginUnSuccessful, forgotPassIsDisplayed, createAccountIsDisplayed, twoFADialogDisplayed } = storeToRefs(authStore);

const loading = ref(false);
const valid = ref(false);
const email = ref('');
const password = ref('');
const showPassword = ref(false);
const rules = ref({
	emailRules: [(v) => Boolean(v) || 'E-mail is required', (v) => /.+@.+/.test(v) || 'E-mail must be valid'],
	nameRules: [(v) => Boolean(v) || 'Name is required', (v) => v.length <= 10 || 'Name must be less than 10 characters'],
	passwordRules: [
		(v) => Boolean(v) || 'Password is required',
		(v) => (v && v.length >= 8) || 'Password must be at least 8 characters',
	],
});

async function validate() {
	loading.value = true;
	const response = await authStore.login({ email: email.value, password: password.value });
	loading.value = false;
	if (response) {
		email.value = '';
		password.value = '';
	}

	twoFADialogDisplayed.value = false;
}

function clickedCreateAccount() {
	authStore.$patch({
		createAccountIsDisplayed: !createAccountIsDisplayed.value,
	});
}

function clickedForgotPass() {
	authStore.$patch({
		forgotPassIsDisplayed: !forgotPassIsDisplayed.value,
	});
}

const passwordFieldType = computed(() => (showPassword.value ? 'text' : 'password'));

function togglePasswordVisibility() {
	showPassword.value = !showPassword.value;
}
</script>

<style lang="scss" scoped>
@import '../../styles.scss';

.password-input {
	position: relative;
	display: flex;
	align-items: center;
}

.password-toggle-icon {
	position: absolute;
	top: 16px;
	right: 50px;
	cursor: pointer;
}

.logo {
	margin-top: 1.5rem;
	text-align: center;
	font-size: 1.5rem;
	color: $SP1500;

	& > h2 {
		font-weight: 400;

		& span {
			color: $SP400;
		}
	}

	& > h3 {
		font-style: italic;
	}
}

h3 {
	text-align: center;
	font-weight: 400;
	font-size: 1.3rem;
	margin-bottom: 0.5rem;
}

button {
	font-size: 1.3rem;
	padding: 2rem;
	text-transform: capitalize;
	color: $SP1600;
}

.login-icon {
	width: 30px;
	margin-right: 0.5rem;
}

.login-btn {
	background-color: $SP400;
	margin-bottom: 1rem;
}

.createAccount-btn {
	background-color: $SP700;
}

.forgotPassLink span {
	color: $SP700 !important;
	font-size: 1.5rem;
}

.input {
	padding: 0rem 1rem;
	background-color: $SP600;
}

.line {
	display: flex;
	gap: 1.5rem;
	font-size: 1.5rem;
	align-items: center;

	& hr {
		flex: 1;
	}
}

.login-facebook {
	background-color: $SP1200;
	margin-bottom: 1rem;
}

.login-google {
	background-color: $SP300;
	color: $SP400;
}
.forgotPassLink {
	span {
		cursor: pointer;
		color: blue;
		text-decoration: underline;
	}
}

.no-underline a {
	text-decoration: none;
}
</style>
